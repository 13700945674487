const TranslationKeys = {
    permissions: {
        readOnly: "permissions.readOnly",
        update: "permissions.update"
    },
    project: {
        statusValues: {
            draft: "project.statusValues.draft",
            offered: "project.statusValues.offered",
            ordered: "project.statusValues.ordered",
            closed: "project.statusValues.closed"
        },
        actions: {
            open: "project.actions.open",
            delete: "project.actions.delete",
            duplicate: "project.actions.duplicate",
            read: "project.actions.read",
            save: "project.actions.save",
            changeStatus: "project.actions.changeStatus"
        },
        lockedDialog: {
            title: "project.lockedDialog.title",
            message: "project.lockedDialog.message", // use {user} for the user who locks the project
            unlockWarning: "project.lockedDialog.unlockWarning",
            submitButtonLabel: "project.lockedDialog.submitButtonLabel"
        },
        deleteDialog: {
            title: "project.deleteDialog.title",
            message: "project.deleteDialog.message",
            confirmButtonLabel: "project.deleteDialog.confirmButtonLabel",
            cancelButtonLabel: "project.deleteDialog.cancelButtonLabel"
        },
        orderingDialog: {
            title: "project.orderingDialog.title", // use {projectName} to use project name,
            newProjectOwnerDropdownLabel: "project.orderingDialog.newProjectOwnerDropdownLabel",
            incompleteConfigurations: "project.orderingDialog.incompleteConfigurations",
            noIncompleteConfigurations: "project.orderingDialog.noIncompleteConfigurations",
            missingRequiredFields: "project.orderingDialog.missingRequiredFields",
            noMissingRequiredFields: "project.orderingDialog.noMissingRequiredFields"
        },
        newProject: {
            button: {
                label: "project.newProject.button.label"
            },
            dialog: {
                title: "project.newProject.dialog.title",
                cancelButtonLabel: "project.newProject.dialog.cancelButtonLabel",
                submitButtonLabel: "project.newProject.dialog.submitButtonLabel"
            },
            exists: {
                title: "project.newProject.exists.title",
                showExistingProjects: "project.newProject.exists.showExistingProjects",
                createNewProject: "project.newProject.exists.createNewProject",
                message: "project.newProject.exists.message"
            }
        },
        warning: {
            missingTransferPriceCurrency: "project.warning.missingTransferPriceCurrency"
        }
    },
    discount: {
        maxDiscountExceeded: "discount.maxDiscountExceeded"
    },
    lineItem: {
        copyAppendix: "lineItem.copyAppendix",
        manualQuantityChangeWarning: "lineItem.manualQuantityChangeWarning",
        typeCodePlaceholder: "lineItem.typeCodePlaceholder",
        properties: {
            articleId: "lineItem.properties.articleId",
            configurationName: "lineItem.properties.configurationName",
            createdAt: "lineItem.properties.createdAt",
            createdBy: "lineItem.properties.createdBy",
            id: "lineItem.properties.id",
            modifiedAt: "lineItem.properties.modifiedAt",
            modifiedBy: "lineItem.properties.modifiedBy",
            multiplier: "lineItem.properties.multiplier",
            option: "lineItem.properties.option",
            plainListPrice: "lineItem.properties.plainListPrice",
            plainListPriceWithCurrency: "lineItem.properties.plainListPriceWithCurrency",
            plainTransferPrice: "lineItem.properties.plainTransferPrice",
            plusCode: "lineItem.properties.plusCode",
            positionNumber: "lineItem.properties.positionNumber",
            positionQuantity: "lineItem.properties.positionQuantity",
            priceGroup: "lineItem.properties.priceGroup",
            product: "lineItem.properties.product",
            productName: "lineItem.properties.productName",
            quoteRelevant: "lineItem.properties.quoteRelevant",
            shortText: "lineItem.properties.shortText",
            status: "lineItem.properties.status",
            totalListPrice: "lineItem.properties.totalListPrice",
            totalTransferPrice: "lineItem.properties.totalTransferPrice",
            calculatedTransferPrice: "lineItem.properties.calculatedTransferPrice",
            calculatedTotalTransferPrice: "lineItem.properties.calculatedTotalTransferPrice",
            typeCode: "lineItem.properties.typeCode",
            transferPriceDiscountPercentage: "lineItem.properties.transferPriceDiscountPercentage",
            transferPriceDiscountAccumulatedPercentage: "lineItem.properties.transferPriceDiscountAccumulatedPercentage",
            transferPriceDiscountedTotalPrice: "lineItem.properties.transferPriceDiscountedTotalPrice",
            transferPriceDiscountedUnitPrice: "lineItem.properties.transferPriceDiscountedUnitPrice",
            functionDesignation: "lineItem.properties.functionDesignation",
            locationDesignation: "lineItem.properties.locationDesignation",
            plainCost: "lineItem.properties.plainCost",
            plainCostWithCurrency: "lineItem.properties.plainCostWithCurrency",
            fullCost: "lineItem.properties.fullCost",
            totalCost: "lineItem.properties.totalCost",
            grossMargin: "lineItem.properties.grossMargin",
            netMargin: "lineItem.properties.netMargin",
            salesPriceMultiplier: "lineItem.properties.salesPriceMultiplier",
            salesPriceAccumulatedMultiplier: "lineItem.properties.salesPriceAccumulatedMultiplier",
            salesPriceWithCurrency: "lineItem.properties.salesPriceWithCurrency",
            totalSalesPrice: "lineItem.properties.totalSalesPrice",
            salesPriceDiscountPercentage: "lineItem.properties.salesPriceDiscountPercentage",
            salesPriceAccumulatedDiscountPercentage: "lineItem.properties.salesPriceAccumulatedDiscountPercentage",
            discountedSalesPrice: "lineItem.properties.discountedSalesPrice",
            discountedTotalSalesPrice: "lineItem.properties.discountedTotalSalesPrice",
            localProductionCost: "lineItem.properties.localProductionCost",
            totalLocalProductionCost: "lineItem.properties.totalLocalProductionCost",
            localGrossMargin: "lineItem.properties.localGrossMargin",
            localGrossProfit: "lineItem.properties.localGrossProfit"
        },
        types: {
            label: "lineItem.types.label",
            folder: "lineItem.types.folder",
            lineup: "lineItem.types.lineup",
            configurableArticle: "lineItem.types.configurableArticle",
            customArticle: "lineItem.types.customArticle",
            usualArticle: "lineItem.types.usualArticle",
            projectOptions: "lineItem.types.projectOptions",
            unitOptions: "lineItem.types.unitOptions"
        },
        statusValues: {
            ready: "lineItem.statusValues.ready",
            notReady: "lineItem.statusValues.notReady",
            conflict: "lineItem.statusValues.conflict"
        },
        actions: {
            addProductToLineup: "lineItem.actions.addProductToLineup",
            delete: "lineItem.actions.delete",
            duplicate: "lineItem.actions.duplicate",
            edit: "lineItem.actions.edit",
            print: "lineItem.actions.print",
            read: "lineItem.actions.read",
            rename: "lineItem.actions.rename",
            mfkView: "lineItem.actions.mfkview",
            openConfigurationView: "lineItem.actions.configurationview"
        },
        deleteDialog: {
            title: "lineItem.deleteDialog.title",
            message: "lineItem.deleteDialog.message",
            confirmButtonLabel: "lineItem.deleteDialog.confirmButtonLabel",
            cancelButtonLabel: "lineItem.deleteDialog.cancelButtonLabel"
        },
        renameDialog: {
            title: "lineItem.renameDialog.title",
            message: "lineItem.renameDialog.message",
            confirmButtonLabel: "lineItem.renameDialog.confirmButtonLabel",
            cancelButtonLabel: "lineItem.renameDialog.cancelButtonLabel",
            nameInput: {
                label: "lineItem.renameDialog.nameInput.label"
            }
        },
        splitItemDialog: {
            title: "lineItem.splitItemDialog.title",
            message: "lineItem.splitItemDialog.message",
            confirmButtonLabel: "lineItem.splitItemDialog.confirmButtonLabel",
            cancelButtonLabel: "lineItem.splitItemDialog.cancelButtonLabel"
        },
        lineup: {
            create: {
                button: {
                    label: "lineItem.lineup.create.button.label"
                },
                dialog: {
                    title: "lineItem.lineup.create.dialog.title",
                    nameInput: {
                        label: "lineItem.lineup.create.dialog.nameInput.label",
                        defaultValue: "lineItem.lineup.create.dialog.nameInput.defaultValue"
                    }
                }
            }
        },
        newArticle: {
            button: {
                label: "lineItem.newArticle.button.label"
            },
            dialog: {
                titleAdd: "lineItem.newArticle.dialog.titleAdd",
                titleEdit: "lineItem.newArticle.dialog.titleEdit",
                cancelButtonLabel: "lineItem.newArticle.dialog.cancelButtonLabel",
                submitButtonLabel: "lineItem.newArticle.dialog.submitButtonLabel",
                infoMessageNoProductCategorySet: "lineItem.newArticle.dialog.infoMessageNoProductCategorySet",
                infoMessageLV: "lineItem.newArticle.dialog.infoMessageLV",
                infoMessageDC: "lineItem.newArticle.dialog.infoMessageDC",
                infoMessageMV: "lineItem.newArticle.dialog.infoMessageMV"
            }
        }
    },

    configuration: {
        widgets: {
            mfk: {
                plmLabel: "configuration.widgets.mfk.plmLabel",
                sapLabel: "configuration.widgets.mfk.sapLabel"
            },
            notification: {
                warning: "configuration.widgets.notification.warning",
                error: "configuration.widgets.notification.error",
                info: "configuration.widgets.notification.info"
            }
        },
        modelChanged: "configuration.modelChanged", // use {previousVersion} / {currentVersion} to show the version number
        invalidPresetMessage: "configuration.invalidPresetMessage", // use {articleName}, {characteristicName} and {characteristicTranslatedValue}
        invalidPresetValueNotFoundMessage: "configuration.invalidPresetValueNotFoundMessage" // use {articleName}, {characteristicName}
    },

    pages: {
        dashboard: {
            pageName: "pages.dashboard.pageName",
            recentProjectsWidget: {
                title: "pages.dashboard.recentProjectsWidget.title",
                viewAllProjects: "pages.dashboard.recentProjectsWidget.viewAllProjects",
                created: "pages.dashboard.recentProjectsWidget.created",
                modified: "pages.dashboard.recentProjectsWidget.modified"
            },
            documentsWidget: {
                title: "pages.dashboard.documentsWidget.title",
                inputLabel: "pages.dashboard.documentsWidget.inputLabel",
                buttonLabel: "pages.dashboard.documentsWidget.buttonLabel"
            },
            helpWidget: {
                headline: "pages.dashboard.helpWidget.headline",
                text: "pages.dashboard.helpWidget.text",
                link: "pages.dashboard.helpWidget.link"
            },
            newsWidget: {
                title: "pages.dashboard.newsWidget.title",
                viewAllNews: "pages.dashboard.newsWidget.viewAll"
            }
        },

        projects: {
            pageName: "pages.projects.pageName",
            searchInputPlaceholder: "pages.projects.searchInputPlaceholder",
            searchDropdownSearchAllOption: "pages.projects.searchDropdownSearchAllOption",
            dataGrid: {
                noProjectsFound: "pages.projects.dataGrid.noProjectsFound",
                noProjectResults: "pages.projects.dataGrid.noProjectResults",
                columns: {
                    permission: "pages.projects.dataGrid.columns.permission"
                }
            }
        },

        project: {
            tabs: {
                details: "pages.project.tabs.details",
                catalog: "pages.project.tabs.catalog",
                composition: "pages.project.tabs.composition",
                reports: "pages.project.tabs.reports",
                configuration: "pages.project.tabs.configuration"
            },
            catalog: {
                showMore: "pages.project.catalog.showMore",
                showLess: "pages.project.catalog.showLess",
                link: "pages.project.catalog.link",
                selectProductGroupButtonLabel: "pages.project.catalog.selectProductGroupButtonLabel",
                searchInputPlaceholder: "pages.project.catalog.searchInputPlaceholder",
                searchResultBreadcrumb: "pages.project.catalog.searchResultBreadcrumb",
                noSearchResultsMessage: "pages.project.catalog.noSearchResultsMessage",
                searchResultsOfCatalog: "pages.project.catalog.searchResultsOfCatalog",
                productCategoryHint: "pages.project.catalog.productCategoryHint",
                selectDriveSegmentTitle: "pages.project.catalog.selectDriveSegmentTitle",
                lineupContextActiveMessage: "pages.project.catalog.lineupContextActiveMessage",
                quickSelection: {
                    expandAllProductGroups: "pages.project.catalog.quickSelection.expandAllProductGroups",
                    collapseAllProductGroups: "pages.project.catalog.quickSelection.collapseAllProductGroups",
                    addToCompositionButtonLabel: "pages.project.catalog.quickSelection.addToCompositionButtonLabel",
                    dataGrid: {
                        columnHeaderNames: {
                            quantity: "pages.project.catalog.quickSelection.dataGrid.columnHeaderNames.quantity",
                            productName: "pages.project.catalog.quickSelection.dataGrid.columnHeaderNames.productName",
                            info: "pages.project.catalog.quickSelection.dataGrid.columnHeaderNames.info"
                        }
                    }
                },
                productSelection: {
                    configureButtonLabel: "pages.project.catalog.productSelection.configureButtonLabel",
                    addToCompositionButtonLabel: "pages.project.catalog.productSelection.addToCompositionButtonLabel",
                    addToCompositionErrorMessage: "pages.project.catalog.productSelection.addToCompositionErrorMessage",
                    single: {
                        addToCompositionSuccessMessage: "pages.project.catalog.productSelection.single.addToCompositionSuccessMessage",
                        addToCompositionValidationProblemsMessage: "pages.project.catalog.productSelection.single.addToCompositionValidationProblemsMessage"
                        // use {addedItemsWithValidationProblems} to get a list of the added items that had validation problems
                    },
                    multiple: {
                        addToCompositionSuccessMessage: "pages.project.catalog.productSelection.multiple.addToCompositionSuccessMessage",
                        addToCompositionValidationProblemsMessage: "pages.project.catalog.productSelection.multiple.addToCompositionValidationProblemsMessage",
                        // use {addedItemsWithValidationProblems} to get a list of the added items that had validation problems
                        addToCompositionErrorMessage: "pages.project.catalog.productSelection.multiple.addToCompositionErrorMessage"
                        // use {productNames} to get a list of product names for which the requests failed
                    }
                },
                tabs: {
                    catalog: "pages.project.catalog.tabs.catalog",
                    quickSelection: "pages.project.catalog.tabs.quickSelection"
                },
                addToLineupDialog: {
                    title: "pages.project.catalog.addToLineupDialog.title",
                    createNewLineupDropdownOption: "pages.project.catalog.addToLineupDialog.createNewLineupDropdownOption",
                    declineButtonLabel: "pages.project.catalog.addToLineupDialog.declineButtonLabel",
                    confirmButtonLabel: "pages.project.catalog.addToLineupDialog.confirmButtonLabel",
                    lineupsDropdownLabel: "pages.project.catalog.addToLineupDialog.lineupsDropdownLabel",
                    newLineupInputLabel: "pages.project.catalog.addToLineupDialog.newLineupInputLabel",
                    lineupNonCompatible: "pages.project.catalog.addToLineupDialog.lineupNonCompatible"
                }
            },
            configuration: {
                error: {
                    message: "pages.project.configuration.error.message",
                    invalidSessionMessage: "pages.project.configuration.error.invalidSessionMessage"
                },
                emptyTreeMessage: "pages.project.configuration.emptyTreeMessage",
                configurationInterfaceDecisions: {
                    differenceTooltip: {
                        parameterName: "pages.project.configuration.configurationInterfaceDecisions.differenceTooltip.parameterName",
                        parameterValue: "pages.project.configuration.configurationInterfaceDecisions.differenceTooltip.parameterValue",
                        projectOptionValue: "pages.project.configuration.configurationInterfaceDecisions.differenceTooltip.projectOptionValue",
                        lineupOptionValue: "pages.project.configuration.configurationInterfaceDecisions.differenceTooltip.lineupOptionValue",
                        message: "pages.project.configuration.configurationInterfaceDecisions.differenceTooltip.message"
                    },
                    applyButtonLabel: "pages.project.configuration.configurationInterfaceDecisions.applyButtonLabel",
                    failedToApplyDecision: "pages.project.configuration.configurationInterfaceDecisions.failedToApplyDecision"
                    // available variables: configurationName, parameterName, translatedParameterName, parameterValue, translatedParameterValue
                },
                matlab: {
                    notTerminalError: {
                        title: "pages.project.configuration.matlab.notTerminalError.title",
                        message: "pages.project.configuration.matlab.notTerminalError.message"
                    },
                    error: {
                        title: "pages.project.configuration.matlab.error.title",
                        unknownErrorMessage: "pages.project.configuration.matlab.error.unknownErrorMessage"
                    }
                },
                graph: {
                    renderError: "pages.project.configuration.graph.renderError" // use {graphName} to render the name of the graph
                },
                abbInputField: {
                    rangeText: "pages.project.configuration.abbInputField.rangeText" // use {minValue, maxValue} to render the values of the range
                },
                visualization: {
                    ar: {
                        dialogTitle: "pages.project.configuration.visualization.ar.dialogTitle",
                        qrCodeAltText: "pages.project.configuration.visualization.ar.qrCodeAltText",
                        errorMessage: "pages.project.configuration.visualization.ar.errorMessage",
                        dialogText: "pages.project.configuration.visualization.ar.dialogText",
                        dialogCloseButton: "pages.project.configuration.visualization.ar.dialogCloseButton"
                    },
                    hideDoors: "pages.project.configuration.visualization.hideDoors"
                },
                layoutOptions: {
                    hideConfigurationTree: "pages.project.configuration.layoutOptions.hideConfigurationTree",
                    hideConfiguration: "pages.project.configuration.layoutOptions.hideConfiguration",
                    hideVisualization: "pages.project.configuration.layoutOptions.hideVisualization",
                    showConfigurationTree: "pages.project.configuration.layoutOptions.showConfigurationTree",
                    showConfiguration: "pages.project.configuration.layoutOptions.showConfiguration",
                    showVisualization: "pages.project.configuration.layoutOptions.showVisualization",
                    focusVisualization: "pages.project.configuration.layoutOptions.focusVisualization",
                    focusConfiguration: "pages.project.configuration.layoutOptions.focusConfiguration",
                    showOnlyConfiguration: "pages.project.configuration.layoutOptions.showOnlyConfiguration",
                    resetLayout: "pages.project.configuration.layoutOptions.resetLayout"
                },
                unitOverviewTable: {
                    defineUnitOptions: "pages.project.configuration.unitOverviewTable.defineUnitOptions",
                    applyDefaultDesignations: "pages.project.configuration.unitOverviewTable.applyDefaultDesignations"
                }
            },
            composition: {
                addFolderButtonLabel: "pages.project.composition.addFolderButtonLabel",
                addLineItemButtonLabel: "pages.project.composition.addLineItemButtonLabel",
                expandAllLineItemsButtonLabel: "pages.project.composition.expandAllLineItemsButtonLabel",
                collapseAllLineItemsButtonLabel: "pages.project.composition.collapseAllLineItemsButtonLabel",
                copyTypeCode: "pages.project.composition.copyTypeCode",
                copyPlusCode: "pages.project.composition.copyPlusCode",
                noLineItemResults: "pages.project.composition.noLineItemResults",
                noLineItemsFound: "pages.project.composition.noLineItemsFound",
                newConfigurationName: "pages.project.composition.newConfigurationName",
                newFolderName: "pages.project.composition.newFolderName",
                remarks: "pages.project.composition.remarks",
                total: "pages.project.composition.total",
                commonOptionSetBySystem: "pages.project.composition.commonOptionSetBySystem",
                commonFolder: "pages.project.composition.commonFolder",
                invalidQuantityMessage: "pages.project.composition.invalidQuantityMessage",
                invalidNumberMessage: "pages.project.composition.invalidNumberMessage",
                hasNoPrice: "pages.project.composition.hasNoPrice",
                hasNoCost: "pages.project.composition.hasNoCost",
                generateDocumentsButton: {
                    disabledReason: "pages.project.composition.generateDocumentsButton.disabledReason"
                },
                customerViewIconTooltip: "pages.project.composition.customerViewIconTooltip",
                salesUnitViewIconTooltip: "pages.project.composition.salesUnitViewIconTooltip",
                manufacturingUnitViewIconTooltip: "pages.project.composition.manufacturingUnitViewIconTooltip",
                endToEndViewIconTooltip: "pages.project.composition.endToEndViewIconTooltip"
            }
        }
    },

    components: {
        dataGrid: {
            columns: {
                actions: "components.dataGrid.columns.actions",
                dragAndDrop: "components.dataGrid.columns.dragAndDrop"
            },
            filters: {
                before: "components.dataGrid.filters.before",
                after: "components.dataGrid.filters.after",
                contains: "components.dataGrid.filters.contains"
            }
        },
        dialog: {
            defaultCancelButtonLabel: "components.dialog.defaultCancelButtonLabel",
            defaultConfirmButtonLabel: "components.dialog.defaultConfirmButtonLabel"
        },
        documentGenerationButton: {
            label: "components.documentGenerationButton.label"
        },
        documentGenerationForm: {
            typeInputLabel: "components.documentGenerationForm.typeInputLabel",
            formatInputLabel: "components.documentGenerationForm.formatInputLabel",
            submitButtonLabel: "components.documentGenerationForm.submitButtonLabel"
        },
        dropdown: {
            unselectOption: "components.dropdown.filterDropdownUnselect",
            placeholder: "components.dropdown.placeholder"
        },
        error: {
            title: "components.error.title",
            message: "components.error.message"
        },
        header: {
            title: "components.header.title",
            subTitle: "components.header.subTitle",
            welcome: "components.header.welcome",
            importInProgress: "components.header.importInProgress",
            sessionTimeout: {
                timeUntil: "components.header.sessionTimeout.timeUntil", // use {time} for the formatted time left
                dialog: {
                    title: "components.header.sessionTimeout.dialog.title", // use {time} for the formatted time left
                    message: "components.header.sessionTimeout.dialog.message",
                    confirmButtonLabel: "components.header.sessionTimeout.dialog.confirmButtonLabel",
                    cancelButtonLabel: "components.header.sessionTimeout.dialog.cancelButtonLabel",
                    loggedOutTitle: "components.header.sessionTimeout.dialog.loggedOutTitle",
                    loggedOutMessage: "components.header.sessionTimeout.dialog.loggedOutMessage"
                },
                browserNotification: {
                    title: "components.header.sessionTimeout.browserNotification.title",
                    message: "components.header.sessionTimeout.browserNotification.message"
                }
            },
            logoutDialog: {
                title: "components.header.logoutDialog.title",
                message: "components.header.logoutDialog.message",
                confirmButtonLabel: "components.header.logoutDialog.confirmButtonLabel",
                cancelButtonLabel: "components.header.logoutDialog.cancelButtonLabel"
            }
        },
        input: {
            inputPlaceholder: "components.input.inputPlaceholder",
            searchInputPlaceholder: "components.input.searchInputPlaceholder"
        },
        textBox: {
            expand: "components.textBox.expand",
            truncate: "components.textBox.truncate"
        },
        unsavedChangesDialog: {
            title: "components.unsavedChangesDialog.title",
            message: "components.unsavedChangesDialog.message",
            save: "components.unsavedChangesDialog.save",
            decline: "components.unsavedChangesDialog.decline",
            cancel: "components.unsavedChangesDialog.cancel"
        }
    },
    error: {
        missingMandatoryValue: "error.missingMandatoryValue", // use {translatedParameterName} for the name of the missing characteristic / value
        failedToLoadPublicEnvironment: {
            title: "error.failedToLoadPublicEnvironment.title",
            message: "error.failedToLoadPublicEnvironment.message"
        },
        token: {
            cannotRefresh: {
                title: "error.token.cannotRefresh.title",
                body: "error.token.cannotRefresh.body"
            }
        },
        login: {
            accessDenied: {
                title: "error.login.accessDenied.title",
                body: "error.login.accessDenied.body"
            }
        },
        failedToInitializeSalesService: {
            title: "error.failedToInitializeSalesService.title",
            message: "error.failedToInitializeSalesService.message"
        },
        failedToLoadVisualization: {
            message: "error.failedToLoadVisualization.message" // use {url} for url and {version} for version
        },
        invalidSession: {
            title: "error.invalidSession.title",
            message: "error.invalidSession.message",
            reloadButtonLabel: "error.invalidSession.reloadButtonLabel"
        },
        failedToAddProduct: "error.failedToAddProduct",
        button: "error.button",
        retry: "error.retry",
        defaultErrorMessage: "error.defaultErrorMessage"
    },
    busy: {
        product: {
            addProducts: "busy.product.addProducts"
        },
        lineItem: {
            update: "busy.lineItem.update"
        },
        configuration: {
            add: "busy.configuration.add",
            save: "busy.configuration.save"
        },
        visualization: {
            ar: "busy.visualization.ar",
            image: "busy.visualization.image",
            save: "busy.visualization.save"
        }
    }
} as const

export default TranslationKeys
