import { GridColumnHeaderParams, GridColumnHeaderTitle } from "@mui/x-data-grid-pro"
import { L10n } from "@encoway/l10n"
import { SalesApi } from "../../../../../../../features/sales/sales.api"
import { Currency } from "../../../constants/Currency"

export default function HeaderWithCurrency(params: GridColumnHeaderParams, currencyProperty: string) {
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const currency =
        !salesDocument.properties[currencyProperty] || salesDocument.properties[currencyProperty] === Currency.NO_CURRENCY
            ? "?"
            : salesDocument.properties[currencyProperty]
    return <GridColumnHeaderTitle label={`${L10n.format(params.colDef.headerName ?? params.field)} [${currency}]`} columnWidth={params.colDef.width ?? 100} />
}
