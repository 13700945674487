import { useCallback, useMemo } from "react"
import MenuButton, { MenuButtonProps } from "../../../../components/buttons/menuButton/MenuButton"
import { SalesApi } from "../../sales.api"
import { useAppSelector } from "../../../../store/store"
import { SalesDocumentStatusValues } from "../../sales.constants"
import SalesUtils from "../../sales.utils"
import useDialog from "../../../../components/dialog/useDialog"
import { OrderDialog } from "./ordering/OrderDialog"
import { NextProcessStep } from "@encoway/sales-api-js-client"

export function SalesDocumentStatusButton() {
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const [executeProcessStep] = SalesApi.useExecuteProcessStepMutation()
    const processSteps = SalesApi.usePossibleProcessStepsQuery().data
    const { status } = SalesApi.useSalesDocumentQuery(undefined, {
        selectFromResult: ({ data }) => ({ status: data?.properties.quote_status })
    })

    const dialog = useDialog()

    const updateStatus = useCallback(
        (nextStep: string) => {
            if (status) {
                if (nextStep === SalesDocumentStatusValues.Order) {
                    dialog.open()
                } else {
                    executeProcessStep([status, nextStep])
                }
            }
        },
        [dialog, executeProcessStep, status]
    )

    const menuButtonItems: MenuButtonProps["items"] = useMemo(() => {
        return (
            processSteps
                ?.flatMap(step => step.possibleSteps)
                .map(({ nextStep }: NextProcessStep) => ({
                    value: nextStep,
                    text: SalesUtils.salesDocument.getStatusTranslation(nextStep)!,
                    cypressId: nextStep
                })) ?? []
        )
    }, [processSteps])

    return (
        <>
            <MenuButton
                variant="contained"
                text={SalesUtils.salesDocument.getStatusTranslation(status)!}
                disabled={readOnly || !status}
                color="secondary"
                value={status}
                items={menuButtonItems}
                onSelectItem={updateStatus}
            />
            {dialog.isOpen && <OrderDialog onClose={dialog.close} />}
        </>
    )
}
