import { PropsWithChildren, useEffect } from "react"
import { ContentWithToken } from "./ContentWithToken"
import { PublicEnvironment } from "../../../../features/environment/environment.types"
import { AbbOpenIdConnect } from "../abbOpenIdConnect/AbbOpenIdConnect"
import { useDeeplink } from "./useDeeplink"

type AuthorizedContentProps = PropsWithChildren<Pick<PublicEnvironment, "clientId" | "issuerUri" | "expectedIssuerUri" | "discoveryUri">>

export default function AuthorizedContent({ clientId, issuerUri, discoveryUri, expectedIssuerUri, children }: AuthorizedContentProps) {
    const { saveDeeplink, isAuthorizationUri, redirectForAuthorization } = useDeeplink()
    useEffect(() => {
        if (!isAuthorizationUri) {
            saveDeeplink()
            redirectForAuthorization()
        }
    }, [saveDeeplink, isAuthorizationUri, redirectForAuthorization])
    return isAuthorizationUri ? (
        <AbbOpenIdConnect clientId={clientId} realm="" authenticationServerUrl={discoveryUri} expectedIssuerUrl={expectedIssuerUri || issuerUri}>
            <ContentWithToken>{children}</ContentWithToken>
        </AbbOpenIdConnect>
    ) : null
}
