import useDialog from "../../../../../../../../../components/dialog/useDialog"
import { useLazyArQuery } from "../../../../../../../../../features/visualization/hooks/useLazyArQuery"
import { useProjectConfigurationLineItem } from "../../../../../hooks/useProjectConfigurationLineItem"

export function useArDialog() {
    const dialog = useDialog()
    const lineItem = useProjectConfigurationLineItem()
    const lineItemId =
        lineItem?.properties.FOLDER_TYPE === "LINEUP" && lineItem?.properties.LINEUP_ARTICLE_LINE_ITEM_ID
            ? lineItem.properties.LINEUP_ARTICLE_LINE_ITEM_ID
            : lineItem?.lineItemId
    const arQuery = useLazyArQuery()

    const openDialog = () => {
        arQuery.fetch(lineItemId!)
        dialog.open()
    }

    return {
        open: openDialog,
        close: dialog.close,
        isOpen: dialog.isOpen,
        qr: arQuery.result.data?.qr,
        error: arQuery.result.error,
        isFetching: arQuery.result.isFetching
    }
}
