import { SalesApi } from "../sales.api"
import { Characteristic } from "@encoway/c-services-js-client"
import { useMemo } from "react"
import SalesUtils from "../sales.utils"

export function useSalesDocumentPropertiesForm(characteristics: Characteristic[]) {
    const { numberOfInvalidInputs } = SalesApi.useSalesDocumentQuery(undefined, {
        selectFromResult: ({ data }) => {
            const characteristicsWithValidationProblems = !data
                ? []
                : characteristics.filter(characteristic => {
                      return SalesUtils.salesDocument.hasValidationProblems(characteristic.id, data)
                  })
            return { numberOfInvalidInputs: characteristicsWithValidationProblems.length }
        }
    })

    return useMemo(() => ({ isValid: numberOfInvalidInputs === 0, numberOfInvalidInputs }), [numberOfInvalidInputs])
}
