import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { Settings } from "../../settings"
import prepareHeaders from "../../store/prepareHeaders"
import { DcsDimensioningResult, DctDimensioningResult, DimensioningDcParams, DimensioningMvParams } from "./dimensioning.types"
import { transformDcsDimensioningResponse, transformDctDimensioningResponse } from "./dimensioning.utils"

export const DimensioningApi = createApi({
    reducerPath: "dimensioningApi",
    tagTypes: ["dcDimensioning"],
    baseQuery: fetchBaseQuery({
        baseUrl: Settings.configuration.baseUrl,
        prepareHeaders: prepareHeaders
    }),
    endpoints: builder => ({
        dimensioningDct: builder.query<DctDimensioningResult, DimensioningDcParams>({
            query: params => ({
                url: `/api/features/dimensioning/dct/configuration/${params.configurationId}/parameter/${params.parameterId}`,
                method: "POST"
            }),
            transformResponse: transformDctDimensioningResponse,
            providesTags: ["dcDimensioning"]
        }),
        dimensioningDcs: builder.query<DcsDimensioningResult, DimensioningDcParams>({
            query: params => ({
                url: `/api/features/dimensioning/dcs/configuration/${params.configurationId}/parameter/${params.parameterId}`,
                method: "POST"
            }),
            transformResponse: transformDcsDimensioningResponse,
            providesTags: ["dcDimensioning"]
        }),
        dimensioningMv: builder.query<any, DimensioningMvParams>({
            query: params => ({
                url: `/api/features/dimensioning/mv/configuration/${params.configurationId}/${params.dimensioningFunction}`,
                method: "POST",
                responseHandler: response => response.blob()
            })
        })
    })
})
