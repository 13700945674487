import { updateDoors } from "../AbbVisualization.utils"
import { useCallback, useMemo, useState } from "react"
import { Visualization } from "@encoway/visual-editor"

export function useVisualizationDoors(visualization: Visualization) {
    const [areHidden, setAreHidden] = useState(false)

    const toggle = useCallback(() => {
        updateDoors(visualization, !areHidden)
        setAreHidden(prevState => !prevState)
    }, [areHidden, visualization])

    return useMemo(() => ({ areHidden, toggle }), [areHidden, toggle])
}
