import { useCallback, useMemo } from "react"
import RouterPaths from "../../../../router/constants/RouterPaths"

const initialRoute = RouterPaths.DASHBOARD
const sessionStorageKey = "ABB_DRIVEHUB_DEEPLINK_ROUTE"
const currentUri = window.location.protocol + "//" + window.location.host + window.location.pathname
const authorizationUri = window.location.pathname.includes("abb-drive-hub-server")
    ? window.location.protocol + "//" + window.location.host + "/abb-drive-hub-server" + initialRoute
    : window.location.protocol + "//" + window.location.host + initialRoute

/**
 * This is a strange implementation to allow deep links with ABB CIAM since ABB CIAM does not allow redirect urls
 * with wildcards, so for example "/app/projects/<projectId>" cannot be defined as valid redirect url.
 */
export const useDeeplink = () => {
    const saveDeeplink = useCallback(() => {
        const route = window.location.pathname.replace("/abb-drive-hub-server", "")
        sessionStorage.setItem(sessionStorageKey, route)
    }, [])
    const isAuthorizationUri = currentUri === authorizationUri
    const redirectForAuthorization = useCallback(() => {
        window.location.href = authorizationUri
    }, [])
    const deeplink = useMemo(() => {
        return sessionStorage.getItem(sessionStorageKey)
    }, [])
    const removeDeeplink = useCallback(() => {
        return sessionStorage.removeItem(sessionStorageKey)
    }, [])
    return {
        deeplink,
        saveDeeplink,
        isAuthorizationUri,
        redirectForAuthorization,
        removeDeeplink
    }
}
