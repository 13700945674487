import { Display, DisplayProps } from "@encoway/cui-configurator-components"
import { Stack } from "@mui/material"
import { ComponentFactory } from "@encoway/react-configurator"
import { ComponentName } from "@encoway/cui-configurator-components/src/constants"
import { AbbDisplayStyles } from "./AbbDisplay.styles"

export function AbbDisplay(props: Readonly<DisplayProps>) {
    const selectedValue = props.data.selectedValues?.at(0)
    const onRenderValue: DisplayProps["onRenderValue"] = (value, defaultRender) => {
        return (
            <Stack direction="row">
                {defaultRender?.(value)}
                {selectedValue &&
                    ComponentFactory.instanceOf(ComponentName.InfoButton, {
                        ...props,
                        data: props.data.selectedValues?.at(0)
                    })}
            </Stack>
        )
    }
    return <Display {...props} onRenderValue={onRenderValue} styles={AbbDisplayStyles} />
}
