import { MessageBarType } from "@fluentui/react"
import { getTheme } from "@fluentui/react/lib/Styling"

const theme = getTheme()

interface NotificationProperties {
    type: MessageBarType
    translationKey: string
    color: string
}

export const Notifications: Record<string, NotificationProperties> = {
    INFO: {
        type: MessageBarType.info,
        translationKey: "configuration.widgets.notification.info",
        color: theme.palette.neutralTertiary
    },
    WARN: {
        type: MessageBarType.severeWarning,
        translationKey: "configuration.widgets.notification.warning",
        color: theme.palette.yellowDark
    },
    ERROR: {
        type: MessageBarType.error,
        translationKey: "configuration.widgets.notification.error",
        color: theme.palette.themeLight
    }
}
