import { useImperativeHandle, useState } from "react"
import { BusyOverlayProps } from "@encoway/cui-application-components/src/components/BusyOverlay/BusyOverlay.types"
import { useAppDispatch } from "../../../../../../../../store/store"
import { BusySlice } from "../../../../../../../../features/busy/busy.slice"

/**
 * Triggers the global application spinner.
 */
export const AbbBusyOverlay = (props: BusyOverlayProps) => {
    const dispatch = useAppDispatch()
    const [hidden, setHidden] = useState(props.initiallyHidden)
    useImperativeHandle(props.componentRef, () => ({
        setVisible: visible => {
            dispatch(visible ? BusySlice.actions.setBusy() : BusySlice.actions.setIdle())
            setHidden(!visible)
        },
        isVisible: () => hidden !== true
    }))
    return null
}
