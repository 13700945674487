import { useEffect } from "react"
import { InputFieldProps } from "@encoway/cui-configurator-components"
import { PrimaryButton } from "@fluentui/react"
import { Constants, walkParameters } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"
import MatlabButtonStyles from "./MatlabButton.styles"
import CuiUtils from "../../utils/CuiUtils"
import { useLazyMatlabQuery } from "../../../../../../../../features/matlab/matlab.api"
import { useAppDispatch, useAppSelector } from "../../../../../../../../store/store"
import TranslationKeys from "../../../../../../../../features/translations/TranslationKeys"
import { ErrorSlice } from "../../../../../../../../features/error/error.slice"
import { BusySlice } from "../../../../../../../../features/busy/busy.slice"

export default function MatlabButton(props: Readonly<InputFieldProps>) {
    const [triggerLazyMatlabQuery, { error }] = useLazyMatlabQuery()
    const configurationContainer = useAppSelector(state => state.sales.configurationContainer)!
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (error) {
            let matlabError: Error
            if ("status" in error && error.status === 428) {
                matlabError = {
                    name: L10n.format(TranslationKeys.pages.project.configuration.matlab.notTerminalError.title),
                    message: L10n.format(TranslationKeys.pages.project.configuration.matlab.notTerminalError.message) + " " + error.data
                }
            } else if ("data" in error && typeof error.data === "string") {
                matlabError = {
                    name: L10n.format(TranslationKeys.pages.project.configuration.matlab.error.title),
                    message: error.data
                }
            } else {
                matlabError = {
                    name: L10n.format(TranslationKeys.pages.project.configuration.matlab.error.title),
                    message: L10n.format(TranslationKeys.pages.project.configuration.matlab.error.unknownErrorMessage)
                }
            }
            dispatch(ErrorSlice.actions.set(matlabError))
        }
    }, [error, dispatch])

    const inputChangedOfParameter = (parameterName: string): boolean => {
        const parameter = walkParameters(
            configurationContainer,
            parameter => parameter.name === parameterName,
            parameter => parameter
        ).at(0)
        return Boolean(parseInt(parameter?.selectedValues?.at(0)?.value))
    }

    const disableButton = (): boolean => {
        if (props.data.viewPortProperties) {
            const value = props.data.viewPortProperties["ML_RoutineDependency" as keyof typeof props.data.viewPortProperties] as string
            const parameterName = CuiUtils.readViewportPropertyValue(value)
            return parameterName ? inputChangedOfParameter(parameterName) : false
        } else {
            return false
        }
    }

    const submit = async () => {
        const configurationId = (props.options as any).appSettings.configuration.options.configurationId
        dispatch(BusySlice.actions.setBusy())
        await triggerLazyMatlabQuery({ configurationId: configurationId, parameterName: props.data.name })
        dispatch(BusySlice.actions.setIdle())
        props.onValueChanged(props.data, props.data.selectedValues?.at(0)?.value === "1" ? "0" : "1", Constants.ValueFormat.Unformatted)
    }

    return <PrimaryButton disabled={disableButton()} text={props.data.translatedName} onClick={submit} styles={MatlabButtonStyles} />
}
