import { SalesQueryFnOptions } from "../sales.api.utils"
import { ApplyConfigurationInterfaceDecisionsArgs, ApplyConfigurationInterfaceDecisionsResponse } from "../sales.api.types"
import TranslationKeys from "../../translations/TranslationKeys"
import { L10n } from "@encoway/l10n"
import { SnackbarSlice } from "../../snackbar/snackbar.slice"

export const applyConfigurationInterfaceDecisionsQuery: SalesQueryFnOptions<
    ApplyConfigurationInterfaceDecisionsResponse,
    ApplyConfigurationInterfaceDecisionsArgs
>["query"] = async (args, service, api) => {
    const response: ApplyConfigurationInterfaceDecisionsResponse = await service.custom.call(`/projectconfiguration/apply`, args)
    if (response.failedDecisions.length > 0) {
        const key = TranslationKeys.pages.project.configuration.configurationInterfaceDecisions.failedToApplyDecision
        const message = response.failedDecisions.map(decision => L10n.format(key, decision as unknown as Record<string, string>)).join(" ")
        api.dispatch(SnackbarSlice.actions.open({ severity: "error", message }))
    }
    return response
}
