import { ProductSelection } from "./ProductQuickSelection"
import { Product } from "@encoway/c-services-js-client"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"
import { ProductCategory } from "../../../../../features/sales/sales.types"
import { ArticleWithQuantity } from "../../../../../features/sales/sales.api.types"

export function mapSelectionToArticlesWithQuantity(selection: ProductSelection): ArticleWithQuantity[] {
    return Array.from(flattenSelection(selection).entries()).map(([product, quantity]) => ({
        articleId: product.id,
        articleName: product.name,
        quantity
    }))
}

export function flattenSelection(selection: ProductSelection) {
    const result = new Map()
    Object.values(selection).forEach(value => value.forEach((quantity, product) => result.set(product, quantity)))
    return result
}

export function isEmptySelection(selection: ProductSelection) {
    return flattenSelection(selection).size === 0
}

export function updateSelection(prev: ProductSelection, productGroupId: string, product: Product, amount: number): ProductSelection {
    const result = {
        ...prev,
        [productGroupId]: prev[productGroupId] ?? new Map()
    }
    if (amount === 0) {
        result[productGroupId].delete(product)
    } else {
        result[productGroupId].set(product, amount)
    }
    return result
}

export function getProductCategory(selection: ProductSelection) {
    const productWithProductCategory = Array.from(flattenSelection(selection).keys()).find(product =>
        CatalogUtils.getCharacteristicValue(product, Characteristics.ProductCategory.id)
    )
    return productWithProductCategory
        ? (CatalogUtils.getCharacteristicValue<string>(productWithProductCategory, Characteristics.ProductCategory.id) as ProductCategory)
        : undefined
}
