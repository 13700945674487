import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from "react"
import { IPartialTheme, IProcessedStyleSet } from "@fluentui/react/lib/Styling"
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities"
import { DoorIcon } from "@fluentui/react-icons-mdl2"
import { Button, Portal, Tooltip } from "@mui/material"
import { Viewer, VisualEditor, VisualEditorProps } from "@encoway/visual-editor"
import { IVisualEditor, IVisualizationStyles, VisualizationProps, VisualizationStyles } from "@encoway/cui-application-components"
import { L10n } from "@encoway/l10n"
import { getCustomContextMenuEntries } from "./AbbVisualization.utils"
import AbbArDialog from "./AbbArDialog"
import { useAppDispatch, useAppSelector } from "../../../../../../../../store/store"
import { VisualizationSlice } from "../../../../../../../../features/visualization/visualization.slice"
import { SalesSlice } from "../../../../../../../../features/sales/sales.slice"
import { ProjectConfigurationContainerIds } from "../../../../ProjectConfiguration.constants"
import { IVisualizationNodeExtended } from "../../../../../../../../features/visualization/visualization.types"
import TranslationKeys from "../../../../../../../../features/translations/TranslationKeys"
import { useVisualizationDoors } from "./hooks/useVisualizationDoors"
import { useVisualizationToolbar } from "./hooks/useVisualizationToolbar"
import { ActiveToggleDoorsButtonSx, ToggleDoorsButtonSx, ToolbarButtonSx } from "./AbbVisualization.styles"

function IVisualization({ styles, theme, visualization, visualEditorForwardRef, onReady, readOnly, settings, locale, eventBus }: Readonly<VisualizationProps>) {
    const ref = useRef<IVisualEditor>()
    const visualEditorRef = (visualEditorForwardRef as MutableRefObject<IVisualEditor>) ?? ref

    const style = useMemo(() => {
        const stylesFunction = styles && typeof styles === "function" ? styles : undefined
        return stylesFunction?.(theme as IPartialTheme)
    }, [styles, theme])

    const classNames = useMemo(() => {
        return classNamesFunction()(styles, theme) as IProcessedStyleSet<IVisualizationStyles>
    }, [styles, theme])

    const hideVisualization = useAppSelector(state => state.sales.hideVisualization)
    const dispatch = useAppDispatch()
    const doors = useVisualizationDoors(visualization)
    const { toolbar, arDialog } = useVisualizationToolbar()

    useEffect(() => {
        dispatch(VisualizationSlice.actions.setVisualization(visualization))
        dispatch(SalesSlice.actions.setVisualizationIsAvailable(true))
    }, [dispatch, visualization])

    const onContextMenu: VisualEditorProps["onContextMenu"] = useCallback(
        (node: object, dismissMenu: () => void, contextAnchor: object | null) => {
            if (contextAnchor) {
                const items = getCustomContextMenuEntries(node as IVisualizationNodeExtended, visualization, dismissMenu)
                return { items, replace: items.length > 0 }
            } else {
                return { items: [], replace: false }
            }
        },
        [visualization]
    )

    const customOnReady = useCallback(() => {
        onReady ? onReady(visualEditorRef) : visualEditorRef.current?.zoom()
    }, [onReady, visualEditorRef])

    const visualProps: VisualEditorProps = {
        eventBus: eventBus,
        className: classNames.visualEditor,
        visualization: visualization,
        settings: settings,
        locale: locale,
        style: style?.visualEditorDiv,
        onReady: customOnReady,
        // @ts-ignore
        ref: visualEditorRef,
        onContextMenu: onContextMenu // CUSTOMIZING
    }

    return hideVisualization ? null : (
        <>
            <Portal container={() => document.getElementById(ProjectConfigurationContainerIds.VISUALIZATION)}>
                <div className={classNames.root}>
                    {/**@ts-ignore**/}
                    {readOnly ? <Viewer {...visualProps} /> : <VisualEditor {...visualProps} />}
                </div>
            </Portal>
            {toolbar && (
                <Portal container={toolbar}>
                    <Button variant="text" onClick={arDialog.open} disableRipple sx={ToolbarButtonSx}>
                        AR
                    </Button>
                    {!arDialog.isFetching && <AbbArDialog close={arDialog.close} isOpen={arDialog.isOpen} qr={arDialog.qr} error={arDialog.error?.message} />}
                    <Tooltip title={L10n.format(TranslationKeys.pages.project.configuration.visualization.hideDoors)} placement="right">
                        <Button variant="text" onClick={doors.toggle} disableRipple sx={doors.areHidden ? ActiveToggleDoorsButtonSx : ToggleDoorsButtonSx}>
                            <DoorIcon />
                        </Button>
                    </Tooltip>
                </Portal>
            )}
        </>
    )
}

export const AbbVisualization = styled(IVisualization, VisualizationStyles)
AbbVisualization.displayName = "Visualization"
