import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import { BusySlice } from "../features/busy/busy.slice"
import { CatalogApi } from "../features/catalog/catalog.api"
import { CatalogSlice } from "../features/catalog/catalog.slice"
import { ConfigurationApi } from "../features/configuration/configuration.api"
import { DimensioningApi } from "../features/dimensioning/dimensioning.api"
import { EnvironmentApi } from "../features/environment/environment.api"
import { ErrorSlice } from "../features/error/error.slice"
import { FetchApi } from "../features/fetch/fetch.api"
import { GraphApi } from "../features/graph/graph.api"
import { MatlabApi } from "../features/matlab/matlab.api"
import { OidcSlice } from "../features/oidc/oidc.slice"
import { ProgressSlice } from "../features/progress/progress.slice"
import { SalesApi } from "../features/sales/sales.api"
import { SalesSlice } from "../features/sales/sales.slice"
import { SnackbarSlice } from "../features/snackbar/snackbar.slice"
import { TranslationsApi } from "../features/translations/translations.api"
import { TranslationsSlice } from "../features/translations/translations.slice"
import { VisualizationApi } from "../features/visualization/visualization.api"
import { VisualizationSlice } from "../features/visualization/visualization.slice"
import { Dispatch, RootState } from "./store.types"

export const store = configureStore({
    reducer: {
        [BusySlice.name]: BusySlice.reducer,
        [CatalogSlice.name]: CatalogSlice.reducer,
        [ErrorSlice.name]: ErrorSlice.reducer,
        [OidcSlice.name]: OidcSlice.reducer,
        [ProgressSlice.name]: ProgressSlice.reducer,
        [SalesSlice.name]: SalesSlice.reducer,
        [SnackbarSlice.name]: SnackbarSlice.reducer,
        [TranslationsSlice.name]: TranslationsSlice.reducer,
        [VisualizationSlice.name]: VisualizationSlice.reducer,
        [CatalogApi.reducerPath]: CatalogApi.reducer,
        [ConfigurationApi.reducerPath]: ConfigurationApi.reducer,
        [DimensioningApi.reducerPath]: DimensioningApi.reducer,
        [EnvironmentApi.reducerPath]: EnvironmentApi.reducer,
        [FetchApi.reducerPath]: FetchApi.reducer,
        [GraphApi.reducerPath]: GraphApi.reducer,
        [MatlabApi.reducerPath]: MatlabApi.reducer,
        [SalesApi.reducerPath]: SalesApi.reducer,
        [TranslationsApi.reducerPath]: TranslationsApi.reducer,
        [VisualizationApi.reducerPath]: VisualizationApi.reducer
    },
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({ serializableCheck: false })
            .concat(CatalogApi.middleware)
            .concat(ConfigurationApi.middleware)
            .concat(DimensioningApi.middleware)
            .concat(EnvironmentApi.middleware)
            .concat(FetchApi.middleware)
            .concat(GraphApi.middleware)
            .concat(MatlabApi.middleware)
            .concat(SalesApi.middleware)
            .concat(TranslationsApi.middleware)
            .concat(VisualizationApi.middleware)
    }
})

export const useAppDispatch: () => Dispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
