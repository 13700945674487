import { ReactElement } from "react"
import { GridActionsCellItemProps, GridColDef, GridRowParams, GridValidRowModel, GridValueGetter } from "@mui/x-data-grid-pro"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { getContainsGridFilterOperator, getDateGridFilterOperators } from "../../../../components/dataGrid/dataGridFilterOperators"
import { dateFormatter } from "../../../../components/dataGrid/dataGridValueFormatters"
import renderEmptyHeader from "../../../../components/dataGrid/./renderer/renderEmptyHeader"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"
import { LockCell } from "./cells/lock/LockCell"
import { PermissionsCell } from "./cells/permissions/PermissionsCell"
import { StatusCell } from "./cells/status/StatusCell"
import { Characteristic } from "@encoway/c-services-js-client"
import { AbbSalesDocumentProperty } from "../../../../features/sales/sales.types"

const valueGetter: GridValueGetter = (_value, row: GridValidRowModel, column: GridColDef) => row.properties[column.field]

export const ProjectsDataGridColumns = (
    salesDocumentPropertyCharacteristics: Characteristic[],
    getActions: (params: GridRowParams) => ReactElement<GridActionsCellItemProps>[]
): GridColDef[] => {
    const containsGridFilterOperator = getContainsGridFilterOperator()
    const dateGridFilterOperators = getDateGridFilterOperators()
    const getHeaderName = (propertyName: AbbSalesDocumentProperty) =>
        salesDocumentPropertyCharacteristics.find(characteristic => characteristic.id === propertyName)?.name ?? (propertyName as string)
    return [
        {
            field: SalesDocumentProperties.QUOTE_LOCKED_BY,
            headerName: getHeaderName("quote_locked_by"),
            width: 40,
            filterable: false,
            sortable: false,
            renderCell: LockCell,
            renderHeader: renderEmptyHeader
        },
        {
            field: "permission",
            headerName: L10n.format(TranslationKeys.pages.projects.dataGrid.columns.permission),
            width: 40,
            type: "boolean",
            renderCell: PermissionsCell
        },
        {
            field: SalesDocumentProperties.QUOTE_ID,
            headerName: getHeaderName("quote_id"),
            hideable: false,
            width: 120,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.DRIVESMART_ID,
            headerName: getHeaderName("drivesmart_id"),
            width: 120,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.PROJECT_NAME,
            headerName: getHeaderName("project_name"),
            flex: 1,
            minWidth: 200,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.SALESFORCE_OPPORTUNITY_NUMBER,
            headerName: getHeaderName("salesforce_opportunity_number"),
            flex: 1,
            minWidth: 80,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.CUSTOMER,
            headerName: getHeaderName("customer"),
            width: 200,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.QUOTE_CREATED_BY,
            headerName: getHeaderName("quote_created_by"),
            minWidth: 200,
            flex: 1,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.CREATED_AT,
            headerName: getHeaderName("quote_created_at"),
            type: "date",
            minWidth: 120,
            valueFormatter: dateFormatter,
            filterOperators: dateGridFilterOperators,
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.MODIFIED_AT,
            headerName: getHeaderName("quote_modified_at"),
            type: "date",
            width: 120,
            valueFormatter: dateFormatter,
            filterOperators: dateGridFilterOperators,
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.QUOTE_MODIFIED_BY,
            headerName: getHeaderName("quote_modified_by"),
            minWidth: 200,
            flex: 1,
            filterOperators: [containsGridFilterOperator],
            valueGetter: valueGetter
        },
        {
            field: SalesDocumentProperties.QUOTE_STATUS,
            headerName: getHeaderName("quote_status"),
            width: 120,
            filterOperators: [containsGridFilterOperator],
            renderCell: StatusCell
        },
        {
            field: "actions",
            type: "actions",
            headerName: L10n.format(TranslationKeys.components.dataGrid.columns.actions),
            hideable: false,
            width: 0,
            renderHeader: renderEmptyHeader,
            getActions: getActions
        }
    ]
}
