import React from "react"
import { BusyOverlay } from "../../features/busy/components/busyOverlay/BusyOverlay"
import { ConanBase } from "./components/ConanBase/ConanBase"
import { EnvironmentApi } from "../../features/environment/environment.api"
import { AbbOpenIdConnect } from "../rootPage/components/abbOpenIdConnect/AbbOpenIdConnect"

export const ConanPage = () => {
    const { data: environment } = EnvironmentApi.usePublicEnvironmentQuery()
    if (environment === undefined) {
        return <BusyOverlay />
    } else {
        return (
            <AbbOpenIdConnect
                clientId={environment.clientId}
                realm=""
                authenticationServerUrl={environment.discoveryUri}
                expectedIssuerUrl={environment.expectedIssuerUri || environment.issuerUri}
                redirectUri={window.location.protocol + "//" + window.location.host + window.location.pathname}
            >
                <ConanBase />
            </AbbOpenIdConnect>
        )
    }
}
