import { PropsWithChildren, useEffect } from "react"
import { Http } from "@encoway/sales-showroom-auth"
import { Settings } from "../../../../settings"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import { CatalogService } from "@encoway/c-services-js-client"
import { CatalogSlice } from "../../../../features/catalog/catalog.slice"
import { useToken } from "../abbOpenIdConnect/AbbOpenIdConnect"

export function ContentWithCatalogService({ children }: PropsWithChildren<unknown>) {
    const catalogService = useAppSelector(state => state.catalog.catalogService)

    const tokenFunction = useToken()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const http = Http.Bearer(tokenFunction).withCredentialsInclude()
        const catalogService = new CatalogService(http, Settings.configuration.baseUrl, Settings.locale)
        dispatch(CatalogSlice.actions.setCatalogService(catalogService))
    }, [tokenFunction, dispatch])

    return catalogService ? <>{children}</> : null
}
