import { createContext } from "react"
import { ProjectConfigurationLineItem } from "../../../../../../features/sales/sales.api.types"

export const ProjectConfigurationTreeContext = createContext<{
    expandItem: (item: ProjectConfigurationLineItem) => void
    collapseItem: (item: ProjectConfigurationLineItem) => void
}>({
    expandItem: (_item: ProjectConfigurationLineItem) => undefined,
    collapseItem: (_item: ProjectConfigurationLineItem) => undefined
})
