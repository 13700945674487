import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import { useState } from "react"
import { ProjectConfigurationLineItemWithParent } from "../treeItem/TreeItem"
import Dialog, { DialogProps } from "../../../../../../components/dialog/Dialog"
import SalesUtils from "../../../../../../features/sales/sales.utils"

interface ItemSplitConfirmationDialogProps extends Pick<DialogProps, "onClose"> {
    item: ProjectConfigurationLineItemWithParent
    onSplitDecision: (splitItems: boolean) => void
}

export const ItemSplitConfirmationDialog = ({ onClose, onSplitDecision, item }: ItemSplitConfirmationDialogProps) => {
    const [name] = useState(SalesUtils.lineItems.getSubName(item.lineItem))
    return (
        <Dialog
            open={true}
            onClose={onClose}
            title={L10n.format(TranslationKeys.lineItem.splitItemDialog.title)}
            message={L10n.format(TranslationKeys.lineItem.splitItemDialog.message, { lineupName: name })}
            defaultDialogActions={{
                onConfirm: () => onSplitDecision(true),
                onCancel: () => onSplitDecision(false),
                confirmButtonLabel: TranslationKeys.lineItem.splitItemDialog.confirmButtonLabel,
                cancelButtonLabel: TranslationKeys.lineItem.splitItemDialog.cancelButtonLabel
            }}
        ></Dialog>
    )
}
