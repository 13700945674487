import { GridCellParams, GridColDef, GridRowClassNameParams } from "@mui/x-data-grid-pro"
import SalesUtils from "../../../../../features/sales/sales.utils"
import { CONFIGURABLE_ARTICLE_CLASS_NAME, FIRST_LEVEL_ITEM_CLASS_NAME } from "./LineItemsDataGrid.styles"
import { BomNode } from "./LineItemsDataGrid"
import { AbbSalesDocumentEntity } from "../../../../../features/sales/sales.types"
import {
    CostLineItemProperties,
    LineItemProperties,
    SalesPriceLineItemProperties,
    TransferPriceDiscountLineItemProperties
} from "../../../../../features/sales/sales.constants"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { Composition } from "../../../../../features/sales/sales.api.types"
import setCellValue from "./utils/setCellValue"
import { UserInformation } from "../../../../../features/oidc/hooks/useUserInformation"
import { OidcUtils } from "../../../../../features/oidc/oidc.utils"
import { transferPricesProperties } from "./constants/transferPricesProperties"
import { listPricesProperties } from "./constants/listPricesProperties"
import { getCellValue } from "./utils/getCellValue"
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces"
import { conditionalLineItemDataGridColumns, ProjectCompositionColumnMapping } from "./constants/lineItemDataGridViewColumns"

export const isCellEditable = (params: GridCellParams<BomNode>) => {
    return SalesUtils.lineItems.isEditableLineItemProperty(params.row.lineItem, params.field)
}

export const getRowClassName = (params: GridRowClassNameParams<BomNode>) => {
    let className = ""
    const { level, lineItem } = params.row
    if (level === 0) className = FIRST_LEVEL_ITEM_CLASS_NAME

    if (lineItem.properties.configurable) className = CONFIGURABLE_ARTICLE_CLASS_NAME

    return className
}

export const getFooterRow = (salesDocument: AbbSalesDocumentEntity, userInformation?: UserInformation): BomNode => {
    return {
        expanded: true,
        level: 1, // empty quote relevant cell
        lineItem: {
            isSalesDocument: true,
            lineItemId: salesDocument.salesDocumentId,
            permission: salesDocument.permission,
            propertyPermissions: {
                ...salesDocument.calculationPropertyPermissions
            },
            configurationPermission: [],
            properties: {
                ...salesDocument.calculationProperties,
                isSubarticle: true, // disable actions
                isLeaf: true, // disable toggle at product name cell
                positionnumber: L10n.format(TranslationKeys.pages.project.composition.total)
            }
        }
    }
}

export const getRows = (composition?: Composition, parent?: BomNode): BomNode[] => {
    const lineItems = composition?.[parent?.lineItem.lineItemId ?? "ROOT"] ?? []
    return lineItems.flatMap(lineItem => {
        const expanded = Object.keys(composition!).includes(lineItem.lineItemId)
        const bomNode: BomNode = { lineItem: lineItem, parent, expanded, level: parent ? parent.level + 1 : 0 }
        if (expanded) bomNode.children = getRows(composition, bomNode)
        return [bomNode].concat(bomNode.children ?? [])
    })
}

export const mapColumn = (column: GridColDef, readOnly: boolean): GridColDef => ({
    ...column,
    headerName: column.headerName ? L10n.format(column.headerName) : column.headerName,
    valueGetter: column.valueGetter ?? getCellValue,
    valueSetter: column.valueSetter ?? ((value, row) => setCellValue(value, row, column.field)),
    editable: column.editable !== false && !readOnly,
    disableColumnMenu: column.disableColumnMenu || (column.sortable === false && column.filterable === false)
})

export const filterColumn = (column: GridColDef, userInformation?: UserInformation): boolean => {
    const isTransferPriceProperty = transferPricesProperties.includes(column.field)
    const isListPriceProperty = listPricesProperties.includes(column.field)
    if (isTransferPriceProperty) {
        return OidcUtils.hasReadTransferPricesRole(userInformation)
    } else if (isListPriceProperty) {
        return OidcUtils.hasReadListPricesRole(userInformation)
    } else if (column.field === LineItemProperties.MULTIPLIER) {
        return OidcUtils.hasReadMultiplierRole(userInformation)
    } else if (TransferPriceDiscountLineItemProperties.includes(column.field)) {
        return OidcUtils.hasReadTransferPriceDiscountRole(userInformation)
    } else if (CostLineItemProperties.includes(column.field)) {
        return OidcUtils.hasReadCostPricesRole(userInformation)
    } else if (SalesPriceLineItemProperties.includes(column.field)) {
        return OidcUtils.hasReadSalesPricesRole(userInformation)
    } else {
        return true
    }
}

export function getColumnVisibilityModel(projectCompositionView: string): GridColumnVisibilityModel {
    const visibleColumns: string[] = ProjectCompositionColumnMapping[projectCompositionView] || []
    return conditionalLineItemDataGridColumns.reduce(
        (visibilityModel, column) => ({
            ...visibilityModel,
            [column]: visibleColumns.includes(column)
        }),
        {}
    )
}
