import { getTheme } from "@fluentui/react"
import { CSSProperties } from "react"
import CuiStyleConstants from "../../constants/CuiStyleConstants"

const theme = getTheme()

const AbbRootStyles: CSSProperties = {
    flex: 1,
    padding: "0em 2em",
    backgroundColor: theme.palette.white,
    borderRadius: CuiStyleConstants.CONTAINER_BORDER_RADIUS
}

export default AbbRootStyles
