export const Characteristics = {
    ProductImage: { id: "Product_image" },
    CatalogLink: { id: "CATALOGUE_LINK" },
    Date: { id: "Date" },
    LongText: { id: "long_text_gui" },
    ShortText: { id: "short_text_gui" },
    GraphDefinition: { id: "GraphDefinition" },
    DocumentTypes: { id: "documentTypes" },
    Translations: { id: "translationsFile" },
    IndustrySegment: { id: "INDUSTRY_SEGMENT" },
    DestinationCountry: { id: "DESTINATION_COUNTRY" },
    TransferPriceCurrency: { id: "TRANSFER_PRICE_CURRENCY" },
    SalesPriceCurrency: { id: "SALES_PRICE_CURRENCY" },
    OrderGeneration: {
        AdditionalMessage: { id: "long_text_gui" },
        DocumentTemplateId: { id: "DOCUMENT_TEMPLATE_ID" },
        DocumentFormat: { id: "DOCUMENT_FORMAT" }
    },
    QuickSelectionEnabled: {
        id: "QUICKSELECTION_ENABLED",
        possibleValues: {
            NONE: 0,
            SHOW_ALL_GROUPS: 1,
            SHOW_SUB_GROUPS: 2
        }
    },
    LvFrameSize: { id: "LV_FRAME_SIZE" },
    ProductCategory: {
        id: "PRODUCT_CATEGORY",
        possibleValues: {
            LV: "LV",
            MV: "MV",
            DC: "DC"
        }
    },
    LocalSalesUnit: { id: "LOCAL_SALES_UNIT" },
    ConfigurableProductReferenceModel: { id: "CONFIG_REF_MODEL" },
    ConfigurableProductReferencePreset: { id: "CONFIG_REF_PRESET" },
    DcModuleFrameSize: { id: "DC_ModuleFrameSize" },
    DcThyristorMaxTemp: { id: "DC_ThyristorMaxTemp" },
    DcModuleDimension: { id: "DC_ModuleDimension" },
    DcThyristorTemp: { id: "DC_ThyristorTemp" },
    DcCurrent: { id: "DC_Current" },
    DcMargin: { id: "DC_Margin" },
    DcProductName: { id: "DC_ProductName" },
    VisualizationSettings: { id: "visualizationSettings" },
    RetentionTime: {
        id: "retentionTime"
    },
    ArPlacement: { id: "arPlacement" },
    ArScaleFactor: { id: "arScaleFactor" },
    AlertColor: { id: "ANNOUNCEMENT_COLOR" },
    AlertActive: { id: "ANNOUNCEMENT_ACTIVE" },
    CatalogDefaultView: {
        id: "CATALOGUE_DEFAULT_VIEW",
        possibleValues: {
            CATALOG: "CATALOG",
            QUICK_SELECTION: "QUICKSELECTION"
        }
    },
    HideConfigurationTree: { id: "HIDE_CONFIGURATION_TREE" },
    Orientation: {
        id: "ORIENTATION",
        possibleValues: {
            HORIZONTAL: "HORIZONTAL",
            VERTICAL: "VERTICAL"
        }
    },
    CuiDefaultLayout: {
        id: "CONFIGURATION_UI_DEFAULT_LAYOUT",
        possibleValues: {
            FocusVisualization: "FOCUS_VISUALIZATION",
            FocusConfiguration: "FOCUS_CONFIGURATION",
            OnlyConfiguration: "ONLY_CONFIGURATION",
            Reset: "-"
        }
    },
    LineupCompatible: {
        id: "LINEUP_COMPATIBLE"
    },
    PlmMapping: {
        id: "PLM_Mapping"
    },
    SapMapping: {
        id: "SAP_Mapping"
    },
    MvDimensioningCalculationFunction: {
        id: "MV_DimensioningCalculationFunction",
        possibleValues: {
            ADDITIONAL_CALCULATION: "additional-calculation",
            ELECTRICAL_DESIGN: "electrical-design"
        }
    },
    ProjectPropertyValueType: {
        id: "PROJECT_PROPERTY_VALUE_TYPE",
        possibleValues: {
            DATE: "date",
            EMAIL: "email",
            PRICE_LIST: "price_list"
        }
    },
    ProjectPropertyValueMaxLength: {
        id: "PROJECT_PROPERTY_VALUE_MAX_LENGTH"
    },
    ProjectPropertyCharacteristics: {
        id: "PROJECT_PROPERTY_CHARACTERISTICS"
    },
    ProjectDetailsOrder: {
        id: "PROJECT_DETAILS_ORDER"
    },
    ProjectDetailsValueRequired: {
        id: "PROJECT_DETAILS_VALUE_REQUIRED"
    },
    CreateProjectDialogOrder: {
        id: "CREATE_PROJECT_DIALOG_ORDER"
    },
    CreateProjectDialogValueRequired: {
        id: "CREATE_PROJECT_DIALOG_VALUE_REQUIRED"
    },
    ProjectInfoTooltipOrder: {
        id: "PROJECT_INFO_TOOLTIP_ORDER"
    },
    UserName: {
        id: "USERNAME"
    }
} as const

export const ProductGroupIds = {
    Catalog: "_CATALOGUE",
    News: "NEWS",
    Announcements: "announcements",
    LV: "LV_products",
    MV: "MV_Products",
    DC: "DC_products",
    ProjectDetails: "PROJECT_DETAILS"
} as const

export const ContentBlockIds = {
    Translations: "application_texts",
    DocumentTypes: "document_types",
    ProjectDocumentTypes: "document_types_project",
    Announcement: "announcement",
    HorizontalLayout: "LAYOUT_HORIZONTAL",
    VerticalLayout: "LAYOUT_VERTICAL",
    CreateProjectDialog: "CREATE_PROJECT_DIALOG",
    ProjectInfoTooltip: "PROJECT_INFO_TOOLTIP",
    ProjectsTable: "PROJECTS_TABLE"
} as const

export const ViewIds = {
    QUICK_SELECTION: "abb.quickselectionview",
    SALES_FILTER: "encoway.salesFilterView",
    CONFIGURATION_INTERFACE: "abb.configurationInterfaceView",
    UNIT_OPTIONS: "abb.unitOptionsView",
    MFK_VIEW: "abb.MV_MfkView"
} as const

export const BLOCKED_LAYOUT_SECTION: string[] = [ContentBlockIds.HorizontalLayout, ContentBlockIds.VerticalLayout]
