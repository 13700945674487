import { GridRowClassNameParams } from "@mui/x-data-grid-pro"
import { AbbDimensioningDctColumns } from "./AbbDimensioningDctColumns"
import { getIdsOfBestDcDimensioningResults, getIdsOfEnabledDcDimensioningResults, mapDimensioningDcColumns } from "../AbbDimensioningDc.utils"
import { DcsDimensioningResultEntry } from "../../../../../../../../../features/dimensioning/dimensioning.types"
import { DimensioningApi } from "../../../../../../../../../features/dimensioning/dimensioning.api"
import { CatalogApi } from "../../../../../../../../../features/catalog/catalog.api"
import { Characteristics } from "../../../../../../../../../features/catalog/catalog.constants"
import DataGrid from "../../../../../../../../../components/dataGrid/DataGrid"
import { AbbDimensioningDataGridProps } from "../AbbDimensioningDc"

export function AbbDimensioningDctDataGrid({ configurationId, parameterId, renderProductNameCell, ...dataGridProps }: Readonly<AbbDimensioningDataGridProps>) {
    const dimensioningDctQuery = DimensioningApi.useDimensioningDctQuery({ configurationId, parameterId })
    const characteristics = [
        CatalogApi.useCharacteristicQuery(Characteristics.DcModuleFrameSize.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcModuleDimension.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcProductName.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcCurrent.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcMargin.id).data
    ]

    const highlighted = getIdsOfBestDcDimensioningResults(dimensioningDctQuery?.data?.entries)
    const enabled = getIdsOfEnabledDcDimensioningResults(dimensioningDctQuery?.data?.entries)

    const getRowClassName = (params: GridRowClassNameParams<DcsDimensioningResultEntry>) => (highlighted.includes(params.row.id) ? "highlighted" : "")

    return (
        <DataGrid
            {...dataGridProps}
            columns={mapDimensioningDcColumns(AbbDimensioningDctColumns, characteristics, renderProductNameCell)}
            rows={dimensioningDctQuery.data?.entries?.filter(r => enabled.includes(r.id)) ?? []}
            getRowClassName={getRowClassName}
            loading={dimensioningDctQuery.isFetching}
            slotProps={{
                noRowsOverlay: {
                    error: dimensioningDctQuery?.data?.errorMessage,
                    onRetry: dimensioningDctQuery.refetch
                }
            }}
        />
    )
}
