import Icon from "../../../../components/icons/Icon"
import WarningIcon from "@mui/icons-material/Warning"
import { ValidationProblem } from "@encoway/sales-api-js-client"
import { ValidationProblemsList } from "./ValidationProblemsList"

interface ValidationProblemsIconProps {
    validationProblems: ValidationProblem[]
}

export function ValidationProblemsIcon({ validationProblems }: Readonly<ValidationProblemsIconProps>) {
    return <Icon component={WarningIcon} color="warning" tooltipProps={{ title: <ValidationProblemsList validationProblems={validationProblems} /> }} />
}
