import { createApi } from "@reduxjs/toolkit/query/react"
import { ConfigurationStatus, ConfigurationStatusRequest } from "./configuration.types"
import { baseQuery } from "../../store/baseQuery"

export const ConfigurationApi = createApi({
    reducerPath: "configurationApi",
    baseQuery: baseQuery,
    tagTypes: ["configurationStatus"],
    refetchOnMountOrArgChange: true,
    endpoints: builder => ({
        status: builder.query<ConfigurationStatus, ConfigurationStatusRequest>({
            providesTags: ["configurationStatus"],
            query: request => `/api/features/configuration/${request.configurationId}/status`,
            transformResponse: (response: any, meta: any, arg: ConfigurationStatusRequest) => {
                return { ...response, lineItem: arg.lineItem } as ConfigurationStatus
            }
        })
    })
})
