import { Alert, List, ListItem } from "@mui/material"
import { ValidationProblem } from "@encoway/sales-api-js-client"
import { getAlertSeverity } from "./ValidationProblemsList.utils"

interface ValidationProblemsListProps {
    validationProblems: ValidationProblem[]
}

export function ValidationProblemsList({ validationProblems }: Readonly<ValidationProblemsListProps>) {
    return (
        <List>
            {validationProblems.map(validationProblem => (
                <ListItem key={validationProblem.message}>
                    <Alert severity={getAlertSeverity(validationProblem)}>{validationProblem.message}</Alert>
                </ListItem>
            ))}
        </List>
    )
}
