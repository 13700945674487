import { GridRowClassNameParams } from "@mui/x-data-grid-pro"
import { AbbDimensioningDcsColumns } from "./AbbDimensioningDcsColumns"
import { getIdsOfBestDcDimensioningResults, getIdsOfEnabledDcDimensioningResults, mapDimensioningDcColumns } from "../AbbDimensioningDc.utils"
import { DcsDimensioningResultEntry } from "../../../../../../../../../features/dimensioning/dimensioning.types"
import { DimensioningApi } from "../../../../../../../../../features/dimensioning/dimensioning.api"
import { CatalogApi } from "../../../../../../../../../features/catalog/catalog.api"
import { Characteristics } from "../../../../../../../../../features/catalog/catalog.constants"
import DataGrid from "../../../../../../../../../components/dataGrid/DataGrid"
import { AbbDimensioningDataGridProps } from "../AbbDimensioningDc"

export function AbbDimensioningDcsDataGrid({ configurationId, parameterId, renderProductNameCell, ...dataGridProps }: Readonly<AbbDimensioningDataGridProps>) {
    const dimensioningDcsQuery = DimensioningApi.useDimensioningDcsQuery({ configurationId, parameterId })

    const characteristics = [
        CatalogApi.useCharacteristicQuery(Characteristics.DcModuleFrameSize.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcModuleDimension.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcThyristorMaxTemp.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcProductName.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcThyristorTemp.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcCurrent.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcMargin.id).data
    ]

    const highlighted = getIdsOfBestDcDimensioningResults(dimensioningDcsQuery?.data?.entries)
    const enabled = getIdsOfEnabledDcDimensioningResults(dimensioningDcsQuery?.data?.entries)

    const getRowClassName = (params: GridRowClassNameParams<DcsDimensioningResultEntry>) => (highlighted.includes(params.row.id) ? "highlighted" : "")

    return (
        <DataGrid
            {...dataGridProps}
            columns={mapDimensioningDcColumns(AbbDimensioningDcsColumns, characteristics, renderProductNameCell)}
            rows={dimensioningDcsQuery?.data?.entries?.filter(r => enabled.includes(r.id)) ?? []}
            getRowClassName={getRowClassName}
            loading={dimensioningDcsQuery.isFetching}
            slotProps={{ noRowsOverlay: { error: dimensioningDcsQuery?.data?.errorMessage, onRetry: dimensioningDcsQuery.refetch } }}
        />
    )
}
