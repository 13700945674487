import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import DataGrid from "../../../../../components/dataGrid/DataGrid"
import Row from "./components/row/Row"
import { createContext, useCallback, useEffect, useMemo, useState } from "react"
import lineItemsDataGridColumns from "./constants/lineItemsDataGridColumns"
import { DataGridProProps, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-pro"
import LineItemsDataGridStyles from "./LineItemsDataGrid.styles"
import { AbbLineItem, AbbLineItemWithParent } from "../../../../../features/sales/sales.types"
import { ResettableLineItemPricingProperties } from "../../../../../features/sales/sales.constants"
import { SalesApi } from "../../../../../features/sales/sales.api"
import { filterColumn, getColumnVisibilityModel, getFooterRow, getRowClassName, getRows, isCellEditable, mapColumn } from "./LineItemDataGrid.utils"
import { useAppSelector } from "../../../../../store/store"
import { useUserInformation } from "../../../../../features/oidc/hooks/useUserInformation"
import { cloneDeep } from "lodash"
import SalesUtils from "../../../../../features/sales/sales.utils"
import { CustomArticleDialog } from "../../../../../features/sales/components/customArticleDialog/CustomArticleDialog"

export interface BomNode extends AbbLineItemWithParent {
    expanded: boolean
    level: number
    parent?: BomNode
    children?: BomNode[]
}

interface LineItemDataGridProps {
    onConfigure: (lineItem: AbbLineItem, readOnly?: boolean) => void
    onDelete: (lineItem: AbbLineItem) => void
    onGenerateDocument: (lineItem: AbbLineItem) => void
    projectCompositionView: string
}

export const LineItemDataGridContext = createContext<LineItemDataGridProps | undefined>(undefined)

export default function LineItemsDataGrid(props: Readonly<LineItemDataGridProps>) {
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const composition = SalesApi.useCompositionQuery().data
    const [updateLineItem] = SalesApi.useUpdateLineItemMutation()
    const [updateSalesDocument] = SalesApi.useUpdateSalesDocumentMutation()
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({})
    const userInformation = useUserInformation()
    const [customLineItemToEdit, setCustomLineItemToEdit] = useState<AbbLineItem>()

    useEffect(() => {
        setColumnVisibilityModel(getColumnVisibilityModel(props.projectCompositionView))
    }, [props.projectCompositionView])

    const rows = useMemo(() => getRows(cloneDeep(composition)), [composition])

    const columns: GridColDef[] = useMemo(
        () =>
            lineItemsDataGridColumns()
                .filter(column => filterColumn(column, userInformation))
                .map(column => mapColumn(column, readOnly)),
        [userInformation, readOnly]
    )

    const processRowUpdate = (newRow: BomNode, oldRow: BomNode) => {
        const changes = Object.entries(newRow.lineItem.properties).reduce((result, [property, value]) => {
            if (value !== oldRow.lineItem.properties[property]) {
                let adjustedValue = typeof value === "string" ? value.trim() : value
                // NOTE: Workaround for PSU-5531 to transform no user input to the value -999 to be handled in the advanced calculation plugin
                if (ResettableLineItemPricingProperties.includes(property) && (value == null || value === "")) {
                    adjustedValue = -999
                }
                return { ...result, [property]: adjustedValue }
            } else {
                return result
            }
        }, {})
        if (newRow.lineItem.isSalesDocument) {
            updateSalesDocument([{}, changes])
        } else {
            updateLineItem([newRow.lineItem.lineItemId, changes])
        }
        return newRow
    }

    const onCellClick: DataGridProProps<BomNode>["onCellClick"] = useCallback(params => {
        if (
            params.row.lineItem.properties.isCustomLineItem &&
            SalesUtils.lineItems.isEditableLineItemProperty(params.row.lineItem, params.field) &&
            params.colDef.headerName === "Product name"
        ) {
            setCustomLineItemToEdit(params.row.lineItem)
            throw new Error("prevent start editing cell.")
        }
    }, [])

    return (
        <LineItemDataGridContext.Provider value={props}>
            <DataGrid
                onCellClick={onCellClick}
                rows={rows}
                pinnedRows={{ bottom: [getFooterRow(salesDocument, userInformation)] }}
                getRowId={row => row.lineItem.lineItemId}
                getRowClassName={getRowClassName}
                rowHeight={40}
                sx={LineItemsDataGridStyles}
                columns={columns}
                initialState={{ pinnedColumns: { right: ["actions"] } }}
                hideFooter
                disableRowSelectionOnClick
                isCellEditable={isCellEditable}
                processRowUpdate={processRowUpdate}
                slots={{ row: Row }}
                autoHeight={false}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={setColumnVisibilityModel}
                localeText={{
                    noRowsLabel: L10n.format(TranslationKeys.pages.project.composition.noLineItemsFound),
                    noResultsOverlayLabel: L10n.format(TranslationKeys.pages.project.composition.noLineItemResults)
                }}
            />
            {customLineItemToEdit && <CustomArticleDialog lineItem={customLineItemToEdit} onClose={() => setCustomLineItemToEdit(undefined)} />}
        </LineItemDataGridContext.Provider>
    )
}
