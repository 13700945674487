import { SalesApi } from "../sales.api"
import { PrintArgs } from "../sales.api.types"
import DownloadFileService from "../../download/DownloadFileService"

export default function usePrint() {
    const [print] = SalesApi.usePrintMutation()
    return async (args: PrintArgs) => {
        const response = await print(args).unwrap()
        const blob = DownloadFileService.base64ToBlob(response.data, response.mimeType)
        DownloadFileService.downloadFile(blob, response.fileName)
    }
}
