import { useEffect } from "react"
import { DropdownProps } from "@encoway/cui-configurator-components"
import { useAppDispatch } from "../../../../../../../../store/store"
import { SalesSlice } from "../../../../../../../../features/sales/sales.slice"
import { getConfigurationLayout } from "./AbbDefaultLayout.utils"

export function AbbDefaultLayout(props: DropdownProps<any>) {
    const dispatch = useAppDispatch()
    const layout = props.data.selectedValues?.at(0)?.value

    useEffect(() => {
        const configurationLayout = getConfigurationLayout(layout)
        dispatch(SalesSlice.actions.changeConfigurationLayout(configurationLayout))
    }, [dispatch, layout])

    return null
}
