import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { BomNode } from "../../LineItemsDataGrid"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"

export default function UserDefinedCell(params: Readonly<GridRenderCellParams<BomNode>>) {
    const isCellEdited = params.row.lineItem?.properties[`${params.field}UserDefined`] === 1

    return (
        <>
            {isCellEdited && <AccountCircleIcon />}
            {params.value}
        </>
    )
}
