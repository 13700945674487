import { Section, SectionProps } from "@encoway/cui-configurator-components"
import { AbbHorizontalSectionStyles, AbbSectionStyles } from "./AbbSection.styles"
import { BLOCKED_LAYOUT_SECTION, ContentBlockIds } from "../../../../../../../../features/catalog/catalog.constants"
import { ContainerTO } from "@encoway/c-services-js-client"
import { MfkSection } from "./MfkSection/MfkSection"
import { MFK_SECTION_NAME_PREFIX } from "./MfkSection/MfkSection.constants"

const shouldDisplayLabel = (container: ContainerTO) => !BLOCKED_LAYOUT_SECTION.includes(container.name)

export function AbbSection(props: Readonly<SectionProps>) {
    return props.data?.name.startsWith(MFK_SECTION_NAME_PREFIX) ? (
        <MfkSection {...props} />
    ) : (
        <Section
            {...props}
            shouldDisplayLabel={shouldDisplayLabel}
            styles={props.data?.name === ContentBlockIds.HorizontalLayout ? AbbHorizontalSectionStyles : AbbSectionStyles}
            prefix=""
        />
    )
}
