import { MenuItem } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../features/translations/TranslationKeys"
import compareStrings from "../../utils/compareStrings"
import Input, { InputProps } from "../input/Input"
import { useMemo } from "react"
import theme from "../../App.theme"
import { merge } from "lodash"

export interface DropdownMenuItem {
    value: any
    text: string
}

export type DropdownProps = InputProps & {
    value?: string | string[] | number | number[]
    menuItems: DropdownMenuItem[]
    unselect?: boolean
    cypressId?: string
    multiple?: boolean
    disableSorting?: boolean
    disablePortal?: boolean
}

export default function Dropdown({ menuItems, unselect, cypressId, multiple, disableSorting, disablePortal, ...props }: Readonly<DropdownProps>) {
    const options = useMemo(
        () =>
            disableSorting
                ? menuItems
                : menuItems
                      .map(item => ({ value: item.value, text: item.text }))
                      .sort((menuItem1, menuItem2) => compareStrings(menuItem1.text, menuItem2.text, false)),
        [disableSorting, menuItems]
    )

    const selectProps = {
        "data-cy": "Dropdown.Select",
        displayEmpty: true,
        multiple,
        MenuProps: { disablePortal },
        renderValue:
            !props.value || (Array.isArray(props.value) && props.value.length === 0)
                ? () => (
                      <span style={{ color: theme.palette.text.disabled }}>
                          {props.placeholder ?? L10n.format(TranslationKeys.components.dropdown.placeholder)}
                      </span>
                  )
                : undefined
    }

    return (
        <Input select data-cy={`Dropdown.${cypressId}`} {...props} SelectProps={merge(selectProps, props.SelectProps)}>
            {unselect && (
                <MenuItem key={L10n.format(TranslationKeys.components.dropdown.unselectOption)} value="">
                    {L10n.format(TranslationKeys.components.dropdown.unselectOption)}
                </MenuItem>
            )}
            {options.map(item => (
                <MenuItem data-cy={`Dropdown.MenuItem.${item.value}`} key={item.text} value={item.value}>
                    {item.text}
                </MenuItem>
            ))}
        </Input>
    )
}
