import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../translations/TranslationKeys"

export default async function readError(error: any) {
    return (await readSalesError(error)) || (await readCatalogError(error)) || getError(error)
}

async function readSalesError(error: any) {
    try {
        const readError = await error.json()
        console.error("error in sales api request", error)
        return { name: L10n.format(TranslationKeys.components.error.title), message: readError.message, status: error.status }
    } catch (e) {
        return undefined
    }
}

async function readCatalogError(error: any) {
    try {
        const readError = await error.response.json()
        console.error("error in catalog api request", error)
        return { name: L10n.format(TranslationKeys.components.error.title), message: readError.message }
    } catch (e) {
        return undefined
    }
}

function getError(error: any) {
    console.error("an error occurred", error)
    return {
        name: L10n.format(TranslationKeys.components.error.title),
        message: getErrorMessage(error)
    }
}

function getErrorMessage(error: any) {
    if (error.message) {
        return error.message
    } else if (error.status === 403) {
        return L10n.format(TranslationKeys.pages.project.configuration.error.invalidSessionMessage)
    } else {
        return L10n.format(TranslationKeys.pages.project.configuration.error.message)
    }
}
