import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import {
    costFormatter,
    dateFormatter,
    multiplierFormatter,
    numberFormatter,
    percentageFormatter,
    priceFormatter,
    projectOnlyPercentageFormatter,
    projectOnlyPriceFormatter
} from "../../../../../../components/dataGrid/dataGridValueFormatters"
import RemarksCell from "../components/remarksCell/RemarksCell"
import QuoteRelevantCell from "../components/quoteRelevantCell/QuoteRelevantCell"
import { renderProductNameCell } from "../components/productNameCell/ProductNameCell"
import ArticleIdCell from "../components/./articleIdCell/ArticleIdCell"
import { ActionsCell } from "../components/actionsCell/ActionsCell"
import { DragAndDropButtonCell } from "../components/dragAndDropButtonCell/DragAndDropButtonCell"
import renderEmptyHeader from "../../../../../../components/dataGrid/./renderer/renderEmptyHeader"
import renderHeaderWithCurrency from "../utils/renderHeaderWithCurrency"
import LineItemTypeCell from "../components/lineItemTypeCell/LineItemTypeCell"
import { LineItemProperties, SalesDocumentProperties } from "../../../../../../features/sales/sales.constants"
import { GridColDef } from "@mui/x-data-grid-pro"
import { L10n } from "@encoway/l10n"
import PlusCodeCell from "../components/plusCodeCell/PlusCodeCell"
import { getPlusCodeCellValue } from "../utils/getPlusCodeCellValue"
import UserDefinedCell from "../components/userDefinedCell/UserDefinedCell"

const determineWidth = (value: string) => {
    return value ? 60 + value.length * 3 : undefined
}

const lineItemsDataGridColumns: () => GridColDef[] = () => [
    {
        field: "dragAndDrop",
        headerName: TranslationKeys.components.dataGrid.columns.dragAndDrop,
        width: 50,
        editable: false,
        renderCell: DragAndDropButtonCell,
        renderHeader: renderEmptyHeader,
        sortable: false,
        filterable: false
    },
    {
        field: LineItemProperties.QUOTE_RELEVANT,
        headerName: TranslationKeys.lineItem.properties.quoteRelevant,
        renderCell: QuoteRelevantCell,
        type: "boolean",
        editable: false,
        sortable: false,
        filterable: false
    },
    {
        field: LineItemProperties.POSITION_NUMBER,
        headerName: TranslationKeys.lineItem.properties.positionNumber,
        width: 80
    },
    {
        field: LineItemProperties.POSITION_QUANTITY,
        headerName: TranslationKeys.lineItem.properties.positionQuantity,
        valueFormatter: numberFormatter,
        type: "number",
        width: 75,
        preProcessEditCellProps: params => ({
            ...params.props,
            error:
                typeof params.props.value !== "number" || params.props.value < 0 || params.props.value % 1 !== 0
                    ? L10n.format(TranslationKeys.pages.project.composition.invalidQuantityMessage)
                    : undefined
        })
    },
    {
        field: "remarks",
        headerName: TranslationKeys.pages.project.composition.remarks,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: RemarksCell
    },
    {
        field: "articleType",
        headerName: TranslationKeys.lineItem.types.label,
        width: 20,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: LineItemTypeCell,
        renderHeader: renderEmptyHeader
    },
    {
        field: LineItemProperties.PRODUCT_NAME,
        headerName: TranslationKeys.lineItem.properties.productName,
        width: 200,
        renderCell: renderProductNameCell
    },
    {
        field: LineItemProperties.PLUS_CODE,
        headerName: TranslationKeys.lineItem.properties.plusCode,
        width: 200,
        valueGetter: getPlusCodeCellValue,
        renderCell: PlusCodeCell
    },
    {
        field: LineItemProperties.ARTICLE_ID,
        headerName: TranslationKeys.lineItem.properties.articleId,
        width: 215,
        renderCell: ArticleIdCell
    },
    {
        field: "option",
        headerName: TranslationKeys.lineItem.properties.option,
        width: 80,
        editable: false
    },
    {
        field: LineItemProperties.PLAIN_LIST_PRICE,
        headerName: TranslationKeys.lineItem.properties.plainListPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.PRICES_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.plainListPrice) + "[CUR]")
    },
    {
        field: LineItemProperties.PLAIN_LIST_PRICE_WITH_CURRENCY,
        headerName: TranslationKeys.lineItem.properties.plainListPriceWithCurrency,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.plainListPriceWithCurrency) + "[CUR]")
    },
    {
        field: LineItemProperties.MULTIPLIER,
        headerName: TranslationKeys.lineItem.properties.multiplier,
        valueFormatter: multiplierFormatter,
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.multiplier))
    },
    {
        field: LineItemProperties.PRICEGROUP,
        headerName: TranslationKeys.lineItem.properties.priceGroup,
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.priceGroup))
    },
    {
        field: LineItemProperties.PLAIN_COST,
        headerName: TranslationKeys.lineItem.properties.plainCost,
        valueFormatter: costFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.PRICES_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.plainCost) + "[CUR]")
    },
    {
        field: LineItemProperties.PLAIN_COST_WITH_CURRENCY,
        headerName: TranslationKeys.lineItem.properties.plainCostWithCurrency,
        valueFormatter: costFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.plainCostWithCurrency) + "[CUR]")
    },
    {
        field: LineItemProperties.FULL_COST,
        headerName: TranslationKeys.lineItem.properties.fullCost,
        valueFormatter: costFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.fullCost) + "[CUR]")
    },
    {
        field: LineItemProperties.TOTAL_COST,
        headerName: TranslationKeys.lineItem.properties.totalCost,
        valueFormatter: costFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.totalCost) + "[CUR]")
    },
    {
        field: LineItemProperties.GROSS_MARGIN,
        headerName: TranslationKeys.lineItem.properties.grossMargin,
        valueFormatter: percentageFormatter,
        type: "number",
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.grossMargin))
    },
    {
        field: LineItemProperties.NET_MARGIN,
        headerName: TranslationKeys.lineItem.properties.netMargin,
        valueFormatter: percentageFormatter,
        type: "number",
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.netMargin))
    },
    {
        field: LineItemProperties.CALCULATED_TRANSFER_PRICE,
        headerName: TranslationKeys.lineItem.properties.plainTransferPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.plainTransferPrice) + "[CUR]")
    },
    {
        field: LineItemProperties.TOTAL_LIST_PRICE,
        headerName: TranslationKeys.lineItem.properties.totalListPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.totalListPrice) + "[CUR]")
    },
    {
        field: LineItemProperties.CALCULATED_TOTAL_TRANSFER_PRICE,
        headerName: TranslationKeys.lineItem.properties.totalTransferPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.totalTransferPrice) + "[CUR]")
    },
    {
        field: LineItemProperties.TRANSFER_PRICE_DISCOUNT_PERCENTAGE,
        headerName: TranslationKeys.lineItem.properties.transferPriceDiscountPercentage,
        valueFormatter: percentageFormatter,
        renderCell: UserDefinedCell,
        type: "number",
        valueGetter: (_value: never, row, column) => {
            if (row.lineItem.properties[column.field] === -999) {
                // NOTE: Workaround for PSU-5531
                return ""
            } else {
                return row.lineItem.properties[column.field]
            }
        },
        preProcessEditCellProps: params => ({
            ...params.props,
            error:
                (typeof params.props.value !== "number" && params.props.value) || params.props.value < 0 || params.props.value > 100
                    ? L10n.format(TranslationKeys.pages.project.composition.invalidNumberMessage)
                    : undefined
        }),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.transferPriceDiscountPercentage))
    },
    {
        field: LineItemProperties.TRANSFER_PRICE_DISCOUNTED_UNIT_PRICE,
        headerName: TranslationKeys.lineItem.properties.transferPriceDiscountedUnitPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.transferPriceDiscountedUnitPrice) + "[CUR]")
    },
    {
        field: LineItemProperties.TRANSFER_PRICE_DISCOUNT_ACCUMULATED_PERCENTAGE,
        headerName: TranslationKeys.lineItem.properties.transferPriceDiscountAccumulatedPercentage,
        valueFormatter: percentageFormatter,
        type: "number",
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.transferPriceDiscountAccumulatedPercentage))
    },
    {
        field: LineItemProperties.TRANSFER_PRICE_DISCOUNTED_TOTAL_PRICE,
        headerName: TranslationKeys.lineItem.properties.transferPriceDiscountedTotalPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.transferPriceDiscountedTotalPrice) + "[CUR]")
    },
    {
        field: LineItemProperties.SALES_PRICE_MULTIPLIER,
        headerName: TranslationKeys.lineItem.properties.salesPriceMultiplier,
        valueFormatter: multiplierFormatter,
        renderCell: UserDefinedCell,
        valueGetter: (_value: never, row, column) => {
            if (row.lineItem.properties[column.field] === -999) {
                // NOTE: Workaround for PSU-5531
                return ""
            } else {
                return row.lineItem.properties[column.field]
            }
        },
        preProcessEditCellProps: params => ({
            ...params.props,
            error: isNaN(params.props.value) ? L10n.format(TranslationKeys.pages.project.composition.invalidNumberMessage) : undefined
        }),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.salesPriceMultiplier))
    },
    {
        field: LineItemProperties.SALES_PRICE_ACCUMULATED_MULTIPLIER,
        headerName: TranslationKeys.lineItem.properties.salesPriceAccumulatedMultiplier,
        valueFormatter: multiplierFormatter,
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.salesPriceAccumulatedMultiplier))
    },
    {
        field: LineItemProperties.SALES_PRICE_WITH_CURRENCY,
        headerName: TranslationKeys.lineItem.properties.salesPriceWithCurrency,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.SALES_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.salesPriceWithCurrency) + "[CUR]")
    },
    {
        field: LineItemProperties.TOTAL_SALES_PRICE,
        headerName: TranslationKeys.lineItem.properties.totalSalesPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.SALES_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.totalSalesPrice) + "[CUR]")
    },
    {
        field: LineItemProperties.SALES_PRICE_DISCOUNT_PERCENTAGE,
        headerName: TranslationKeys.lineItem.properties.salesPriceDiscountPercentage,
        valueFormatter: percentageFormatter,
        renderCell: UserDefinedCell,
        type: "number",
        valueGetter: (_value: never, row, column) => {
            if (row.lineItem.properties[column.field] === -999) {
                // NOTE: Workaround for PSU-5531
                return ""
            } else {
                return row.lineItem.properties[column.field]
            }
        },
        preProcessEditCellProps: params => ({
            ...params.props,
            error:
                (typeof params.props.value !== "number" && params.props.value) || params.props.value < 0 || params.props.value > 100
                    ? L10n.format(TranslationKeys.pages.project.composition.invalidNumberMessage)
                    : undefined
        }),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.salesPriceDiscountPercentage))
    },
    {
        field: LineItemProperties.SALES_PRICE_ACCUMULATED_DISCOUNT_PERCENTAGE,
        headerName: TranslationKeys.lineItem.properties.salesPriceAccumulatedDiscountPercentage,
        valueFormatter: percentageFormatter,
        type: "number",
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.salesPriceAccumulatedDiscountPercentage))
    },
    {
        field: LineItemProperties.DISCOUNTED_SALES_PRICE,
        headerName: TranslationKeys.lineItem.properties.discountedSalesPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.SALES_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.discountedSalesPrice) + "[CUR]")
    },
    {
        field: LineItemProperties.DISCOUNTED_TOTAL_SALES_PRICE,
        headerName: TranslationKeys.lineItem.properties.discountedTotalSalesPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.SALES_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.discountedTotalSalesPrice) + "[CUR]")
    },
    {
        field: LineItemProperties.LOCAL_PRODUCTION_COST,
        headerName: TranslationKeys.lineItem.properties.localProductionCost,
        valueFormatter: projectOnlyPriceFormatter,
        renderCell: UserDefinedCell,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.localProductionCost) + "[CUR]")
    },
    {
        field: LineItemProperties.TOTAL_LOCAL_PRODUCTION_COST,
        headerName: TranslationKeys.lineItem.properties.totalLocalProductionCost,
        valueFormatter: projectOnlyPriceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.totalLocalProductionCost) + "[CUR]")
    },
    {
        field: LineItemProperties.LOCAL_GROSS_MARGIN,
        headerName: TranslationKeys.lineItem.properties.localGrossMargin,
        valueFormatter: projectOnlyPercentageFormatter,
        type: "number",
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.localGrossMargin))
    },
    {
        field: LineItemProperties.LOCAL_GROSS_PROFIT,
        headerName: TranslationKeys.lineItem.properties.localGrossProfit,
        valueFormatter: projectOnlyPriceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.TRANSFER_PRICE_CURRENCY),
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.localGrossProfit) + "[CUR]")
    },
    {
        field: LineItemProperties.ABB_LINE_ITEM_ID,
        headerName: TranslationKeys.lineItem.properties.id,
        width: 120
    },
    {
        field: LineItemProperties.CONFIGURATION_NAME,
        headerName: TranslationKeys.lineItem.properties.configurationName,
        width: 200
    },
    {
        field: LineItemProperties.CREATED_BY,
        headerName: TranslationKeys.lineItem.properties.createdBy,
        width: 150
    },
    {
        field: LineItemProperties.CREATED_AT,
        headerName: TranslationKeys.lineItem.properties.createdAt,
        type: "date",
        valueFormatter: dateFormatter
    },
    {
        field: LineItemProperties.MODIFIED_AT,
        headerName: TranslationKeys.lineItem.properties.modifiedAt,
        type: "date",
        width: 150,
        valueFormatter: dateFormatter
    },
    {
        field: LineItemProperties.MODIFIED_BY,
        headerName: TranslationKeys.lineItem.properties.modifiedBy,
        width: determineWidth(L10n.format(TranslationKeys.lineItem.properties.modifiedBy))
    },
    {
        field: "actions",
        headerName: TranslationKeys.components.dataGrid.columns.actions,
        type: "actions",
        width: 10,
        hideable: false,
        editable: false,
        renderHeader: renderEmptyHeader,
        getActions: ActionsCell
    }
]

export default lineItemsDataGridColumns
