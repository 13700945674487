import { useEffect } from "react"
import { L10n } from "@encoway/l10n"
import { SalesDocumentFilter } from "@encoway/sales-api-js-client"
import { generatePath } from "react-router-dom"
import useDialog from "../../../../components/dialog/useDialog"
import { SalesApi } from "../../sales.api"
import { SalesDocumentProperties } from "../../sales.constants"
import useNavigate from "../../../../router/hooks/useNavigate"
import RouterPaths from "../../../../router/constants/RouterPaths"
import { ButtonPropsWithLabel } from "../../../../components/dialog/dialogActions/DialogActions"
import TranslationKeys from "../../../translations/TranslationKeys"
import DialogStyles from "../../../../components/dialog/Dialog.styles"
import Dialog from "../../../../components/dialog/Dialog"
import { CatalogApi } from "../../../catalog/catalog.api"
import { Characteristics, ContentBlockIds } from "../../../catalog/catalog.constants"
import { SalesDocumentPropertyInput } from "../salesDocumentPropertyInput/SalesDocumentPropertyInput"
import { useSalesDocumentPropertiesForm } from "../../hooks/useSalesDocumentPropertiesForm"
import { Stack } from "@mui/material"
import { ErrorAlert } from "../../../error/components/errorAlert/ErrorAlert"
import { BusySpinner } from "../../../busy/components/busySpinner/BusySpinner"
import { useSalesDocumentPropertyCharacteristics } from "../../hooks/useSalesDocumentPropertyCharacteristics"

export default function NewSalesDocumentDialog(props: Readonly<ReturnType<typeof useDialog>>) {
    const [saveSalesDocument] = SalesApi.useSaveSalesDocumentMutation()
    const [createSalesDocument, { isSuccess: salesDocumentCreated }] = SalesApi.useCreateSalesDocumentMutation()
    const salesDocument = SalesApi.useSalesDocumentQuery(undefined, { skip: !salesDocumentCreated }).data
    const projectsWithSalesForceOpportunityNumberExistDialog = useDialog()
    const navigate = useNavigate()

    const createProjectDialogQuery = CatalogApi.useProductQuery(ContentBlockIds.CreateProjectDialog)
    const characteristics = useSalesDocumentPropertyCharacteristics(createProjectDialogQuery.data?.product, Characteristics.CreateProjectDialogOrder.id)
    const form = useSalesDocumentPropertiesForm(characteristics)

    const salesDocuments = SalesApi.useSalesDocumentsQuery(
        {
            filterConfig: new SalesDocumentFilter().add(
                SalesDocumentProperties.SALESFORCE_OPPORTUNITY_NUMBER,
                "=",
                salesDocument?.properties.salesforce_opportunity_number ?? "",
                "string"
            )
        },
        { skip: !salesDocument?.properties.salesforce_opportunity_number || !props.isOpen }
    )

    useEffect(() => {
        if (props.isOpen) {
            createSalesDocument()
        }
    }, [props.isOpen, createSalesDocument])

    const saveNewSalesDocument = () => {
        saveSalesDocument()
            .unwrap()
            .then(savedSalesDocument => {
                props.close()
                navigate.toProjectDetails(savedSalesDocument.salesDocumentId)
            })
    }

    const submit = () => {
        if (
            salesDocument?.properties.salesforce_opportunity_number &&
            !!salesDocuments.data?.data.find(sd => sd.properties.salesforce_opportunity_number === salesDocument.properties.salesforce_opportunity_number)
        ) {
            projectsWithSalesForceOpportunityNumberExistDialog.open()
            props.close()
        } else {
            saveNewSalesDocument()
        }
    }

    const handleExistingProject = () => {
        projectsWithSalesForceOpportunityNumberExistDialog.close()
        props.close()
        navigate.withSearchParams(generatePath(RouterPaths.PROJECTS), {
            [SalesDocumentProperties.SALESFORCE_OPPORTUNITY_NUMBER]: salesDocument!.properties.salesforce_opportunity_number!
        })
    }

    const existsOpportunityIdButton: ButtonPropsWithLabel = {
        label: TranslationKeys.project.newProject.exists.showExistingProjects,
        variant: "contained",
        onClick: handleExistingProject,
        sx: DialogStyles.blackButton
    }
    const createNewProjectButton: ButtonPropsWithLabel = {
        label: TranslationKeys.project.newProject.exists.createNewProject,
        variant: "contained",
        onClick: saveNewSalesDocument,
        sx: DialogStyles.blackButton
    }

    return (
        <>
            <Dialog
                withDividers
                title={L10n.format(TranslationKeys.project.newProject.dialog.title)}
                open={props.isOpen}
                onClose={props.close}
                sx={{ ".MuiDialog-paper": { width: 400 } }}
                defaultDialogActions={{
                    onConfirm: submit,
                    disableConfirm: !form.isValid,
                    onCancel: props.close
                }}
            >
                <Stack component="form" spacing={2}>
                    {createProjectDialogQuery.isError && (
                        <ErrorAlert error={createProjectDialogQuery.error.message} onRetry={createProjectDialogQuery.refetch} />
                    )}
                    {createProjectDialogQuery.isFetching && <BusySpinner />}
                    {createProjectDialogQuery.isSuccess &&
                        characteristics.map(characteristic => (
                            <SalesDocumentPropertyInput
                                key={characteristic.id}
                                characteristic={characteristic}
                                requiredCharacteristicId={Characteristics.CreateProjectDialogValueRequired.id}
                            />
                        ))}
                </Stack>
            </Dialog>
            <Dialog
                open={projectsWithSalesForceOpportunityNumberExistDialog.isOpen}
                onClose={projectsWithSalesForceOpportunityNumberExistDialog.close}
                title={L10n.format(TranslationKeys.project.newProject.exists.title)}
                sx={{ ".MuiDialog-paper": { width: 400 } }}
                message={L10n.format(TranslationKeys.project.newProject.exists.message)}
                customDialogActions={{
                    actions: [existsOpportunityIdButton, createNewProjectButton]
                }}
            />
        </>
    )
}
