import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../store/store"
import { SalesApi } from "../../../../../features/sales/sales.api"
import useConfigurationService from "./useConfigurationService"
import { BusySlice } from "../../../../../features/busy/busy.slice"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { AR_QR_CODE } from "../../../../../features/visualization/visualization.constants"
import { useLazyArQuery } from "../../../../../features/visualization/hooks/useLazyArQuery"
import { useLazyRenderingsQuery } from "../../../../../features/visualization/hooks/useLazyRenderingsQuery"
import { useProjectConfigurationLineItem } from "./useProjectConfigurationLineItem"

export function useSaveConfiguration() {
    const visualization = useAppSelector(state => state.visualization.visualization)
    const [saveConfiguration] = SalesApi.useSaveConfigurationMutation()

    const lineItem = useProjectConfigurationLineItem()

    const lineItemId =
        lineItem?.properties.FOLDER_TYPE === "LINEUP" && lineItem?.properties.LINEUP_ARTICLE_LINE_ITEM_ID
            ? lineItem.properties.LINEUP_ARTICLE_LINE_ITEM_ID
            : lineItem?.lineItemId

    const configurationService = useConfigurationService()
    const arQuery = useLazyArQuery()
    const renderingsQuery = useLazyRenderingsQuery()
    const dispatch = useAppDispatch()

    const getConfigurationProperties = useCallback(async () => {
        const promiseResult = await Promise.allSettled([lineItemId ? arQuery.fetch(lineItemId) : undefined, renderingsQuery.fetch()])
        const renderingsResult = promiseResult[1].status === "fulfilled" ? promiseResult[1].value.data : undefined
        const arQrCode = promiseResult[0].status === "fulfilled" ? promiseResult[0].value?.data?.qr?.split(",").at(1) : undefined
        return { [AR_QR_CODE]: arQrCode, ...renderingsResult }
    }, [arQuery, lineItemId, renderingsQuery])

    return useCallback(async () => {
        if (visualization) {
            dispatch(BusySlice.actions.setBusy(L10n.format(TranslationKeys.busy.visualization.save)))
            const configurationProperties = await getConfigurationProperties()
            await configurationService!.configurationProperties(configurationProperties)
            dispatch(BusySlice.actions.setIdle(L10n.format(TranslationKeys.busy.visualization.save)))
        }
        await saveConfiguration()
    }, [configurationService, dispatch, getConfigurationProperties, saveConfiguration, visualization])
}
