import { FocusEventHandler, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react"
import { SimpleTreeView, SimpleTreeViewProps } from "@mui/x-tree-view"
import { ProjectConfigurationLineItem } from "../../../../../../features/sales/sales.api.types"
import { TreeItem } from "../treeItem/TreeItem"
import { ProjectConfigurationTreeContext } from "./ProjectConfigurationTree.context"
import { getAllLineItemIdsToCollapse, getAllLineItemIdsToExpand } from "./ProjectConfigurationTree.utils"

interface ProjectConfigurationTreeProps
    extends Omit<SimpleTreeViewProps<false>, "expandedItems" | "selectedItems" | "onSelectedItemsChange" | "onFocusCapture"> {
    lineItems: ProjectConfigurationLineItem[]
    selectedLineItemId?: string
    onSelectedLineItemChange: (lineItemId?: string) => void | Promise<void>
}

export const ProjectConfigurationTree = ({
    lineItems,
    selectedLineItemId,
    onSelectedLineItemChange,
    ...simpleTreeViewProps
}: ProjectConfigurationTreeProps) => {
    const [expandedItems, setExpandedItems] = useState<string[]>([])

    useEffect(() => {
        if (selectedLineItemId) {
            const itemIdsToExpand = getAllLineItemIdsToExpand(selectedLineItemId, lineItems)
            setExpandedItems(state => [...state, ...itemIdsToExpand])
        }
    }, [selectedLineItemId, lineItems])

    const onSelectedItemsChange = useCallback(
        (_event: SyntheticEvent, lineItemId: string | null) => {
            onSelectedLineItemChange(lineItemId ?? undefined)
        },
        [onSelectedLineItemChange]
    )

    const collapseItem = useCallback((item: ProjectConfigurationLineItem) => {
        const lineItemIdsToCollapse = getAllLineItemIdsToCollapse(item)
        setExpandedItems(prevState => prevState.filter(id => !lineItemIdsToCollapse.includes(id)))
    }, [])

    const expandItem = useCallback((item: ProjectConfigurationLineItem) => {
        setExpandedItems(prevState => prevState.concat(item.lineItem.lineItemId))
    }, [])

    const contextValue = useMemo(() => ({ collapseItem, expandItem }), [collapseItem, expandItem])

    const stopPropagationOnFocus: FocusEventHandler<HTMLUListElement> = event => {
        event.stopPropagation()
    }

    return (
        <ProjectConfigurationTreeContext.Provider value={contextValue}>
            <SimpleTreeView
                {...simpleTreeViewProps}
                expandedItems={expandedItems}
                selectedItems={selectedLineItemId}
                onSelectedItemsChange={onSelectedItemsChange}
                onFocusCapture={stopPropagationOnFocus}
            >
                {lineItems.map(item => (
                    <TreeItem key={item.lineItem.lineItemId} item={item} />
                ))}
            </SimpleTreeView>
        </ProjectConfigurationTreeContext.Provider>
    )
}
