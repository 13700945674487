import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ProgressState {
    counter: number
    total: number
    translationKey?: string
}

const initialState: ProgressState = {
    counter: 0,
    total: 0
}

export const ProgressSlice = createSlice({
    name: "progress",
    initialState,
    reducers: {
        start: (state, action: PayloadAction<{ total: number; translationKey?: string }>) => {
            state.total = action.payload.total
            state.translationKey = action.payload.translationKey
        },
        increment: (state, action: PayloadAction<number>) => {
            if (state.counter < state.total) {
                state.counter += action.payload
                if (state.counter >= state.total) {
                    state.counter = 0
                    state.total = 0
                    state.translationKey = undefined
                }
            }
        }
    }
})
