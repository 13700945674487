import {
    DataGridPro,
    DataGridProProps,
    GRID_DEFAULT_LOCALE_TEXT,
    GridActionsColDef,
    GridCellEditStopParams,
    GridCellModes,
    GridCellModesModel
} from "@mui/x-data-grid-pro"
import { DataGridStyles } from "./DataGrid.styles"
import { Cell } from "./components/cell/Cell"
import { NoRowsOverlay } from "./components/noRowsOverlay/NoRowsOverlay"
import { LoadingOverlay } from "./components/loadingOverlay/LoadingOverlay"
import { merge } from "lodash"
import { mergeSx } from "../../utils/mergeSx"
import { GridProSlotsComponent } from "@mui/x-data-grid-pro/models/gridProSlotsComponent"
import type { GridProSlotProps } from "@mui/x-data-grid-pro/models/gridProSlotProps"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material"
import type { GridAutosizeOptions } from "@mui/x-data-grid/hooks/features/columnResize"
import { useCallback, useMemo, useState } from "react"
import { renderCell } from "./renderer/renderCell"
import { renderEditCell } from "./renderer/renderEditCell"
import { BomNode } from "../../pages/projectPage/components/projectComposition/lineItemsDataGrid/LineItemsDataGrid"
import { GridEventListener } from "@mui/x-data-grid/models/events"

const pageSizeOptions = [10, 20, 50]

export default function DataGrid({ onCellEditStop, onCellClick, ...props }: Readonly<DataGridProProps>) {
    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})
    const columns = useMemo(
        () =>
            props.columns.map(column =>
                (column as GridActionsColDef).getActions
                    ? column
                    : {
                          ...column,
                          renderCell: renderCell(column.renderCell),
                          renderEditCell: column.renderEditCell ?? renderEditCell
                      }
            ),
        [props.columns]
    )

    const localeText = useMemo(() => ({ ...GRID_DEFAULT_LOCALE_TEXT, ...props.localeText }), [props.localeText])

    const slots: Partial<GridProSlotsComponent> = useMemo(
        () => ({
            loadingOverlay: LoadingOverlay,
            cell: Cell,
            noRowsOverlay: NoRowsOverlay,
            ...props.slots
        }),
        [props.slots]
    )

    const slotProps: GridProSlotProps = useMemo(() => merge({ row: { "data-cy": "row" } }, props.slotProps), [props.slotProps])

    const sx: SxProps<Theme> = useMemo(() => mergeSx(DataGridStyles, props.sx), [props.sx])

    const autosizeOptions: GridAutosizeOptions = useMemo(
        () => ({
            includeOutliers: true,
            includeHeaders: true,
            expand: false,
            ...props.autosizeOptions
        }),
        [props.autosizeOptions]
    )

    const extendedOnCellEditStop: GridEventListener<"cellEditStop"> = useCallback(
        (params: GridCellEditStopParams<BomNode>, event, details) => {
            onCellEditStop?.(params, event, details)
            // obtained from MUI X data grid recipes: https://mui.com/x/react-data-grid/recipes-editing/
            setCellModesModel(prevModel => {
                return {
                    // Revert the mode of the other cells from other rows
                    ...Object.keys(prevModel).reduce(
                        (acc, id) => ({
                            ...acc,
                            [id]: Object.keys(prevModel[id]).reduce(
                                (acc2, field) => ({
                                    ...acc2,
                                    [field]: { mode: GridCellModes.View }
                                }),
                                {}
                            )
                        }),
                        {}
                    ),
                    [params.id]: {
                        // Revert the mode of other cells in the same row
                        ...Object.keys(prevModel[params.id] || {}).reduce((acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }), {})
                    }
                }
            })
        },
        [onCellEditStop]
    )

    // obtained from MUI X data grid recipes: https://mui.com/x/react-data-grid/recipes-editing/
    const handleCellClick: DataGridProProps["onCellClick"] = useCallback(
        (params, event, details) => {
            try {
                onCellClick?.(params, event, details)
                if (!params.isEditable) {
                    return
                }
                // Ignore portal
                if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
                    return
                }
                setCellModesModel(prevModel => {
                    return {
                        // Revert the mode of the other cells from other rows
                        ...Object.keys(prevModel).reduce(
                            (acc, id) => ({
                                ...acc,
                                [id]: Object.keys(prevModel[id]).reduce(
                                    (acc2, field) => ({
                                        ...acc2,
                                        [field]: { mode: GridCellModes.View }
                                    }),
                                    {}
                                )
                            }),
                            {}
                        ),
                        [params.id]: {
                            // Revert the mode of other cells in the same row
                            ...Object.keys(prevModel[params.id] || {}).reduce((acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }), {}),
                            [params.field]: { mode: GridCellModes.Edit }
                        }
                    }
                })
            } catch (error) {}
        },
        [onCellClick]
    )

    return (
        <DataGridPro
            data-cy="DataGrid"
            autoHeight
            pageSizeOptions={pageSizeOptions}
            {...props}
            onCellClick={handleCellClick}
            onCellEditStop={extendedOnCellEditStop}
            cellModesModel={cellModesModel}
            columns={columns}
            localeText={localeText}
            slots={slots}
            slotProps={slotProps}
            sx={sx}
            autosizeOptions={autosizeOptions}
        />
    )
}
