import { Button, Menu, MenuItem, Stack } from "@mui/material"
import { Lock, MoreVert } from "@mui/icons-material"
import ProjectActionsStyles from "./ProjectActions.styles"
import usePopover from "../../../../../../hooks/usePopover"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import { L10n } from "@encoway/l10n"
import { AbbSalesDocumentEntity } from "../../../../../../features/sales/sales.types"
import Icon from "../../../../../../components/icons/Icon"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import AutoStoriesIcon from "@mui/icons-material/AutoStories"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DeleteIcon from "@mui/icons-material/Delete"
import Dialog from "../../../../../../components/dialog/Dialog"
import useDeleteSalesDocumentHandler from "../../../../../../features/sales/hooks/useDeleteSalesDocumentHandler"
import { SalesApi } from "../../../../../../features/sales/sales.api"

export interface ProjectActionProps {
    project: AbbSalesDocumentEntity
    onOpenProject: (project: AbbSalesDocumentEntity, readOnly: boolean) => void
}

export default function ProjectActions({ project, onOpenProject }: Readonly<ProjectActionProps>) {
    const popover = usePopover()
    const deleteSalesDocumentHandler = useDeleteSalesDocumentHandler()
    const [copySalesDocument] = SalesApi.useCopySalesDocumentMutation()
    return (
        <Stack direction="row" justifyContent="end" alignItems="center" onClick={event => event.stopPropagation()}>
            {project.properties.quote_locked_by ? (
                <Icon
                    component={Lock}
                    tooltipProps={{ title: L10n.format(TranslationKeys.project.lockedDialog.message, { user: project.properties.quote_locked_by }) }}
                    onClick={event => event.stopPropagation()}
                    sx={ProjectActionsStyles.icon}
                />
            ) : null}

            <Button sx={ProjectActionsStyles.button} onClick={event => popover.open(event.currentTarget)}>
                <MoreVert />
            </Button>

            <Menu
                open={popover.isOpen}
                anchorEl={popover.anchorEl}
                onClose={popover.close}
                onClick={popover.close}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                sx={ProjectActionsStyles.menu}
            >
                <MenuItem onClick={() => onOpenProject(project, false)} disabled={!project.permission.includes("UPDATE")}>
                    <ModeEditIcon />
                    {L10n.format(TranslationKeys.project.actions.open)}
                </MenuItem>
                <MenuItem onClick={() => onOpenProject(project, true)}>
                    <AutoStoriesIcon />
                    {L10n.format(TranslationKeys.project.actions.read)}
                </MenuItem>
                <MenuItem onClick={() => copySalesDocument(project.salesDocumentId)} disabled={!project.permission.includes("UPDATE")}>
                    <ContentCopyIcon />
                    {L10n.format(TranslationKeys.project.actions.duplicate)}
                </MenuItem>
                <MenuItem onClick={() => deleteSalesDocumentHandler.setSalesDocumentToDelete(project)} disabled={!project.permission.includes("DELETE")}>
                    <DeleteIcon />
                    {L10n.format(TranslationKeys.project.actions.delete)}
                </MenuItem>
            </Menu>

            <Dialog
                open={!!deleteSalesDocumentHandler.salesDocumentToDelete}
                onClose={deleteSalesDocumentHandler.cancel}
                title={L10n.format(TranslationKeys.project.deleteDialog.title)}
                message={L10n.format(TranslationKeys.project.deleteDialog.message)}
                defaultDialogActions={{
                    onConfirm: deleteSalesDocumentHandler.confirm,
                    onCancel: deleteSalesDocumentHandler.cancel,
                    confirmButtonLabel: TranslationKeys.project.deleteDialog.confirmButtonLabel,
                    cancelButtonLabel: TranslationKeys.project.deleteDialog.cancelButtonLabel
                }}
            />
        </Stack>
    )
}
