import { useEffect, useMemo, useState } from "react"
import { Alert, Stack, Typography } from "@mui/material"
import ChecklistIcon from "@mui/icons-material/Checklist"
import EditNoteIcon from "@mui/icons-material/EditNote"
import ErrorIcon from "@mui/icons-material/Error"
import { L10n } from "@encoway/l10n"
import { NumberValue } from "@encoway/c-services-js-client"
import ProductSelectionStyles from "./ProductSelection.styles"
import { ProductStandardSelection } from "./productStandardSelection/ProductStandardSelection"
import { ProductQuickSelection } from "./productQuickSelection/ProductQuickSelection"
import useParams from "../../../../router/hooks/useParams"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import Breadcrumbs, { Breadcrumb } from "../../../../components/breadcrumbs/Breadcrumbs"
import SearchInput from "../../../../components/input/searchInput/SearchInput"
import Tabs from "../../../../components/tabs/Tabs"
import { useSearchParams } from "react-router-dom"
import CatalogUtils from "../../../../features/catalog/catalog.utils"
import { CatalogApi } from "../../../../features/catalog/catalog.api"
import { Characteristics, ProductGroupIds } from "../../../../features/catalog/catalog.constants"
import { SalesApi } from "../../../../features/sales/sales.api"
import { getBreadcrumbs } from "./ProductSelection.utils"
import { ErrorAlert } from "../../../../features/error/components/errorAlert/ErrorAlert"
import { ProductSelectionSearchParams } from "./ProductSelection.constants"
import { getLineItemLabel } from "../projectConfiguration/components/treeItem/TreeItem.Label.utils"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import { CatalogSlice } from "../../../../features/catalog/catalog.slice"

export default function ProductSelection() {
    const [selectedTab, setSelectedTab] = useState(0)
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchValue = searchParams.get(ProductSelectionSearchParams.SEARCH) ?? undefined
    const lineItem = useAppSelector(state => state.catalog.selectedLineItem)
    const productGroupIds = useMemo(() => params.productGroupIds ?? [ProductGroupIds.Catalog], [params])
    const productGroupsQuery = CatalogApi.useProductGroupsQuery(productGroupIds)
    const productGroups = useMemo(() => productGroupsQuery.currentData ?? [], [productGroupsQuery.currentData])
    const currentProductGroupId = productGroupIds.at(-1)!
    const productGroup = productGroups.find(productGroup => productGroup.id === currentProductGroupId)
    const quickSelectionCharacteristic: number = useMemo(
        () =>
            (productGroup
                ? CatalogUtils.getCharacteristicValue<NumberValue>(productGroup, Characteristics.QuickSelectionEnabled.id)?.value
                : Characteristics.QuickSelectionEnabled.possibleValues.NONE) as number,
        [productGroup]
    )
    const quickSelectionIsAvailable = Boolean(quickSelectionCharacteristic)
    const catalogDefaultView = productGroup ? CatalogUtils.getCharacteristicValue<string>(productGroup, Characteristics.CatalogDefaultView.id) : undefined
    const dispatch = useAppDispatch()
    const { productCategory, salesDocumentId } = SalesApi.endpoints.salesDocument.useQueryState(undefined, {
        selectFromResult: ({ currentData }) => ({
            productCategory: currentData?.properties.PRODUCT_CATEGORY,
            salesDocumentId: currentData?.salesDocumentId
        })
    })

    const tabs = useMemo(
        () => [
            {
                cypressId: "tab.catalog",
                label: L10n.format(TranslationKeys.pages.project.catalog.tabs.catalog),
                icon: <ChecklistIcon />
            },
            {
                cypressId: "tab.quickSelection",
                label: L10n.format(TranslationKeys.pages.project.catalog.tabs.quickSelection),
                disabled: !quickSelectionIsAvailable,
                icon: <EditNoteIcon />
            }
        ],
        [quickSelectionIsAvailable]
    )

    //On unmount clear lineup context
    useEffect(
        () => () => {
            dispatch(CatalogSlice.actions.setSelectedLineItem(null))
        },
        [dispatch]
    )

    useEffect(() => {
        setSelectedTab(quickSelectionIsAvailable && catalogDefaultView !== Characteristics.CatalogDefaultView.possibleValues.CATALOG ? 1 : 0)
    }, [catalogDefaultView, quickSelectionIsAvailable])

    const breadcrumbs: Breadcrumb[] = useMemo(() => {
        return getBreadcrumbs(productGroups, !!searchValue, salesDocumentId!)
    }, [productGroups, salesDocumentId, searchValue])

    const currentProductGroupIsCatalog = currentProductGroupId === ProductGroupIds.Catalog

    const lineItemLabel = useMemo(() => (lineItem ? getLineItemLabel(lineItem) : ""), [lineItem])

    return (
        <Stack spacing={3} flexShrink={0} width="100%">
            {!currentProductGroupIsCatalog && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            {productGroupsQuery.isError && <ErrorAlert error={productGroupsQuery.error.message} onRetry={productGroupsQuery.refetch} />}
            <SearchInput
                onSearch={searchValue => setSearchParams({ [ProductSelectionSearchParams.SEARCH]: searchValue })}
                placeholder={L10n.format(TranslationKeys.pages.project.catalog.searchInputPlaceholder)}
                keepSearchValue={!!searchValue}
            />

            {currentProductGroupIsCatalog && <Typography variant="h3">{L10n.format(TranslationKeys.pages.project.catalog.selectDriveSegmentTitle)}</Typography>}

            {productCategory && currentProductGroupIsCatalog && (
                <Alert severity="info" icon={<ErrorIcon color="warning" fontSize="small" />} sx={ProductSelectionStyles.warning}>
                    {L10n.format(TranslationKeys.pages.project.catalog.productCategoryHint)}
                </Alert>
            )}

            {productGroups.length > 1 || searchValue ? (
                <Tabs
                    tabs={tabs}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabsStyles={ProductSelectionStyles.tabStyles}
                    stackProps={{ flexShrink: 0 }}
                />
            ) : null}
            {lineItem && <Alert severity="info">{L10n.format(TranslationKeys.pages.project.catalog.lineupContextActiveMessage, { lineItemLabel })}</Alert>}
            {selectedTab === 0 || !productGroup ? (
                <ProductStandardSelection productGroupId={currentProductGroupId} productGroupIds={productGroupIds} />
            ) : (
                <ProductQuickSelection productGroup={productGroup} quickSelectionCharacteristic={quickSelectionCharacteristic} />
            )}
        </Stack>
    )
}
