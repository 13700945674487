import { GridRow, GridRowProps } from "@mui/x-data-grid-pro"
import { BomNode } from "../../LineItemsDataGrid"
import { OverlayStyles, RowPreviewStyles, RowStyles } from "./Row.styles"
import { ItemSplitConfirmationDialog } from "../../../../projectConfiguration/components/itemSplitConfirmationDialog/ItemSplitConfirmationDialog"
import { ProjectConfigurationLineItemWithParent } from "../../../../projectConfiguration/components/treeItem/TreeItem"
import { useLineItemDragDrop } from "../../../../../../../features/sales/hooks/useLineItemDragDrop"

export default function Row(props: Readonly<GridRowProps>) {
    const bomNode = props.row as BomNode
    const { ref, dragProps, dropProps, isSplitItemDialogOpen, closeSplitItemDialog, unitMoveAction, moveLineItemsToHoverPosition } =
        useLineItemDragDrop(bomNode)
    const onSplitConfirmation = (splitItems: boolean) => {
        moveLineItemsToHoverPosition(unitMoveAction, splitItems).then(closeSplitItemDialog)
    }

    return (
        <>
            <GridRow {...props} ref={ref} style={RowStyles(dropProps.hoverPosition, dragProps.isDragging)} />
            {dropProps.dropNotAllowed && dropProps.dragItem && <div style={OverlayStyles(props.index, ref.current)} />}
            {dragProps.isDragging && <GridRow {...props} style={RowPreviewStyles(props.index, ref.current, dragProps.difference)} />}
            {isSplitItemDialogOpen && (
                <ItemSplitConfirmationDialog
                    onClose={closeSplitItemDialog}
                    item={(bomNode?.parent || bomNode) as ProjectConfigurationLineItemWithParent}
                    onSplitDecision={onSplitConfirmation}
                />
            )}
        </>
    )
}
