import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import Icon from "../../../../../../../components/./icons/Icon"
import { Computer, LocalAtm, LocalOffer, Warning } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import { Stack } from "@mui/material"
import { BomNode } from "../../LineItemsDataGrid"
import { isFooter } from "../row/rowUtils"
import { AbbLineItem } from "../../../../../../../features/sales/sales.types"
import { LineItemReadyStateIcon } from "../../../../../../../features/sales/components/lineItemReadyStateIcon/LineItemReadyStateIcon"
import { ValidationProblemsIcon } from "../../../../../../../features/sales/components/validationProblems/ValidationProblemsIcon"

const showDiscountWarning = (lineItem: AbbLineItem) => isFooter(lineItem) && lineItem.properties.transferPriceDiscountAccumulatedPercentage! > 20

export default function RemarksCell(params: Readonly<GridRenderCellParams<BomNode>>) {
    const lineItem = params.row.lineItem
    const readyState = lineItem.properties.readystate
    const validationProblems = lineItem.properties.validationProblems
    return (
        <Stack direction="row" alignItems="center">
            {showDiscountWarning(lineItem) && (
                <Icon component={Warning} color="error" tooltipProps={{ title: L10n.format(TranslationKeys.discount.maxDiscountExceeded) }} />
            )}

            {readyState && <LineItemReadyStateIcon readyState={readyState} />}
            {validationProblems && <ValidationProblemsIcon validationProblems={validationProblems} />}

            {lineItem.properties.QUANTITY_CHANGED_MANUALLY === "true" && (
                <Icon component={Warning} color="warning" tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.manualQuantityChangeWarning) }} />
            )}

            {lineItem.properties.COMMON_OPTION_SET_BY_SYSTEM === "true" && (
                <Icon
                    component={Computer}
                    color="secondary"
                    tooltipProps={{ title: L10n.format(TranslationKeys.pages.project.composition.commonOptionSetBySystem) }}
                />
            )}
            {(!lineItem.properties.hasPriceWithChildren || !lineItem.properties.hasWarrantyFactor) && (
                <Icon component={LocalAtm} color="warning" tooltipProps={{ title: L10n.format(TranslationKeys.pages.project.composition.hasNoPrice) }} />
            )}
            {(!lineItem.properties.hasCostWithChildren || !lineItem.properties.hasWarrantyFactor) && (
                <Icon component={LocalOffer} color="warning" tooltipProps={{ title: L10n.format(TranslationKeys.pages.project.composition.hasNoCost) }} />
            )}
        </Stack>
    )
}
