import CompareIcon from "@mui/icons-material/Compare"
import Icon, { IconProps } from "../../../../components/icons/Icon"
import { merge } from "lodash"
import { ConfigurationInterfaceDecisionsDifferencesIconTooltipTitleProps, TooltipTitle } from "./tooltipTitle/TooltipTitle"

type ConfigurationInterfaceDifferencesIconProps = ConfigurationInterfaceDecisionsDifferencesIconTooltipTitleProps & Omit<IconProps, "component">

export function ConfigurationInterfaceDecisionsDifferencesIcon({
    projectOptionsDifferences,
    lineupOptionsDifferences,
    ...props
}: Readonly<ConfigurationInterfaceDifferencesIconProps>) {
    return (
        <Icon
            component={CompareIcon}
            tooltipProps={merge(
                {
                    title: <TooltipTitle projectOptionsDifferences={projectOptionsDifferences} lineupOptionsDifferences={lineupOptionsDifferences} />,
                    slotProps: { tooltip: { sx: { maxWidth: "none" } } }
                },
                props.tooltipProps
            )}
            {...props}
        />
    )
}
