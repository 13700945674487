import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AlertColor } from "@mui/material"

export const AUTO_HIDE_DURATION = 6000 // in milliseconds

export interface Snackbar {
    /**
     * ID of snackbar.
     */
    id: number

    /**
     * Defines the color of the snackbar.
     */
    severity: AlertColor

    /**
     * Message that should be displayed.
     */
    message: string

    /**
     * Duration (ms) the snackbar should be open. If undefined, the snackbar does not hide automatically.
     * @default 6000 if severity is "success", otherwise undefined.
     */
    autoHideDuration?: number
}

export type SnackbarDefinition = Omit<Snackbar, "id">

export interface SnackbarState {
    snackbars: Snackbar[]
}

const initialState: SnackbarState = {
    snackbars: []
}

export const SnackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        /**
         * Opens a new Snackbar.
         * @param state current state of slice.
         * @param action payload object containing severity, message and optionally autoHideDuration.
         */
        open: (state, action: PayloadAction<SnackbarDefinition>) => {
            const id = state.snackbars.length > 0 ? Math.max(...state.snackbars.map(snackbar => snackbar.id)) + 1 : 0
            const autoHideDuration = Math.max(AUTO_HIDE_DURATION, action.payload.message.length * 100)
            state.snackbars = [...state.snackbars, { ...action.payload, id, autoHideDuration }]
        },

        /**
         * Closes snackbar with given id.
         * @param state current state of slice.
         * @param action payload is id of snackbar.
         */
        close: (state, action: PayloadAction<number>) => {
            state.snackbars = state.snackbars.filter(snackbar => snackbar.id !== action.payload)
        },

        /**
         * Closes snackbar by given snackbar definition.
         * @param state current state of slice.
         * @param action payload is message of snackbar.
         */
        closeByDefinition: (state, action: PayloadAction<SnackbarDefinition>) => {
            state.snackbars = state.snackbars.filter(snackbar => {
                return (
                    snackbar.severity !== action.payload.severity ||
                    snackbar.message !== action.payload.message ||
                    snackbar.autoHideDuration !== action.payload.autoHideDuration
                )
            })
        }
    }
})
