import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../translations/TranslationKeys"
import { Alert, Box, Stack } from "@mui/material"
import Dialog, { DefaultDialogActionsProps } from "../../../../../components/dialog/Dialog"
import usePrint from "../../../hooks/usePrint"
import Button from "@mui/material/Button"
import { useOrderGeneration } from "./useOrderGeneration"
import { SalesApi } from "../../../sales.api"
import * as Utils from "./OrderDialog.utils"
import { useCallback, useEffect, useMemo } from "react"
import { SalesDocumentProperties, SalesDocumentStatusValues } from "../../../sales.constants"
import { CatalogApi } from "../../../../catalog/catalog.api"
import Dropdown, { DropdownMenuItem } from "../../../../../components/dropdown/Dropdown"

interface OrderDialogProps {
    onClose: () => void
}

export const OrderDialog = ({ onClose }: Readonly<OrderDialogProps>) => {
    const [updateSalesDocument] = SalesApi.useUpdateSalesDocumentMutation()
    const [executeProcessStep, { data: processExecutionResponse }] = SalesApi.useExecuteProcessStepMutation()
    const { data: validationResult, isSuccess: isValidationSuccess } = SalesApi.useValidateOrderingProcessQuery()
    const { data: salesDocumentPropertyCharacteristics } = SalesApi.useSalesDocumentPropertyCharacteristicsQuery()
    const { productCategory, owner, status, projectName } = SalesApi.useSalesDocumentQuery(undefined, {
        selectFromResult: ({ data }) => ({
            productCategory: data?.properties.PRODUCT_CATEGORY,
            owner: data?.properties.quote_owner,
            status: data?.properties.quote_status,
            projectName: data?.properties.project_name
        })
    })

    const { data: responsiblePersons } = CatalogApi.useCharacteristicQuery("ORDER_RESPONSIBLE_USER_" + productCategory, { skip: !productCategory })

    const { notificationText, documentTemplate, documentTemplateTranslation, documentFormat } = useOrderGeneration()
    const print = usePrint()

    const updateOwner = useCallback(
        (value: string) => {
            updateSalesDocument([{ [SalesDocumentProperties.QUOTE_OWNER]: value }, {}])
        },
        [updateSalesDocument]
    )

    useEffect(() => {
        if (responsiblePersons?.possibleValues && Object.values(responsiblePersons.possibleValues).length === 1) {
            updateOwner(Object.values(responsiblePersons.possibleValues)[0].id)
        }
    }, [updateOwner, responsiblePersons?.possibleValues])

    const menuItems: DropdownMenuItem[] = useMemo(() => {
        return responsiblePersons?.possibleValues
            ? Object.values(responsiblePersons.possibleValues).map(value => ({
                  value: value.id,
                  text: (value.value as string | undefined) ?? value.id
              }))
            : []
    }, [responsiblePersons])

    const ownerCharacteristic = useMemo(() => {
        return salesDocumentPropertyCharacteristics?.find(characteristic => characteristic.id === SalesDocumentProperties.QUOTE_OWNER)
    }, [salesDocumentPropertyCharacteristics])

    const submit = useCallback(() => {
        if (processExecutionResponse) {
            onClose()
        } else if (status) {
            executeProcessStep([status, SalesDocumentStatusValues.Order])
        }
    }, [processExecutionResponse, onClose, status, executeProcessStep])

    const documentTemplateAvailable = !!documentTemplate && !!documentFormat

    const hasIncompleteConfigurations =
        processExecutionResponse?.messageBody || validationResult
            ? Utils.hasIncompleteConfigurations(processExecutionResponse?.messageBody || validationResult!)
            : false

    const hasMissingRequiredProperties =
        processExecutionResponse?.messageBody || validationResult
            ? Utils.hasMissingRequiredProperties(processExecutionResponse?.messageBody || validationResult!)
            : false

    const hasValidationProblems = hasIncompleteConfigurations || hasMissingRequiredProperties

    const responsiblePerson = menuItems.find(item => item.value === owner) ? owner : undefined

    const defaultDialogActions: DefaultDialogActionsProps | undefined = useMemo(() => {
        return hasValidationProblems || !!processExecutionResponse ? undefined : { onConfirm: submit, onCancel: onClose, disableConfirm: !responsiblePerson }
    }, [hasValidationProblems, submit, onClose, processExecutionResponse, responsiblePerson])

    const onPrint = async () => {
        if (documentTemplateAvailable) {
            await print({ documentTemplate, documentFormat })
        }
    }

    return (
        <Dialog
            title={L10n.format(TranslationKeys.project.orderingDialog.title, { projectName })}
            onClose={onClose}
            open={true}
            defaultDialogActions={defaultDialogActions}
        >
            <Stack spacing={2}>
                {hasIncompleteConfigurations ? (
                    <Alert severity="error">{L10n.format(TranslationKeys.project.orderingDialog.incompleteConfigurations)}</Alert>
                ) : (
                    <Alert severity="success">{L10n.format(TranslationKeys.project.orderingDialog.noIncompleteConfigurations)}</Alert>
                )}
                {hasMissingRequiredProperties ? (
                    <Alert severity="error">{L10n.format(TranslationKeys.project.orderingDialog.missingRequiredFields)}</Alert>
                ) : (
                    <Alert severity="success">{L10n.format(TranslationKeys.project.orderingDialog.noMissingRequiredFields)}</Alert>
                )}
                {isValidationSuccess && !hasValidationProblems && !processExecutionResponse && !!ownerCharacteristic && (
                    <Dropdown
                        menuItems={menuItems}
                        value={responsiblePerson ?? ""}
                        label={L10n.format(TranslationKeys.project.orderingDialog.newProjectOwnerDropdownLabel)}
                        required
                        fullWidth
                        onChange={event => updateOwner(event.target.value)}
                    />
                )}
                {!!processExecutionResponse && !hasValidationProblems && (
                    <>
                        {notificationText ? <Box dangerouslySetInnerHTML={{ __html: notificationText }} /> : null}
                        {documentTemplateAvailable && <Button onClick={onPrint}>{documentTemplateTranslation}</Button>}
                    </>
                )}
            </Stack>
        </Dialog>
    )
}
