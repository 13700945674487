import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { SalesApi } from "../../../../../../../../features/sales/sales.api"
import { useDeleteLineItemsHandler } from "../../../../../../../../features/sales/hooks/useDeleteLineItemsHandler"
import { IconButton, Stack } from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DeleteIcon from "@mui/icons-material/Delete"
import { AbbLineItem } from "../../../../../../../../features/sales/sales.types"
import { getParent } from "../../../../ProjectConfiguration.utils"

export function ActionsCell(params: Readonly<GridRenderCellParams<AbbLineItem>>) {
    const [duplicateLineItems] = SalesApi.useDuplicateLineItemsMutation()
    const deleteLineItemsHandler = useDeleteLineItemsHandler()
    const projectConfiguration = SalesApi.useProjectConfigurationQuery().currentData!

    const onDuplicate = () => {
        const parent = getParent(params.row.lineItemId, projectConfiguration)
        duplicateLineItems({
            selectedLineItemIds: [params.row.lineItemId],
            parentLineItemId: parent?.lineItem.lineItemId,
            previousLineItem: "LAST"
        })
    }

    const onDelete = () => {
        deleteLineItemsHandler.askToDeleteLineItems([params.row.lineItemId])
    }

    return (
        <Stack direction="row">
            <IconButton onClick={onDuplicate}>
                <ContentCopyIcon />
            </IconButton>
            <IconButton onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
        </Stack>
    )
}
