import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import getBasename from "../../router/utils/getBasename"
import CatalogUtils from "../catalog/catalog.utils"
import { Characteristics, ContentBlockIds } from "../catalog/catalog.constants"
import { L10n } from "@encoway/l10n"
import { Settings } from "../../settings"
import { BusySlice } from "../busy/busy.slice"

export const TranslationsApi = createApi({
    reducerPath: "translationsApi",
    baseQuery: fakeBaseQuery<Error>(),
    endpoints: builder => ({
        translations: builder.query<object, string>({
            queryFn: async (language, api) => {
                api.dispatch(BusySlice.actions.setBusy())
                try {
                    const headers = new Headers({ "Accept-Language": language })
                    const productUrl = getBasename() + `/api/catalog/products/${ContentBlockIds.Translations}`
                    const productResponse = await fetch(productUrl, { headers })
                    const productData = await productResponse.json()

                    const mediaUrl = CatalogUtils.getMediaUri(productData.product, Characteristics.Translations.id)!
                    const mediaResponse = await fetch(mediaUrl, { headers })
                    const translations = await mediaResponse.json()

                    L10n.currentLocale(language)
                    L10n.source("ui", { [language]: flattenTranslationObject(translations) }, true)
                    await L10n.reloadResources(language)

                    const catalogService = (api.getState() as any).catalog.catalogService
                    if (catalogService) {
                        catalogService.useLanguage(language)
                    }

                    Settings.locale = language

                    return { data: translations }
                } finally {
                    api.dispatch(BusySlice.actions.setIdle())
                }
            }
        })
    })
})

function flattenTranslationObject(translationObject: Object) {
    const result: Record<string, string> = {}
    transformTranslationObject("", translationObject, result)
    return result
}

function transformTranslationObject(objKey: string, translationObject: Object | string, objectTo: Record<string, string>) {
    Object.entries(translationObject).forEach(([key, value]) => {
        if (typeof value === "string") {
            objectTo[objKey + key] = value
        } else if (value !== null && typeof value === "object") {
            transformTranslationObject(objKey + key + ".", value, objectTo)
        }
    })
}
