import { Dialog, LinearProgress, Stack } from "@mui/material"
import { L10n } from "@encoway/l10n"
import { ProgressState } from "../../../../features/progress/progress.slice"
import { BusySpinner } from "../../../../features/busy/components/busySpinner/BusySpinner"

export default function Progress({ counter, total, translationKey }: Readonly<ProgressState>) {
    return (
        <Dialog open>
            <BusySpinner />
            <LinearProgress value={(counter / total) * 100} sx={{ width: "350px" }} variant="determinate" />
            <Stack sx={{ textAlign: "center" }}>{translationKey ? L10n.format(translationKey, { count: counter, total }) : null}</Stack>
        </Dialog>
    )
}
