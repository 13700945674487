import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { BomNode } from "../../LineItemsDataGrid"
import ClipboardCell from "../clipBoardCell/ClipboardCell"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"

function PlusCodeCell({ value }: Readonly<GridRenderCellParams<BomNode>>) {
    return <ClipboardCell text={value} title={L10n.format(TranslationKeys.pages.project.composition.copyPlusCode)} />
}

export default PlusCodeCell
