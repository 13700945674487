import { useCallback, useState } from "react"

export type DialogState = {
    isOpen: boolean
    open: () => void
    close: () => void
}

export default function useDialog(): DialogState {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const open = useCallback(() => setIsOpen(true), [])
    const close = useCallback(() => setIsOpen(false), [])

    return {
        isOpen,
        open: open,
        close: close
    }
}
