import { Stack } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import { LineItemProperties } from "../../../../../../features/sales/sales.constants"
import { ChangeEvent, useState } from "react"
import { SalesApi } from "../../../../../../features/sales/sales.api"
import { ProjectConfigurationLineItemWithParent } from "../treeItem/TreeItem"
import Dialog from "../../../../../../components/dialog/Dialog"
import Input from "../../../../../../components/input/Input"
import SalesUtils from "../../../../../../features/sales/sales.utils"

type RenameDialogProps = {
    onClose: () => void
    item: ProjectConfigurationLineItemWithParent
}

export const RenameDialog = ({ onClose, item }: RenameDialogProps) => {
    const [name, setName] = useState(SalesUtils.lineItems.getSubName(item.lineItem))
    const onChange = (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)
    const [updateLineItem] = SalesApi.useUpdateLineItemMutation()
    const isFolder = SalesUtils.lineItems.isFolder(item.lineItem)

    const onConfirm = () => {
        if (isFolder) {
            updateLineItem([item.lineItem.lineItemId, { [LineItemProperties.LABEL]: name }])
        } else {
            updateLineItem([item.lineItem.lineItemId, { [LineItemProperties.CONFIGURATION_NAME]: name }])
        }
        onClose()
    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
            title={L10n.format(TranslationKeys.lineItem.renameDialog.title)}
            message={L10n.format(TranslationKeys.lineItem.renameDialog.message)}
            defaultDialogActions={{
                onConfirm: onConfirm,
                onCancel: onClose,
                confirmButtonLabel: TranslationKeys.lineItem.renameDialog.confirmButtonLabel,
                cancelButtonLabel: TranslationKeys.lineItem.renameDialog.cancelButtonLabel
            }}
        >
            <Stack spacing={1}>
                <form onSubmit={onConfirm}>
                    <Input label={L10n.format(TranslationKeys.lineItem.renameDialog.nameInput.label)} value={name} onChange={onChange} autoFocus />
                </form>
            </Stack>
        </Dialog>
    )
}
