import React from "react"
import { BurgerMenuData, BurgerMenuHandle } from "@encoway/cui-configurator-components/src/components/BurgerMenu/BurgerMenu.types"
import { ParameterMenu, ParameterMenuItem } from "@encoway/cui-configurator-components/src/components/Section/Section.types"
import { ComponentName, VIEWPORT_PROPERTY_VALUE } from "@encoway/cui-configurator-components/src/constants"
import { ComponentFactory } from "@encoway/react-configurator"
import { ParameterProps } from "@encoway/cui-configurator-components/src/components/Parameter/Parameter.types"
import { ParameterTO } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"
import { Constants as ReactConfiguratorConstants } from "@encoway/react-configurator/dist/esm/common/constants"
import { Constants as RestApiConstants } from "@encoway/c-services-js-client/dist/esm/constants"

const REVERT_DECISION = "RevertDecision"

interface PM extends BurgerMenuData, ParameterMenu {}

interface ExtendedParameterProps extends ParameterProps {
    parameter: ParameterTO
    burgerMenuHandles: React.RefObject<BurgerMenuHandle>[]
}

const OptionalPositionDisplay = (props: Record<string, unknown>) => {
    return ComponentFactory.instanceOf(ComponentName.OptionalPositionDisplay, props)
}

const compare = (a: ParameterMenuItem, b: ParameterMenuItem) => {
    const aOrder = a.order
    const bOrder = b.order
    if (aOrder === bOrder) {
        return 0
    } else if (bOrder === undefined) {
        return -1
    } else if (aOrder === undefined) {
        return 1
    }
    return aOrder < bOrder ? -1 : 1
}

const remove = (items: ParameterMenuItem[], key: string) => {
    const index = items.findIndex((i: ParameterMenuItem) => i.key === key)
    if (index >= 0) {
        items.splice(index, 1)
    }
}

const add = (items: ParameterMenuItem[], item: ParameterMenuItem) => {
    remove(items, item.key)
    items.splice(items.length, 0, item)
    items.sort(compare)
}

const UndoDecisionItem = (parameter: ParameterTO, props: ParameterProps) => {
    return {
        key: "undoDecision",
        iconProps: { iconName: REVERT_DECISION },
        text: L10n.format("Configuration.Undo.label"),
        onClick: () => {
            props.onValueChanged && props.onValueChanged(parameter, ReactConfiguratorConstants.Undo, RestApiConstants.ValueFormat.Unformatted)
        },
        canCheck: false,
        order: 0
    }
}

// copied component from product code (Section.tsx)
export const ParamComponent = (props: ExtendedParameterProps) => {
    const { parameter, burgerMenuHandles, ...paramProps } = props
    const menuRef = React.useRef<BurgerMenuHandle>()
    const parameterMenuItems: ParameterMenuItem[] = []
    const menuData: PM = {
        add: (item: ParameterMenuItem) => add(parameterMenuItems, item),
        remove: (item: ParameterMenuItem) => remove(parameterMenuItems, item.key),
        getItems: () => parameterMenuItems,
        setMenuHandle: (h: BurgerMenuHandle) => {
            menuRef.current = h
        }
    }
    burgerMenuHandles.push(menuRef as React.RefObject<BurgerMenuHandle>)
    parameterMenuItems.splice(0, parameterMenuItems.length)
    if (parameter.undoable && parameter.viewPortProperties?.ALLOW_UNDO === VIEWPORT_PROPERTY_VALUE.True) {
        menuData.add(UndoDecisionItem(parameter, props))
    }

    const factory = () =>
        ComponentFactory.instanceOf(parameter.viewPort || "", {
            ...paramProps,
            key: parameter.id,
            data: parameter,
            parameterMenuData: menuData
        })

    if (parameter.viewPortProperties?.OPTIONAL_POSITIONS === VIEWPORT_PROPERTY_VALUE.True) {
        return (
            <OptionalPositionDisplay
                key={parameter.id}
                factory={factory}
                parameterMenuData={menuData}
                data={parameter}
                onValueChanged={props.onValueChanged}
                mediaLink={props.mediaLink}
                options={props.options}
            />
        )
    }

    return factory()
}
