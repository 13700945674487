import { GridEditInputCell, GridRenderEditCellParams } from "@mui/x-data-grid-pro"
import { Tooltip } from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
export const renderEditCell = (params: GridRenderEditCellParams) => {
    return (
        <Tooltip open={!!params.error} title={params.error ?? ""}>
            <GridEditInputCell
                {...params}
                endAdornment={
                    <ClearIcon
                        transform={"scale(50%)"}
                        cursor={"pointer"}
                        onClick={e => {
                            e.stopPropagation()
                            const { id, field } = params
                            params.api.setEditCellValue({ id, field, value: "" })
                        }}
                    />
                }
            />
        </Tooltip>
    )
}
