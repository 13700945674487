import { SxProps, Theme } from "@mui/material"
import { mergeSx } from "../../../../utils/mergeSx"

export const ContainerSx: SxProps<Theme> = {
    backgroundColor: theme => theme.palette.background.paper,
    overflowX: "auto",
    flexGrow: 1
}

export const TreeContainerSx: SxProps<Theme> = {
    backgroundColor: theme => theme.palette.background.default,
    borderRadius: theme => theme.shape.borderRadius,
    flex: "0 0 auto",
    width: "27rem",
    overflow: "auto"
}

export const HiddenTreeContainerSx = mergeSx(TreeContainerSx, {
    display: "none"
})

export const TreeSx: SxProps<Theme> = {
    margin: 2
}

export const VisualizationContainerSx: SxProps<Theme> = {
    flex: "1 0 auto"
}

export const HiddenVisualizationContainerSx = mergeSx(VisualizationContainerSx, {
    display: "none"
})

export function getConfigurationContainerSx(showConfiguration: boolean, showVisualization: boolean): SxProps<Theme> {
    return {
        borderRadius: theme => theme.shape.borderRadius,
        display: showConfiguration ? undefined : "none",
        flex: showVisualization ? "0 0 auto" : "1 0 auto",
        overflow: "auto",
        width: "360px"
    }
}
