import { Characteristics } from "../../../../../../../../features/catalog/catalog.constants"
import { ConfigurationLayout } from "../../../../../../../../features/sales/sales.slice"

export function getConfigurationLayout(layout?: string): ConfigurationLayout {
    if (!layout) {
        return { hideConfigurationTree: false, hideConfiguration: false, hideVisualization: false }
    } else {
        switch (layout) {
            case Characteristics.CuiDefaultLayout.possibleValues.FocusVisualization:
                return { hideConfigurationTree: true, hideConfiguration: false, hideVisualization: false }
            case Characteristics.CuiDefaultLayout.possibleValues.OnlyConfiguration:
                return { hideConfigurationTree: true, hideConfiguration: false, hideVisualization: true }
            case Characteristics.CuiDefaultLayout.possibleValues.FocusConfiguration:
                return { hideConfigurationTree: false, hideConfiguration: false, hideVisualization: true }
            case Characteristics.CuiDefaultLayout.possibleValues.Reset:
                return { hideConfigurationTree: false, hideConfiguration: false, hideVisualization: false }
            default:
                console.warn("unrecognized default layout [" + layout + "] set at characteristic " + Characteristics.CuiDefaultLayout.id)
                return { hideConfigurationTree: false, hideConfiguration: false, hideVisualization: false }
        }
    }
}
