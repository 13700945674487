import { MouseEventHandler, MutableRefObject, RefObject, TouchEventHandler, useRef } from "react"
import { ResizeBarStyles } from "./ResizeBar.styles"
import { Box } from "@mui/material"
import { calculateNewSizes } from "./ResizeBar.utils"

export type Direction = 1 | -1
export type SizeProperty = "width" | "height"

interface ResizeBarProps {
    axis: "x" | "y"
    direction: Direction
    target: RefObject<HTMLElement>
    dependency?: RefObject<HTMLElement>
}

export const ResizeBar = ({ axis, direction, target, dependency }: ResizeBarProps) => {
    const position: MutableRefObject<number> = useRef(0)
    const sizeProperty: SizeProperty = axis === "x" ? "width" : "height"

    const updateSize = (newPosition: number) => {
        if (target.current) {
            const { newTargetSize, newDependencySize } = calculateNewSizes(
                target.current,
                dependency?.current ?? null,
                position.current,
                newPosition,
                direction,
                sizeProperty
            )
            target.current.style[sizeProperty] = `${newTargetSize}px`
            if (dependency?.current) {
                dependency.current.style[sizeProperty] = `${newDependencySize}px`
            }
        }
        position.current = newPosition
        window.dispatchEvent(new Event("resize"))
    }

    const resizeHandler = (event: MouseEvent) => {
        const newPosition = event[axis]
        updateSize(newPosition)
    }

    const onMouseDownHandler: MouseEventHandler<HTMLDivElement> = event => {
        position.current = event.nativeEvent[axis]
        document.addEventListener("mousemove", resizeHandler, false)
        document.addEventListener("mouseup", () => document.removeEventListener("mousemove", resizeHandler, false), false)
    }

    const touchResizeHandler = (event: TouchEvent) => {
        const newPosition = axis === "x" ? event.touches[0].clientX : event.touches[0].clientY
        updateSize(newPosition)
    }

    const onTouchStartHandler: TouchEventHandler<HTMLDivElement> = event => {
        position.current = axis === "x" ? event.touches[0].clientX : event.touches[0].clientY
        document.addEventListener("touchmove", touchResizeHandler, false)
        document.addEventListener("touchend", () => document.removeEventListener("touchmove", touchResizeHandler, false), false)
    }

    return <Box style={ResizeBarStyles(axis)} onMouseDown={onMouseDownHandler} onTouchStart={onTouchStartHandler} />
}
