import { ValidationProblem } from "@encoway/sales-api-js-client"
import { AlertProps } from "@mui/material"

export function getAlertSeverity(validationProblem: ValidationProblem): AlertProps["severity"] {
    switch (validationProblem.severity) {
        case "HIGH":
            return "error"
        case "MEDIUM":
            return "warning"
        default:
            return "info"
    }
}
