import { SalesApi } from "../../../sales.api"
import { CatalogApi } from "../../../../catalog/catalog.api"
import CatalogUtils from "../../../../catalog/catalog.utils"
import { Characteristics } from "../../../../catalog/catalog.constants"

export const useOrderGeneration = () => {
    const productCategory = SalesApi.useSalesDocumentQuery(undefined, {
        selectFromResult: ({ data }) => ({ productCategory: data?.properties.PRODUCT_CATEGORY })
    }).productCategory

    const orderGenerationContentBlockData = CatalogApi.useProductWithSpinnerQuery(`ORDER_GENERATION_${productCategory}`, {
        skip: !productCategory
    }).data

    const orderGenerationContentBlockCharacteristics = orderGenerationContentBlockData?.characteristics
    const orderGenerationContentBlockProduct = orderGenerationContentBlockData?.product
    const notificationText = orderGenerationContentBlockProduct
        ? CatalogUtils.getCharacteristicValue<string>(orderGenerationContentBlockProduct, Characteristics.OrderGeneration.AdditionalMessage.id)
        : undefined
    const documentTemplate = orderGenerationContentBlockProduct
        ? CatalogUtils.getCharacteristicValue<string>(orderGenerationContentBlockProduct, Characteristics.OrderGeneration.DocumentTemplateId.id)
        : undefined
    const documentFormat = orderGenerationContentBlockProduct
        ? CatalogUtils.getCharacteristicValue<string>(orderGenerationContentBlockProduct, Characteristics.OrderGeneration.DocumentFormat.id)
        : undefined
    const documentTemplateTranslationCharacteristic = orderGenerationContentBlockCharacteristics?.find(
        c => c.id === Characteristics.OrderGeneration.DocumentTemplateId.id
    )
    const documentTemplateTranslation =
        documentTemplate && documentTemplateTranslationCharacteristic
            ? CatalogUtils.getPossibleValueTranslation(documentTemplate, documentTemplateTranslationCharacteristic)
            : undefined
    return {
        notificationText,
        documentTemplate,
        documentFormat,
        documentTemplateTranslation
    }
}
