import { Direction, SizeProperty } from "./ResizeBar"

export function calculateNewSizes(
    target: HTMLElement,
    dependency: HTMLElement | null,
    oldPosition: number,
    newPosition: number,
    direction: Direction,
    sizeProperty: SizeProperty
) {
    const difference = oldPosition - newPosition
    if (dependency) {
        const currentTargetSize = getSize(target, sizeProperty)
        const currentDependencySize = getSize(dependency, sizeProperty)
        const possibleDifference = getPossibleDifference(currentTargetSize, currentDependencySize, difference, direction)
        const newTargetSize = calculationSize(currentTargetSize, possibleDifference, direction, false)
        const newDependencySize = calculationSize(currentDependencySize, possibleDifference, direction, true)
        if (newTargetSize === currentTargetSize || newDependencySize === currentDependencySize) {
            return { newTargetSize: currentTargetSize, newDependencySize: currentDependencySize }
        } else {
            return { newTargetSize, newDependencySize }
        }
    } else {
        const currentSize = getSize(target, sizeProperty)
        const newTargetSize = Math.max(0, calculationSize(currentSize, difference, direction, false))
        return { newTargetSize, newDependencySize: undefined }
    }
}

function getPossibleDifference(targetSize: number, dependencySize: number, difference: number, direction: Direction) {
    const newTargetSize = calculationSize(targetSize, difference, direction, false)
    const newDependencySize = calculationSize(dependencySize, difference, direction, true)
    if (newTargetSize >= 0 && newDependencySize >= 0) {
        return difference
    } else if (newTargetSize < 0) {
        return difference > 0 ? targetSize : -targetSize
    } else {
        return difference > 0 ? dependencySize : -dependencySize
    }
}

function calculationSize(currentSize: number, difference: number, direction: Direction, isDependency: boolean) {
    return isDependency ? currentSize - difference * direction : currentSize + difference * direction
}

function getSize(element: HTMLElement, sizeProperty: SizeProperty) {
    return parseInt(getComputedStyle(element, "")[sizeProperty])
}
