import DashboardWidget from "../dashboardWidget/DashboardWidget"
import { Button, Card, Grid, Stack, Typography } from "@mui/material"
import ProjectCard from "./projectCard/ProjectCard"
import AddIcon from "@mui/icons-material/Add"
import { L10n } from "@encoway/l10n"
import RecentProjectsWidgetStyles from "./RecentProjectsWidget.styles"
import useDialog from "../../../../components/dialog/useDialog"
import useNavigate from "../../../../router/hooks/useNavigate"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import NewSalesDocumentDialog from "../../../../features/sales/components/newSalesDocumentDialog/NewSalesDocumentDialog"
import { useUserInformation } from "../../../../features/oidc/hooks/useUserInformation"
import { SalesApi } from "../../../../features/sales/sales.api"
import { Paging, SalesDocumentFilter } from "@encoway/sales-api-js-client"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"
import { OidcUtils } from "../../../../features/oidc/oidc.utils"
import Box from "@mui/material/Box"

export default function RecentProjectsWidget() {
    const dialog = useDialog()
    const navigate = useNavigate()
    const userInformation = useUserInformation()
    const filterConfig = userInformation?.username
        ? new SalesDocumentFilter().add(SalesDocumentProperties.QUOTE_CREATED_BY, "=", userInformation.username, "string")
        : undefined
    const pagingLoadConfig = new Paging().limit(4).sortField(SalesDocumentProperties.MODIFIED_AT).descending()
    const salesDocumentsQuery = SalesApi.useSalesDocumentsQuery({ filterConfig, pagingLoadConfig })

    return (
        <DashboardWidget title={L10n.format(TranslationKeys.pages.dashboard.recentProjectsWidget.title)}>
            <Stack spacing={4}>
                <Box>
                    <Grid container spacing={4}>
                        {salesDocumentsQuery.currentData?.data.map(salesDocument => (
                            <Grid item key={salesDocument.salesDocumentId}>
                                <ProjectCard key={salesDocument.salesDocumentId} project={salesDocument} sx={RecentProjectsWidgetStyles.card} />
                            </Grid>
                        ))}
                        <Grid item>
                            <Card sx={RecentProjectsWidgetStyles.viewAllCard} onClick={() => navigate.toProjectsOverview()}>
                                <Typography data-cy={"Typography.ViewAllProjects"}>
                                    {L10n.format(TranslationKeys.pages.dashboard.recentProjectsWidget.viewAllProjects)}
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <Stack direction="row">
                    <Button
                        variant="contained"
                        startIcon={<AddIcon sx={RecentProjectsWidgetStyles.buttonIcon} />}
                        onClick={dialog.open}
                        data-cy={"newProjectButton"}
                        sx={RecentProjectsWidgetStyles.button}
                        disabled={!userInformation || !OidcUtils.hasEditProjectsRole(userInformation)}
                    >
                        {L10n.format(TranslationKeys.project.newProject.button.label)}
                    </Button>
                </Stack>
            </Stack>
            {dialog.isOpen && <NewSalesDocumentDialog {...dialog} />}
        </DashboardWidget>
    )
}
