import { useEffect, useMemo, useState } from "react"
import { ProjectConfigurationContainerIds } from "../../../../../ProjectConfiguration.constants"
import { getToolbar } from "../AbbVisualization.utils"
import { useArDialog } from "./useArDialog"

export function useVisualizationToolbar() {
    const [toolbar, setToolbar] = useState<HTMLDivElement>()
    const arDialog = useArDialog()

    useEffect(() => {
        const visualizationContainer = document.getElementById(ProjectConfigurationContainerIds.VISUALIZATION) as HTMLDivElement
        const mutationObserver = new MutationObserver(() => {
            const toolbarElement = getToolbar(visualizationContainer)
            if (toolbarElement) {
                setToolbar(toolbarElement)
                mutationObserver.disconnect()
            }
        })
        mutationObserver.observe(visualizationContainer, { subtree: true, childList: true })
        return () => mutationObserver.disconnect()
    }, [arDialog.open])

    return useMemo(() => ({ toolbar, arDialog }), [toolbar, arDialog])
}
