import { Product } from "@encoway/c-services-js-client"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"
import TimeAgo from "javascript-time-ago"
import { Settings } from "../../../../../settings"

export function getNewsData(product: Product) {
    return {
        imageSrc: CatalogUtils.getMediaUri(product, Characteristics.ProductImage.id),
        timeAgo: getTimeAgo(product),
        longText: CatalogUtils.getLongText(product),
        name: product.name
    }
}

function getTimeAgo(product: Product) {
    const timeAgo = new TimeAgo(Settings.locale)
    const date = CatalogUtils.getCharacteristicValue<string>(product, Characteristics.Date.id)
    try {
        return date ? timeAgo.format(new Date(date)) : undefined
    } catch {
        console.warn("Could not parse date", date)
        return undefined
    }
}
