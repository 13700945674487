import { useEffect, useMemo, useState } from "react"
import { SalesApi } from "../../../../../features/sales/sales.api"
import { CatalogApi } from "../../../../../features/catalog/catalog.api"
import { decisionsToCharacteristics, getProductSelection, getVisibleFiltersFromDecisions } from "../ProductSelection.utils"
import LineItemsUtils from "../../../../../features/sales/utils/LineItemsUtils"
import { useAppSelector } from "../../../../../store/store"
import { DefaultFilter } from "../ProductSelection.constants"

export function useProductFilters(productGroupId: string, searchValue?: string) {
    const [characteristicFilters, setCharacteristicFilters] = useState<Record<string, string | number | string[] | undefined>>({})
    const productSelection = getProductSelection(productGroupId, characteristicFilters, !!searchValue, searchValue)
    const productsQuery = CatalogApi.useProductsQuery(productSelection)
    const filterCharacteristics = SalesApi.useFilterCharacteristicsQuery(productGroupId).data
    const lineItem = useAppSelector(state => state.catalog.selectedLineItem)

    const filterDecisions = useMemo(() => {
        if (lineItem) {
            const decisions = LineItemsUtils.getConfigurationInterfaceDecisions(lineItem)
            return decisionsToCharacteristics(decisions)
        } else {
            return {}
        }
    }, [lineItem])

    useEffect(() => {
        if (lineItem) {
            if (filterCharacteristics?.length) {
                const characteristics = getVisibleFiltersFromDecisions(filterCharacteristics, filterDecisions)
                setCharacteristicFilters({
                    ...DefaultFilter,
                    ...characteristics
                })
            } else {
                setCharacteristicFilters(DefaultFilter)
            }
        } else {
            setCharacteristicFilters({})
        }
    }, [filterDecisions, filterCharacteristics, lineItem])

    const updateFilters = (characteristicId: string, value: string | string[] | number | undefined) => {
        setCharacteristicFilters(prevState => {
            const newState = { ...prevState }
            if (value === undefined || value === "") {
                delete newState[characteristicId]
            } else {
                newState[characteristicId] = value
            }
            return newState
        })
    }

    return {
        characteristicFilters,
        aggregatedCharacteristicValues: productsQuery.currentData?.aggregatedCharacteristicValues ?? {},
        onUpdateFilter: updateFilters,
        productGroupId,
        filterDecisions
    }
}
