import { useEffect, useState } from "react"
import { Backdrop, Portal, Stack, Typography } from "@mui/material"
import { BusySpinner } from "../busySpinner/BusySpinner"
import { useAppSelector } from "../../../../store/store"
import { BackdropSx } from "./BusyOverlay.styles"

export const BusyOverlay = () => {
    const busyTexts = useAppSelector(state => state.busy.texts)
    const [shouldRender, setShouldRender] = useState<boolean>(false)
    const [blurredElement, setBlurredElement] = useState<HTMLElement | null>(null)

    useEffect(() => {
        const timeout = setTimeout(() => setShouldRender(true), 400)
        return () => clearTimeout(timeout)
    }, [])

    useEffect(() => {
        if (shouldRender && !blurredElement) {
            const element = document.activeElement as HTMLElement | null
            setBlurredElement(element)
            element?.blur()
        }
    }, [shouldRender, blurredElement])

    useEffect(() => {
        return () => {
            blurredElement?.focus()
        }
    }, [blurredElement])

    return (
        <Portal container={document.fullscreenElement ?? document.body}>
            <Backdrop open invisible={!shouldRender} component={Stack} sx={BackdropSx}>
                {shouldRender && (
                    <>
                        <BusySpinner />
                        {busyTexts.map(text => (
                            <Typography key={text} color="primary">
                                {text}
                            </Typography>
                        ))}
                    </>
                )}
            </Backdrop>
        </Portal>
    )
}
