import Button from "@mui/material/Button"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../../features/translations/TranslationKeys"
import { SalesApi } from "../../../../../../../../features/sales/sales.api"

type ApplyDefaultDesignationsButtonProps = {
    lineItemId: string
}

export const ApplyDefaultDesignationsButton = ({ lineItemId }: Readonly<ApplyDefaultDesignationsButtonProps>) => {
    const [applyDefaultDesignations] = SalesApi.useApplyDefaultDesignationsMutation()
    const onClick = () => {
        applyDefaultDesignations(lineItemId)
    }
    return (
        <Button onClick={onClick} variant="contained" color="secondary">
            {L10n.format(TranslationKeys.pages.project.configuration.unitOverviewTable.applyDefaultDesignations)}
        </Button>
    )
}
