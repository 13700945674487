import { useCallback, useEffect, useMemo, useRef } from "react"
import { Box, Stack } from "@mui/material"
import { SalesApi } from "../../../../features/sales/sales.api"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import { ResizeBar } from "../../../../components/resizeBar/ResizeBar"
import { useSearchParams } from "react-router-dom"
import { getLineItem, getViewId } from "./ProjectConfiguration.utils"
import { ProjectConfigurationContainerIds, ProjectConfigurationSearchParams } from "./ProjectConfiguration.constants"
import Cui from "./components/cui/Cui"
import { ProjectConfigurationHeader } from "./ProjectConfiguration.Header"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { isNil } from "lodash"
import { SalesSlice } from "../../../../features/sales/sales.slice"
import Dialog from "../../../../components/dialog/Dialog"
import { useDeleteLineItemsHandler } from "../../../../features/sales/hooks/useDeleteLineItemsHandler"
import { ProjectConfigurationTree } from "./components/tree/ProjectConfigurationTree"
import { useSaveConfiguration } from "./hooks/useSaveConfiguration"
import {
    ContainerSx,
    getConfigurationContainerSx,
    HiddenTreeContainerSx,
    HiddenVisualizationContainerSx,
    TreeContainerSx,
    TreeSx,
    VisualizationContainerSx
} from "./ProjectConfiguration.styles"

export function ProjectConfiguration() {
    const [searchParams, setSearchParams] = useSearchParams()
    const lineItemId = searchParams.get(ProjectConfigurationSearchParams.LINE_ITEM_ID) ?? undefined
    const searchParamViewId = searchParams.get(ProjectConfigurationSearchParams.VIEW_ID)
    const focusVisualization = searchParams.get(ProjectConfigurationSearchParams.FOCUS_VISUALIZATION)

    const stackRef = useRef<HTMLDivElement>(null)
    const configurationContainerRef = useRef<HTMLDivElement>(null)
    const visualizationContainerRef = useRef<HTMLDivElement>(null)
    const configurationTreeContainerRef = useRef<HTMLDivElement>(null)

    const saveConfiguration = useSaveConfiguration()
    const dispatch = useAppDispatch()
    const deleteLineItemsHandler = useDeleteLineItemsHandler()

    const { data: projectConfiguration, isSuccess } = SalesApi.useProjectConfigurationQuery()
    const [viewConfiguration] = SalesApi.useViewConfigurationMutation()
    const [openConfiguration] = SalesApi.useOpenConfigurationMutation()
    const [stopConfiguration] = SalesApi.useStopConfigurationMutation()

    const readOnly = useAppSelector(state => state.sales.configurationReadOnly)
    const configurationIsSaved = useAppSelector(state => state.sales.configurationIsSaved)
    const visualizationIsAvailable = useAppSelector(state => state.sales.visualizationIsAvailable)
    const hideConfigurationTree = useAppSelector(state => state.sales.hideConfigurationTree)
    const hideVisualization = useAppSelector(state => state.sales.hideVisualization)
    const hideConfiguration = useAppSelector(state => state.sales.hideConfiguration)

    const lineItems = useMemo(() => {
        return projectConfiguration?.lineItems ?? []
    }, [projectConfiguration?.lineItems])

    const lineItem = useMemo(() => {
        return lineItemId && projectConfiguration ? getLineItem(lineItemId, projectConfiguration)?.lineItem : undefined
    }, [lineItemId, projectConfiguration])

    const isConfigurable = lineItem?.properties.configurable || lineItem?.properties.FOLDER_TYPE === "LINEUP"
    const viewId = getViewId(searchParamViewId, lineItem)
    const showConfigurationTree = !hideConfigurationTree
    const showVisualization = isConfigurable && visualizationIsAvailable && !hideVisualization
    const showConfiguration = isConfigurable && !hideConfiguration
    const showLeftResizeBar = showConfigurationTree && (showVisualization || showConfiguration)
    const showRightResizeBar = showConfiguration && showVisualization
    const configurationLineItemId = lineItem?.properties.FOLDER_TYPE === "LINEUP" ? lineItem.properties.LINEUP_ARTICLE_LINE_ITEM_ID! : lineItem?.lineItemId

    const startConfiguration = useCallback(
        async (lineItemId: string) => {
            await stopConfiguration()
            if (readOnly) {
                await viewConfiguration([lineItemId, viewId])
            } else {
                await openConfiguration([lineItemId, viewId])
            }
        },
        [openConfiguration, readOnly, stopConfiguration, viewConfiguration, viewId]
    )

    const changeConfigurationLayout = useCallback(() => {
        if (!isNil(focusVisualization)) {
            dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideVisualization: false, hideConfiguration: true }))
        } else {
            dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideVisualization: false, hideConfiguration: false }))
        }
    }, [dispatch, focusVisualization])

    useEffect(() => {
        if (isConfigurable && configurationLineItemId) {
            startConfiguration(configurationLineItemId).then(() => changeConfigurationLayout())
        }
    }, [configurationLineItemId, isConfigurable, startConfiguration, stopConfiguration, changeConfigurationLayout, viewId])

    useEffect(() => {
        return () => {
            stopConfiguration()
        }
    }, [stopConfiguration])

    useEffect(() => {
        changeConfigurationLayout()
    }, [changeConfigurationLayout])

    const onSelectedLineItemChange = useCallback(
        async (newLineItemId?: string) => {
            if (newLineItemId !== lineItemId) {
                if (newLineItemId && lineItemId && !configurationIsSaved) {
                    await saveConfiguration()
                }
                setSearchParams(newLineItemId ? { [ProjectConfigurationSearchParams.LINE_ITEM_ID]: newLineItemId } : undefined)
            }
        },
        [configurationIsSaved, saveConfiguration, setSearchParams, lineItemId]
    )

    const confirmDelete = () => {
        deleteLineItemsHandler.deleteLineItems().then(() => {
            if (lineItemId && deleteLineItemsHandler.lineItemsToDelete?.includes(lineItemId)) {
                searchParams.delete(ProjectConfigurationSearchParams.LINE_ITEM_ID)
                dispatch(SalesSlice.actions.setConfigurationId(undefined))
                setSearchParams(searchParams)
            }
        })
    }

    return (
        <Stack spacing={2} flexGrow={1} display="flex" overflow="auto">
            <ProjectConfigurationHeader lineItem={lineItem} />
            <Stack ref={stackRef} direction="row" spacing={1} id={ProjectConfigurationContainerIds.PROJECT_CONFIGURATION_CONTAINER} sx={ContainerSx}>
                {isSuccess && lineItems.length === 0 && L10n.format(TranslationKeys.pages.project.configuration.emptyTreeMessage)}
                {isSuccess && lineItems.length > 0 && (
                    <Box ref={configurationTreeContainerRef} sx={showConfigurationTree ? TreeContainerSx : HiddenTreeContainerSx}>
                        <ProjectConfigurationTree
                            sx={TreeSx}
                            lineItems={lineItems}
                            selectedLineItemId={lineItemId}
                            onSelectedLineItemChange={onSelectedLineItemChange}
                        />
                    </Box>
                )}
                {showLeftResizeBar && (
                    <ResizeBar
                        target={configurationTreeContainerRef}
                        dependency={showVisualization ? visualizationContainerRef : configurationContainerRef}
                        direction={-1}
                        axis="x"
                    />
                )}
                <Box
                    id={ProjectConfigurationContainerIds.VISUALIZATION}
                    ref={visualizationContainerRef}
                    sx={showVisualization ? VisualizationContainerSx : HiddenVisualizationContainerSx}
                />
                {showRightResizeBar && <ResizeBar target={configurationContainerRef} dependency={visualizationContainerRef} direction={1} axis="x" />}
                <Box
                    id={ProjectConfigurationContainerIds.CONFIGURATION}
                    ref={configurationContainerRef}
                    sx={getConfigurationContainerSx(showConfiguration, showVisualization)}
                ></Box>
                {isConfigurable && (
                    <Box sx={{ display: "none" }}>
                        <Cui lineItem={lineItem} />
                    </Box>
                )}
            </Stack>

            <Dialog
                open={deleteLineItemsHandler.isAskingToDeleteLineItems}
                onClose={deleteLineItemsHandler.cancelDeletingLineItems}
                title={L10n.format(TranslationKeys.lineItem.deleteDialog.title)}
                message={L10n.format(TranslationKeys.lineItem.deleteDialog.message)}
                defaultDialogActions={{
                    onConfirm: confirmDelete,
                    onCancel: deleteLineItemsHandler.cancelDeletingLineItems,
                    confirmButtonLabel: TranslationKeys.lineItem.deleteDialog.confirmButtonLabel,
                    cancelButtonLabel: TranslationKeys.lineItem.deleteDialog.cancelButtonLabel
                }}
            />
        </Stack>
    )
}
