import { Typography } from "@mui/material"
import theme from "../../../../../App.theme"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { Product } from "@encoway/c-services-js-client"

interface LineupNonCompatibleProductsProps {
    products: Product[]
}

export function LineupNonCompatibleProducts({ products }: Readonly<LineupNonCompatibleProductsProps>) {
    return (
        <div>
            <Typography color={theme.palette.warning.main}>
                {L10n.format(TranslationKeys.pages.project.catalog.addToLineupDialog.lineupNonCompatible)}
            </Typography>
            {products.map(product => (
                <Typography key={product.id} color={theme.palette.warning.main}>
                    <li>{product.name}</li>
                </Typography>
            ))}
        </div>
    )
}
