import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface OidcState {
    token?: string
    idToken?: string
}

const initialState: OidcState = {}

export const OidcSlice = createSlice({
    name: "oidc",
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },
        setIdToken: (state, action: PayloadAction<string>) => {
            state.idToken = action.payload
        }
    }
})
