import { useCallback, useEffect, useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { ProjectConfigurationLineItem } from "../sales.api.types"
import useDialog from "../../../components/dialog/useDialog"
import { SalesApi } from "../sales.api"
import { getDragAndDropHoverPosition } from "../../../utils/getDragAndDropHoverPosition"
import SalesUtils from "../sales.utils"
import { getPreviousItem } from "../../../pages/projectPage/components/projectConfiguration/ProjectConfiguration.utils"
import { AbbLineItemWithParent } from "../sales.types"
import LineItemsUtils from "../utils/LineItemsUtils"

interface UnitMoveAction {
    hoverPosition: "top" | "bottom" | "center" | undefined
    draggedItem: ProjectConfigurationLineItem
}

const DRAG_AND_DROP_ITEM_TYPE = "lineItems"

export function useLineItemDragDrop(item: AbbLineItemWithParent) {
    const ref = useRef<HTMLDivElement>(null)
    const { isOpen, open, close } = useDialog()
    const [unitMoveAction, setUnitMoveAction] = useState<UnitMoveAction>()
    const [moveLineItems] = SalesApi.useMoveLineItemsMutation()
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const projectConfiguration = SalesApi.endpoints.projectConfiguration.useQueryState().data!

    const canDrag = useCallback(
        monitor => {
            const rect = ref.current?.getElementsByClassName("DragAndDropButton")[0]?.getBoundingClientRect()
            const clientOffset = monitor.getClientOffset()
            return !!(rect && clientOffset && clientOffset.x >= rect.x && clientOffset.x <= rect.x + rect.width)
        },
        [ref]
    )

    const canDrop = useCallback(
        (draggedItem, monitor) => {
            const hoverPosition = getDragAndDropHoverPosition(monitor, ref)
            return draggedItem && SalesUtils.lineItems.canDropLineItem(draggedItem, item, salesDocument, hoverPosition)
        },
        [item, salesDocument]
    )

    const moveLineItemsToHoverPosition = useCallback(
        async (unitMoveAction, splitItems = false) => {
            if (unitMoveAction) {
                if (unitMoveAction.hoverPosition === "top") {
                    const previousBomNode = getPreviousItem(
                        item as ProjectConfigurationLineItem,
                        projectConfiguration,
                        item.parent as ProjectConfigurationLineItem
                    )
                    await moveLineItems({
                        selectedLineItemIds: [unitMoveAction.draggedItem.lineItem.lineItemId],
                        parentLineItemId: item.parent?.lineItem.lineItemId,
                        previousLineItem: previousBomNode?.lineItem.lineItemId ?? "FIRST",
                        splitItems
                    })
                } else if (unitMoveAction.hoverPosition === "bottom") {
                    await moveLineItems({
                        selectedLineItemIds: [unitMoveAction.draggedItem.lineItem.lineItemId],
                        parentLineItemId: item.parent?.lineItem.lineItemId,
                        previousLineItem: item.lineItem.lineItemId,
                        splitItems
                    })
                } else if (unitMoveAction.hoverPosition === "center") {
                    await moveLineItems({
                        selectedLineItemIds: [unitMoveAction.draggedItem.lineItem.lineItemId],
                        parentLineItemId: item.lineItem.lineItemId,
                        splitItems
                    })
                }
            }
        },
        [item, projectConfiguration, moveLineItems]
    )

    const isMovingLineItemWithQuantityGreaterOneIntoLineup = useCallback(
        unitMoveAction => {
            const { draggedItem } = unitMoveAction
            const parent = LineItemsUtils.getParentLineItemBasedOnHoverPosition(item, unitMoveAction.hoverPosition)
            return parent && LineItemsUtils.isLineupFolder(parent.lineItem) && draggedItem.lineItem.properties.newAmount! > 1
        },
        [item]
    )

    const drop = useCallback(
        (draggedItem, monitor) => {
            const hoverPosition = getDragAndDropHoverPosition(monitor, ref)
            const unitMoveAction = { hoverPosition: hoverPosition, draggedItem: draggedItem }
            if (isMovingLineItemWithQuantityGreaterOneIntoLineup(unitMoveAction)) {
                setUnitMoveAction(unitMoveAction)
                open()
            } else {
                moveLineItemsToHoverPosition(unitMoveAction)
            }
        },
        [isMovingLineItemWithQuantityGreaterOneIntoLineup, moveLineItemsToHoverPosition, open]
    )

    const dropCollect = useCallback(
        monitor => {
            return {
                hoverPosition: canDrop(monitor.getItem(), monitor) ? getDragAndDropHoverPosition(monitor, ref) : undefined,
                dragItem: monitor.getItem(),
                dropNotAllowed: !!monitor.getItem() && !canDrop(monitor.getItem(), monitor)
            }
        },
        [ref, canDrop]
    )

    const [dragProps, dragRef] = useDrag(
        () => ({
            type: DRAG_AND_DROP_ITEM_TYPE,
            item: item,
            collect: monitor => ({
                difference: monitor.getDifferenceFromInitialOffset(),
                isDragging: monitor.isDragging()
            }),
            canDrag: canDrag
        }),
        [item, canDrag]
    )

    const [dropProps, dropRef] = useDrop(
        () => ({
            accept: DRAG_AND_DROP_ITEM_TYPE,
            collect: dropCollect,
            drop: drop,
            canDrop: canDrop
        }),
        [drop, dropCollect, canDrop]
    )

    useEffect(() => {
        dragRef(ref.current)
        dropRef(ref.current)
    }, [ref, dragRef, dropRef])

    return {
        ref,
        dragProps,
        dropProps,
        isSplitItemDialogOpen: isOpen,
        openSplitItemDialog: open,
        closeSplitItemDialog: close,
        unitMoveAction,
        moveLineItemsToHoverPosition
    }
}
