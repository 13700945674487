import getAppPath from "./router/utils/getAppPath"
import { merge } from "lodash"
import { translations as cuiConfiguratorTranslations } from "@encoway/cui-configurator-components"
import { translations as cuiApplicationTranslations } from "@encoway/cui-application-components"

export const Settings = {
    locale: "en-US",
    configuration: {
        includeCredentials: true,
        options: {
            articleName: "",
            linkedConfiguration: true
        },
        settings: {},
        baseUrl: getAppPath()
    },
    visualization: {
        mode: "visu", // we do not use the default visualization
        baseUrl: "https://visualization.encoway.cloud/vcs",
        version: "1.11.0",
        settings: {
            ui: {
                components: {
                    catalog: false,
                    header: false
                },
                camera: {
                    logarithmicDepthBuffer: true
                }
            },
            theme: {
                palette: {
                    themePrimary: "#ff000f",
                    themeLighterAlt: "#fff5f5",
                    themeLighter: "#ffd6d9",
                    themeLight: "#ffb3b8",
                    themeTertiary: "#ff6670",
                    themeSecondary: "#ff1f2e",
                    themeDarkAlt: "#e6000f",
                    themeDark: "#c2000d",
                    themeDarker: "#8f000a",
                    neutralLighterAlt: "#faf9f8",
                    neutralLighter: "#f3f2f1",
                    neutralLight: "#edebe9",
                    neutralQuaternaryAlt: "#e1dfdd",
                    neutralQuaternary: "#d0d0d0",
                    neutralTertiaryAlt: "#c8c6c4",
                    neutralTertiary: "#a19f9d",
                    neutralSecondary: "#605e5c",
                    neutralSecondaryAlt: "#8a8886",
                    neutralPrimaryAlt: "#3b3a39",
                    neutralPrimary: "#323130",
                    neutralDark: "#201f1e",
                    black: "#000000",
                    white: "#ffffff"
                }
            }
        }
    },
    translations: merge(cuiConfiguratorTranslations, cuiApplicationTranslations)
}
