import { VISUALIZATION_IMAGE_ORTHOGRAPHIC_PREFIX, VISUALIZATION_IMAGE_PREFIX } from "./visualization.constants"
import { Visualization } from "@encoway/visual-editor"

export function createRenderingsResult(renderings?: Record<string, string>, orthographicRenderings?: Record<string, string>): Record<string, string> {
    return {
        ...(renderings ? getRenderingResult(renderings) : {}),
        ...(orthographicRenderings ? getRenderingResult(orthographicRenderings, true) : {})
    }
}

function getRenderingResult(renderings: Record<string, string>, orthographic?: boolean) {
    return Object.entries(renderings).reduce((result, [key, value]) => ({ ...result, [getVisualizationImageKey(key, orthographic)]: value }), {})
}

function getVisualizationImageKey(imageName: string, orthographic?: boolean) {
    const prefix = orthographic ? VISUALIZATION_IMAGE_PREFIX + VISUALIZATION_IMAGE_ORTHOGRAPHIC_PREFIX : VISUALIZATION_IMAGE_PREFIX
    return prefix + imageName.replaceAll("-", "_").toUpperCase()
}

export function setVisualizationRootNodeState(state: any, visualization: Visualization) {
    const rootNode = visualization.cloud
        .graph()
        .nodes()
        .find((node: any) => node.id() === "article")
    if (rootNode) {
        rootNode.state.setState(state)
        console.log("Passed data to visualization", state, rootNode)
    } else {
        console.warn("Missing root node to pass data to visualization", visualization?.cloud.graph().nodes())
    }
}
