import { Characteristic } from "@encoway/c-services-js-client"
import Dropdown from "../../../../components/dropdown/Dropdown"
import { L10n } from "@encoway/l10n"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { InputLabel } from "../../../../components/input/Input.Label"
import { SalesApi } from "../../sales.api"
import { Characteristics } from "../../../catalog/catalog.constants"
import { CatalogEntityInfoDrawerContent } from "../../../catalog/components/catalogEntityInfoDrawerContent/CatalogEntityInfoDrawerContent"
import { useAppSelector } from "../../../../store/store"
import SalesUtils from "../../sales.utils"
import Input from "../../../../components/input/Input"
import TranslationKeys from "../../../translations/TranslationKeys"
import { useEffect, useState } from "react"
import { getInputType, getSalesDocumentPropertyInputCharacteristicData } from "./SalesDocumentPropertyInput.utils"

export interface SalesDocumentPropertyInputProps {
    characteristic: Characteristic
    requiredCharacteristicId: string
}

export function SalesDocumentPropertyInput({ characteristic, requiredCharacteristicId }: Readonly<SalesDocumentPropertyInputProps>) {
    const permissions = SalesApi.useCurrencyPermissionsQuery().data
    const [updateSalesDocument] = SalesApi.useUpdateSalesDocumentMutation()
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const priceLists = SalesApi.usePriceListsQuery().data
    const salesDocumentProperty = SalesApi.useSalesDocumentQuery(undefined, {
        selectFromResult: ({ data }) => ({
            value: data?.properties[characteristic.id],
            isEditable: data ? SalesUtils.salesDocument.isEditableProperty(characteristic.id, data) : false,
            hasValidationProblems: data ? SalesUtils.salesDocument.hasValidationProblems(characteristic.id, data) : false
        })
    })

    const updateSalesDocumentPropertyValue = (value: string | number) => {
        updateSalesDocument([{ [characteristic.id]: value }, {}])
    }

    const label = characteristic.name ?? ""
    const characteristicData = getSalesDocumentPropertyInputCharacteristicData(characteristic, requiredCharacteristicId, permissions)
    const menuItems = characteristicData.possibleValues.map(pv => ({ value: pv.id, text: pv.value as string }))
    const isPriceListInput = characteristicData.valueType === Characteristics.ProjectPropertyValueType.possibleValues.PRICE_LIST
    const disabled = readOnly || isPriceListInput || !salesDocumentProperty.isEditable

    const value = isPriceListInput
        ? priceLists?.find(list => list.priceListId === salesDocumentProperty.value)?.properties["sys_pl_id"] ?? ""
        : (salesDocumentProperty.value as undefined | string | number)

    const [internalValue, setInternalValue] = useState(value)

    useEffect(() => setInternalValue(value), [value])

    const info =
        characteristicData.productImageUrl || characteristicData.shortText || characteristicData.longText ? (
            <CatalogEntityInfoDrawerContent
                name={characteristic.name}
                imageUrl={characteristicData.productImageUrl}
                shortText={characteristicData.shortText}
                longText={characteristicData.longText}
            />
        ) : undefined

    if (characteristic.possibleValues) {
        return (
            <Dropdown
                name={characteristic.id}
                menuItems={menuItems}
                value={menuItems.some(item => item.value === value) ? value : ""}
                label={label}
                required={characteristicData.required}
                disabled={disabled}
                error={salesDocumentProperty.hasValidationProblems}
                info={info}
                renderInfoInDrawer
                unselect
                fullWidth
                cypressId={characteristic.id}
                onChange={event => updateSalesDocumentPropertyValue(event.target.value)}
            />
        )
    } else if (characteristicData.valueType === Characteristics.ProjectPropertyValueType.possibleValues.DATE) {
        return (
            <DatePicker
                name={characteristic.id}
                value={value ? dayjs(value) : null}
                label={<InputLabel label={characteristic.name ?? ""} info={info} renderInfoInDrawer={true} />}
                disabled={disabled}
                slotProps={{ textField: { required: characteristicData.required, error: salesDocumentProperty.hasValidationProblems } }}
                onChange={value => {
                    updateSalesDocumentPropertyValue(value?.toISOString() ?? "")
                }}
            />
        )
    } else {
        return (
            <Input
                name={characteristic.id}
                value={internalValue ?? ""}
                type={getInputType(characteristic)}
                label={characteristic.name ?? ""}
                placeholder={L10n.format(TranslationKeys.components.input.inputPlaceholder)}
                disabled={disabled}
                required={characteristicData.required}
                info={info}
                renderInfoInDrawer
                error={salesDocumentProperty.hasValidationProblems}
                inputProps={{ maxLength: characteristicData.maxLength }}
                onChange={event => setInternalValue(event.target.value)}
                onBlur={event => updateSalesDocumentPropertyValue((event.target as HTMLInputElement).value)}
            />
        )
    }
}
