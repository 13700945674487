import { Box, Button, Stack, Tooltip } from "@mui/material"
import { Queue } from "@mui/icons-material"
import Dialog from "../../../../../../../components/dialog/Dialog"
import useDialog from "../../../../../../../components/dialog/useDialog"
import { ClipboardCellStyles } from "./ClipboardCell.styles"

export default function ClipboardCell({ text, title }: { text?: string; title: string }) {
    const dialog = useDialog()

    const onClickCopyButton = () => {
        navigator.clipboard.writeText(text ?? "")
        dialog.close()
    }

    return text ? (
        <>
            <Tooltip title={text}>
                <Stack direction="row" spacing={1} onClick={dialog.open} sx={ClipboardCellStyles.stack}>
                    <span style={ClipboardCellStyles.text}>{text}</span>
                    <Queue fontSize="small" />
                </Stack>
            </Tooltip>
            <Dialog maxWidth="xl" title={title} icon={<Queue fontSize="small" />} open={dialog.isOpen} onClose={dialog.close} withDividers>
                <Stack spacing={1}>
                    <span>{title}</span>
                    <Stack direction="row" alignItems="end" spacing={1}>
                        <Box sx={ClipboardCellStyles.box} dangerouslySetInnerHTML={{ __html: text.replaceAll("+", "<wbr>+") }} />
                        <Button variant="contained" color="secondary" size="large" onClick={onClickCopyButton} sx={ClipboardCellStyles.button}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Queue />
                                <span>{title}</span>
                            </Stack>
                        </Button>
                    </Stack>
                </Stack>
            </Dialog>
        </>
    ) : (
        <></>
    )
}
