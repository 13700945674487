import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../translations/TranslationKeys"
import { AddLineItemsArgs, AddProductsUpdateServerResponse, ArticleWithQuantity } from "../sales.api.types"
import { SnackbarSlice } from "../../snackbar/snackbar.slice"
import { SalesService } from "@encoway/sales-api-js-client"
import { StringLineItemProperties } from "../sales.constants"
import { AbbLineItem } from "../sales.types"
import { isNil, merge } from "lodash"
import { SalesQueryFnOptions } from "../sales.api.utils"

const CHUNK_SIZE = 5

export const addItemsQuery: SalesQueryFnOptions<AddProductsUpdateServerResponse | undefined, AddLineItemsArgs>["query"] = async (
    args: AddLineItemsArgs,
    salesService: SalesService,
    api: BaseQueryApi
) => {
    const { response, errorProductIds } = await makeRequests(args, salesService)
    const success = errorProductIds.length === 0
    if (!success) {
        handleErrors(errorProductIds, args.articlesToInsert, api)
    }
    if (response) {
        handleInvalidPresets(response, api)
        handleAddItemsResponse(response, args, api, success)
        return response
    }
}

async function makeRequests(args: AddLineItemsArgs, salesService: SalesService) {
    const responses: AddProductsUpdateServerResponse[] = []
    const errorProductIds: string[] = []
    for (let i = 0; i < args.articlesToInsert.length; i += CHUNK_SIZE) {
        const articlesToInsert = args.articlesToInsert.slice(i, i + CHUNK_SIZE)
        try {
            const actualArgs = {
                ...args,
                articlesToInsert: articlesToInsert.map(article => ({
                    articleId: article.articleId,
                    quantity: article.quantity
                }))
            }
            const response: AddProductsUpdateServerResponse = await salesService.custom.call("/lineItem/additems", actualArgs)
            response.failedLineItemIds.forEach(id => errorProductIds.push(id))
            responses.push(response)
        } catch (e) {
            articlesToInsert.forEach(article => errorProductIds.push(article.articleId))
        }
    }
    return {
        response: responses.length > 0 ? (merge({}, ...responses) as AddProductsUpdateServerResponse) : undefined,
        errorProductIds
    }
}

function handleErrors(errorProductIds: string[], articlesToInsert: ArticleWithQuantity[], api: BaseQueryApi) {
    const productNames = errorProductIds.map(id => "'" + getArticleName(id, articlesToInsert) + "'").join(", ")
    const message = L10n.format(TranslationKeys.pages.project.catalog.productSelection.multiple.addToCompositionErrorMessage, { productNames })
    api.dispatch(SnackbarSlice.actions.open({ message, severity: "error" }))
}

function getArticleName(articleId: string, articlesToInsert: ArticleWithQuantity[]) {
    const article = articlesToInsert.find(article => article.articleId === articleId)
    return article?.articleName ?? article?.articleId
}

function handleAddItemsResponse(response: AddProductsUpdateServerResponse, args: AddLineItemsArgs, api: BaseQueryApi, success = true) {
    const translationKeys = getTranslationKeys(args)
    const addedItemsWithValidationProblems = getAddedItemsWithHighSeverityValidationProblems(response)
    if (addedItemsWithValidationProblems) {
        const errorMessage = L10n.format(translationKeys.error, { addedItemsWithValidationProblems })
        api.dispatch(SnackbarSlice.actions.open({ message: errorMessage, severity: "warning" }))
    } else if (success) {
        api.dispatch(SnackbarSlice.actions.open({ message: L10n.format(translationKeys.success), severity: "success" }))
    }
}

function handleInvalidPresets(response: AddProductsUpdateServerResponse, api: BaseQueryApi) {
    if (response.invalidPresets.length > 0) {
        response.invalidPresets.forEach(invalidPreset => {
            const errorMessage = L10n.format(
                isNil(invalidPreset.characteristicTranslatedValue)
                    ? TranslationKeys.configuration.invalidPresetValueNotFoundMessage
                    : TranslationKeys.configuration.invalidPresetMessage,
                {
                    articleName: invalidPreset.articleName,
                    characteristicName: invalidPreset.characteristicName,
                    characteristicTranslatedValue: invalidPreset.characteristicTranslatedValue
                }
            )
            api.dispatch(SnackbarSlice.actions.open({ message: errorMessage, severity: "error" }))
        })
    }
}

function getTranslationKeys(args: AddLineItemsArgs) {
    const key = args.articlesToInsert.length === 1 ? "single" : "multiple"
    return {
        success: TranslationKeys.pages.project.catalog.productSelection[key].addToCompositionSuccessMessage,
        error: TranslationKeys.pages.project.catalog.productSelection[key].addToCompositionValidationProblemsMessage
    }
}

function getAddedItemsWithHighSeverityValidationProblems(updateServerResponse: AddProductsUpdateServerResponse): string {
    return getAddedItems(updateServerResponse)
        .filter(hasHighSeverityValidationProblems)
        .map(item => "'" + item.properties[StringLineItemProperties.CONFIGURATION_NAME] + "'")
        .join(", ")
}

function hasHighSeverityValidationProblems(lineItem: AbbLineItem) {
    return lineItem.properties.validationProblems?.flat().some(validationProblem => validationProblem.severity === "HIGH") ?? false
}

function getAddedItems(updateServerResponse: AddProductsUpdateServerResponse): AbbLineItem[] {
    return Object.values(updateServerResponse.addedChildLineItems).flat()
}
