import { useEffect, useState } from "react"
import { L10n } from "@encoway/l10n"
import { getProductCategoryInfoMessage } from "./CustomArticleDialog.utils"
import { AbbLineItem, AbbLineItemProperties } from "../../sales.types"
import { SalesApi } from "../../sales.api"
import { useUserInformation } from "../../../oidc/hooks/useUserInformation"
import Dialog from "../../../../components/dialog/Dialog"
import TranslationKeys from "../../../translations/TranslationKeys"
import { Alert, Stack } from "@mui/material"
import Input from "../../../../components/input/Input"
import { OidcUtils } from "../../../oidc/oidc.utils"
import { LineItemProperties } from "../../sales.constants"

interface CustomArticleDialogProps {
    lineItem?: AbbLineItem
    onClose: () => void
}

export function CustomArticleDialog({ onClose, lineItem }: CustomArticleDialogProps) {
    const [inputValues, setInputValues] = useState<Partial<AbbLineItemProperties>>({})
    const [addCustomLineItem] = SalesApi.useAddCustomLineItemMutation()
    const [updateLineItem] = SalesApi.useUpdateLineItemMutation()
    const userInformation = useUserInformation()
    const productCategory = SalesApi.endpoints.salesDocument.useQueryState(undefined, {
        selectFromResult: ({ data }) => ({
            productCategory: data?.properties.PRODUCT_CATEGORY
        })
    }).productCategory

    useEffect(() => {
        if (lineItem) {
            setInputValues(lineItem.properties)
        }
    }, [lineItem])

    const updateInputValues = (property: keyof Partial<AbbLineItemProperties>, value: any) => {
        setInputValues(prevState => ({ ...prevState, [property]: value }))
    }

    const close = () => {
        onClose()
        setInputValues({})
    }

    const submit = () => {
        if (lineItem) {
            updateLineItem([lineItem?.lineItemId, inputValues]).then(close)
        } else {
            addCustomLineItem({ ...inputValues, name: inputValues.label }).then(close)
        }
    }

    const infoMessage = getProductCategoryInfoMessage(productCategory)
    const numberOfRequiredInputValues =
        2 + (OidcUtils.hasUpdateListPricesRole(userInformation) ? 1 : 0) + (OidcUtils.hasUpdateCostPricesRole(userInformation) ? 1 : 0)
    const isValid = numberOfRequiredInputValues <= Object.values(inputValues).filter(value => value != null && value !== "").length

    return (
        <Dialog
            withDividers
            title={
                lineItem ? L10n.format(TranslationKeys.lineItem.newArticle.dialog.titleEdit) : L10n.format(TranslationKeys.lineItem.newArticle.dialog.titleAdd)
            }
            open={true}
            onClose={onClose}
            defaultDialogActions={{
                onConfirm: submit,
                disableConfirm: !isValid,
                onCancel: close,
                cancelButtonLabel: L10n.format(TranslationKeys.lineItem.newArticle.dialog.cancelButtonLabel),
                confirmButtonLabel: L10n.format(TranslationKeys.lineItem.newArticle.dialog.submitButtonLabel)
            }}
        >
            <Stack component="form" rowGap={2} width={400}>
                {infoMessage && !lineItem && <Alert severity="info">{infoMessage}</Alert>}
                <Input
                    autoFocus
                    value={inputValues.label ?? ""}
                    label={L10n.format(TranslationKeys.lineItem.properties.productName)}
                    required
                    inputProps={{ maxLength: 50 }}
                    onChange={event => updateInputValues(LineItemProperties.PRODUCT_NAME, event.target.value)}
                />
                <Input
                    value={inputValues.shortText ?? ""}
                    label={L10n.format(TranslationKeys.lineItem.properties.shortText)}
                    required
                    inputProps={{ maxLength: 50 }}
                    onChange={event => updateInputValues(LineItemProperties.SHORT_TEXT, event.target.value)}
                />
                {OidcUtils.hasUpdateListPricesRole(userInformation) && (
                    <Input
                        type="number"
                        value={inputValues.plainListPrice ?? ""}
                        label={L10n.format(TranslationKeys.lineItem.properties.plainListPrice)}
                        required
                        inputProps={{ maxLength: 50 }}
                        onChange={event => updateInputValues(LineItemProperties.PLAIN_LIST_PRICE, event.target.value)}
                    />
                )}
                {OidcUtils.hasUpdateCostPricesRole(userInformation) && (
                    <Input
                        type="number"
                        value={inputValues.plainCost ?? ""}
                        label={L10n.format(TranslationKeys.lineItem.properties.plainCost)}
                        required
                        inputProps={{ maxLength: 50 }}
                        onChange={event => updateInputValues(LineItemProperties.PLAIN_COST, event.target.value)}
                    />
                )}
            </Stack>
        </Dialog>
    )
}
