import React, { useMemo } from "react"
import { ParameterProps, SectionProps } from "@encoway/cui-configurator-components"
import { Box, Stack, Typography } from "@mui/material"
import { ErrorAlert } from "../../../../../../../../../features/error/components/errorAlert/ErrorAlert"
import { CatalogApi } from "../../../../../../../../../features/catalog/catalog.api"
import CatalogUtils from "../../../../../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../../../../../features/catalog/catalog.constants"
import { ParamComponent } from "../AbbSection.ParamComponent"
import { BurgerMenuHandle } from "@encoway/cui-configurator-components/src/components/BurgerMenu/BurgerMenu.types"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../../../features/translations/TranslationKeys"

interface ExtendedSectionProps extends SectionProps {
    /**
     * Stores the BurgerMenu handles displayed in the Section.
     */
    burgermenuHandles?: React.RefObject<BurgerMenuHandle>[]
    /**
     * Data information displayed in the Root component.
     */
    rootDataId?: string
}

export const MfkSection = (props: ExtendedSectionProps) => {
    const { styles, theme, data, hideLabel, shouldDisplayLabel, onRenderLabel, ...parameterProps } = props
    const parameters = data.parameters
    const burgermenuHandles = props.burgermenuHandles || []

    const error = useMemo(() => {
        if (parameters.length !== 2) {
            return `Wrong number of parameters for Mfk widget. Number of parameters must be 2, but found ${parameters.length}.`
        } else if (parameters[0].name + "_USER" !== parameters[1].name) {
            return `ID of second parameter must be ID of first parameter with suffix '_USER'`
        }
    }, [parameters])

    const { data: characteristic } = CatalogApi.useCharacteristicQuery(parameters.at(0)?.name!, { skip: !!error })

    const plmMapping = characteristic ? CatalogUtils.getCharacteristicValue(characteristic, Characteristics.PlmMapping.id) : undefined
    const sapMapping = characteristic ? CatalogUtils.getCharacteristicValue(characteristic, Characteristics.SapMapping.id) : undefined

    const modifiedParameters = useMemo(() => {
        return parameters.map(parameter => ({
            ...parameter,
            values: parameter.values?.map(value => ({ ...value, translatedValue: `${value.value}: ${value.translatedValue}` }))
        }))
    }, [parameters])

    return error ? (
        <ErrorAlert error={error} />
    ) : (
        <Stack paddingTop="2em">
            <Stack direction="row" spacing={2} marginTop={2} alignItems="center">
                <Stack direction="row" spacing={1}>
                    <Typography variant="body2" fontWeight="bold">
                        {L10n.format(TranslationKeys.configuration.widgets.mfk.plmLabel)}
                    </Typography>
                    <Typography variant="body2">{plmMapping ?? "-"}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Typography variant="body2" fontWeight="bold">
                        {L10n.format(TranslationKeys.configuration.widgets.mfk.sapLabel)}
                    </Typography>
                    <Typography variant="body2">{sapMapping ?? "-"}</Typography>
                </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Box sx={{ width: 300 }}>
                    <ParamComponent parameter={modifiedParameters[0]} burgerMenuHandles={burgermenuHandles} {...(parameterProps as ParameterProps)} />
                </Box>
                <Box sx={{ width: 300 }}>
                    <ParamComponent parameter={modifiedParameters[1]} burgerMenuHandles={burgermenuHandles} {...(parameterProps as ParameterProps)} />
                </Box>
            </Stack>
        </Stack>
    )
}
