import { useState } from "react"
import { Alert, Button, IconButton, Stack, Tooltip } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import FactoryIcon from "@mui/icons-material/Factory"
import StoreIcon from "@mui/icons-material/Store"
import PersonIcon from "@mui/icons-material/Person"
import AssessmentIcon from "@mui/icons-material/Assessment"
import { L10n } from "@encoway/l10n"
import LineItemsDataGrid from "./lineItemsDataGrid/LineItemsDataGrid"
import useNavigate from "../../../../router/hooks/useNavigate"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import Dialog from "../../../../components/dialog/Dialog"
import useDialog from "../../../../components/dialog/useDialog"
import { CustomArticleDialog } from "../../../../features/sales/components/customArticleDialog/CustomArticleDialog"
import DocumentGenerationForm from "../../../../features/catalog/components/documentGenerationForm/DocumentGenerationForm"
import theme from "../../../../App.theme"
import { ContentBlockIds, ProductGroupIds } from "../../../../features/catalog/catalog.constants"
import { CatalogApi } from "../../../../features/catalog/catalog.api"
import { SalesApi } from "../../../../features/sales/sales.api"
import usePrint from "../../../../features/sales/hooks/usePrint"
import { useDeleteLineItemsHandler } from "../../../../features/sales/hooks/useDeleteLineItemsHandler"
import { AbbLineItem } from "../../../../features/sales/sales.types"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import { SalesSlice } from "../../../../features/sales/sales.slice"
import { ProjectCompositionView } from "./constants/ProjectCompositionView"
import { OidcUtils } from "../../../../features/oidc/oidc.utils"
import { useUserInformation } from "../../../../features/oidc/hooks/useUserInformation"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"
import { Currency } from "./constants/Currency"

export default function ProjectComposition() {
    const [lineItemToPrint, setLineItemToPrint] = useState<AbbLineItem>()
    const [selectedProjectCompositionView, setSelectedProjectCompositionView] = useState(ProjectCompositionView.ABB_SALES_UNIT_VIEW)

    const salesDocumentReadOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const documentTypes = CatalogApi.useDocumentTypesQuery(ContentBlockIds.DocumentTypes).data ?? []

    const { productCategory, salesDocumentId, transferPriceCurrency } = SalesApi.endpoints.salesDocument.useQueryState(undefined, {
        selectFromResult: ({ currentData }) => ({
            productCategory: currentData?.properties.PRODUCT_CATEGORY,
            salesDocumentId: currentData?.salesDocumentId,
            transferPriceCurrency: currentData?.properties[SalesDocumentProperties.TRANSFER_PRICE_CURRENCY]
        })
    })

    const [addFolder] = SalesApi.useAddFolderMutation()
    const [expandAllLineItems] = SalesApi.useExpandAllLineItemsMutation()
    const [collapseAllLineItems] = SalesApi.useCollapseAllLineItemsMutation()

    const deleteLineItemsHandler = useDeleteLineItemsHandler()
    const print = usePrint()
    const navigate = useNavigate()
    const dialog = useDialog()
    const dispatch = useAppDispatch()
    const userInformation = useUserInformation()

    const openConfiguration = (lineItem: AbbLineItem, readOnly?: boolean) => {
        dispatch(SalesSlice.actions.setConfigurationReadOnly(!!readOnly))
        navigate.toProjectConfiguration(salesDocumentId!, lineItem.lineItemId)
    }

    const submitDocumentGeneration = (documentTemplate: string, documentFormat: string) => {
        setLineItemToPrint(undefined)
        print({ documentTemplate, documentFormat, lineItemId: lineItemToPrint!.lineItemId })
    }

    const addProduct = () => {
        if (productCategory) {
            const productGroupIds = [ProductGroupIds.Catalog, ProductGroupIds[productCategory as keyof typeof ProductGroupIds]]
            navigate.toProductGroup(salesDocumentId!, productGroupIds)
        } else {
            navigate.toCatalog(salesDocumentId!)
        }
    }

    const handleAddFolderButtonClick = () => {
        addFolder({ folderName: L10n.format(TranslationKeys.pages.project.composition.newFolderName), insertPosition: "FIRST" })
    }

    return (
        <Stack spacing={3} width="100%">
            {(!transferPriceCurrency || transferPriceCurrency === Currency.NO_CURRENCY) && (
                <Alert severity="warning">{L10n.format(TranslationKeys.project.warning.missingTransferPriceCurrency)}</Alert>
            )}
            <Stack direction="row" spacing={2} justifyContent="space-between" flexWrap="wrap" useFlexGap>
                <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
                    <Button variant="contained" endIcon={<AddIcon />} onClick={addProduct} disabled={salesDocumentReadOnly}>
                        {L10n.format(TranslationKeys.pages.project.composition.addLineItemButtonLabel)}
                    </Button>
                    <Button variant="contained" color="secondary" endIcon={<AddIcon />} onClick={handleAddFolderButtonClick} disabled={salesDocumentReadOnly}>
                        {L10n.format(TranslationKeys.pages.project.composition.addFolderButtonLabel)}
                    </Button>
                    <Button variant="contained" color="secondary" endIcon={<AddIcon />} onClick={() => dialog.open()} disabled={salesDocumentReadOnly}>
                        {L10n.format(TranslationKeys.lineItem.newArticle.button.label)}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => collapseAllLineItems()}>
                        {L10n.format(TranslationKeys.pages.project.composition.collapseAllLineItemsButtonLabel)}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => expandAllLineItems()}>
                        {L10n.format(TranslationKeys.pages.project.composition.expandAllLineItemsButtonLabel)}
                    </Button>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Tooltip title={L10n.format(TranslationKeys.pages.project.composition.customerViewIconTooltip)}>
                        <IconButton
                            color="secondary"
                            sx={{
                                backgroundColor: selectedProjectCompositionView === ProjectCompositionView.ABB_CUSTOMER_VIEW ? theme.palette.divider : undefined
                            }}
                            onClick={() => setSelectedProjectCompositionView(ProjectCompositionView.ABB_CUSTOMER_VIEW)}
                        >
                            <PersonIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={L10n.format(TranslationKeys.pages.project.composition.salesUnitViewIconTooltip)}>
                        <IconButton
                            color="secondary"
                            sx={{
                                backgroundColor:
                                    selectedProjectCompositionView === ProjectCompositionView.ABB_SALES_UNIT_VIEW ? theme.palette.divider : undefined
                            }}
                            onClick={() => setSelectedProjectCompositionView(ProjectCompositionView.ABB_SALES_UNIT_VIEW)}
                        >
                            <StoreIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={L10n.format(TranslationKeys.pages.project.composition.manufacturingUnitViewIconTooltip)}>
                        <IconButton
                            color="secondary"
                            disabled={!OidcUtils.hasReadTransferPriceDiscountRole(userInformation)}
                            sx={{
                                backgroundColor:
                                    selectedProjectCompositionView === ProjectCompositionView.ABB_MANUFACTURING_UNIT_VIEW ? theme.palette.divider : undefined
                            }}
                            onClick={() => setSelectedProjectCompositionView(ProjectCompositionView.ABB_MANUFACTURING_UNIT_VIEW)}
                        >
                            <FactoryIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={L10n.format(TranslationKeys.pages.project.composition.endToEndViewIconTooltip)}>
                        <IconButton
                            color="secondary"
                            disabled={!OidcUtils.hasReadCostPricesRole(userInformation)}
                            sx={{
                                backgroundColor:
                                    selectedProjectCompositionView === ProjectCompositionView.ABB_END_TO_END_VIEW ? theme.palette.divider : undefined
                            }}
                            onClick={() => setSelectedProjectCompositionView(ProjectCompositionView.ABB_END_TO_END_VIEW)}
                        >
                            <AssessmentIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>

            <LineItemsDataGrid
                onConfigure={openConfiguration}
                onDelete={lineItem => deleteLineItemsHandler.askToDeleteLineItems([lineItem.lineItemId])}
                onGenerateDocument={setLineItemToPrint}
                projectCompositionView={selectedProjectCompositionView}
            />

            {dialog.isOpen && <CustomArticleDialog onClose={dialog.close} />}

            <Dialog title={L10n.format(TranslationKeys.lineItem.actions.print)} onClose={() => setLineItemToPrint(undefined)} open={Boolean(lineItemToPrint)}>
                <DocumentGenerationForm documentTypes={documentTypes} onSubmit={submitDocumentGeneration} />
            </Dialog>

            <Dialog
                open={deleteLineItemsHandler.isAskingToDeleteLineItems}
                onClose={deleteLineItemsHandler.cancelDeletingLineItems}
                title={L10n.format(TranslationKeys.lineItem.deleteDialog.title)}
                message={L10n.format(TranslationKeys.lineItem.deleteDialog.message)}
                defaultDialogActions={{
                    onConfirm: deleteLineItemsHandler.deleteLineItems,
                    onCancel: deleteLineItemsHandler.cancelDeletingLineItems,
                    confirmButtonLabel: TranslationKeys.lineItem.deleteDialog.confirmButtonLabel,
                    cancelButtonLabel: TranslationKeys.lineItem.deleteDialog.cancelButtonLabel
                }}
            />
        </Stack>
    )
}
