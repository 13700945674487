import { Characteristic, MediaValue, NumberValue, Product, ProductGroup, SelectProductsResult } from "@encoway/c-services-js-client"
import getAppPath from "../../router/utils/getAppPath"
import compareStrings from "../../utils/compareStrings"
import { cloneDeep } from "lodash"
import { Characteristics } from "./catalog.constants"

export type CharacteristicValue = string | NumberValue | MediaValue | undefined
export type CatalogEntity = Product | ProductGroup | Characteristic

class CatalogUtils {
    static getCharacteristicValue<T extends CharacteristicValue>(catalogEntity: CatalogEntity, characteristicId: string) {
        return catalogEntity.characteristicValues[characteristicId]?.values.at(0) as T | undefined
    }

    static getCharacteristicValues<T extends CharacteristicValue[]>(catalogEntity: CatalogEntity, characteristicId: string) {
        return catalogEntity.characteristicValues[characteristicId]?.values as T | undefined
    }

    static getLongText(catalogEntity: CatalogEntity) {
        const longText = CatalogUtils.getCharacteristicValue<string>(catalogEntity, Characteristics.LongText.id)
        return longText ? longText.replaceAll("<a", '<a target="_blank"') : longText
    }

    static getMediaUri(catalogEntity: Product | ProductGroup, characteristicId: string) {
        const mediaValue = this.getCharacteristicValue<MediaValue>(catalogEntity, characteristicId)
        return process.env.REACT_APP_DEVELOPMENT ? mediaValue?.uri?.replace(/^.*\/api\//, getAppPath() + "/api/") : mediaValue?.uri
    }

    static getMediaUriOfCharacteristic(catalogEntity: Characteristic, characteristicId: string) {
        const mediaValue = this.getCharacteristicValue<MediaValue>(catalogEntity, characteristicId)
        return mediaValue ? `${getAppPath()}/api/catalog/media?id=${mediaValue.uri}` : undefined
    }

    static getPossibleValues(characteristic: Characteristic, sortStringValues?: boolean) {
        const possibleValues = Object.values(characteristic.possibleValues || {})
        return sortStringValues ? possibleValues.sort((pv1, pv2) => compareStrings(pv1.value as string, pv2.value as string)) : possibleValues
    }

    static getPossibleValueTranslation(possibleValueId: string, characteristic: Characteristic) {
        const possibleValue = characteristic.possibleValues?.[possibleValueId]?.value
        const translation = possibleValue && this.isNumberValue(possibleValue) ? possibleValue.value : possibleValue
        return translation ?? possibleValueId
    }

    static isNumberValue(value: CharacteristicValue): value is NumberValue {
        return typeof value !== "string" && (value as NumberValue)?.value !== undefined
    }

    static sortByNumberCharacteristic<T extends CatalogEntity>(entities: T[], characteristicId: string) {
        return entities.slice().sort((characteristic1, characteristic2) => {
            const order1 = (this.getCharacteristicValue<NumberValue>(characteristic1, characteristicId)?.value as number) ?? Infinity
            const order2 = (this.getCharacteristicValue<NumberValue>(characteristic2, characteristicId)?.value as number) ?? Infinity
            return order1 - order2
        })
    }

    static sortCharacteristicsByView(selectProductsResult: SelectProductsResult, viewId: string) {
        const characteristicView = selectProductsResult.characteristicViews[viewId]
        if (characteristicView) {
            const clone = cloneDeep(selectProductsResult)
            clone.characteristics.sort((a, b) => characteristicView.indexOf(a.id) - characteristicView.indexOf(b.id))
            return clone
        } else {
            return selectProductsResult
        }
    }

    static joinNameAndUnit(characteristic: Characteristic) {
        return characteristic.unitOfMeasure ? `${characteristic.name} (${characteristic.unitOfMeasure.unitSymbol})` : characteristic.name
    }
}

export default CatalogUtils
