import { SxProps } from "@mui/system"
import { Theme } from "@mui/material"
import { mergeSx } from "../../../../../../../../utils/mergeSx"

export const ToolbarButtonSx: SxProps<Theme> = {
    borderRadius: 0,
    "&:hover": { backgroundColor: "rgb(243, 242, 241)", textDecoration: "none" },
    "&:active": { backgroundColor: "rgb(237, 235, 233)" }
}

export const ToggleDoorsButtonSx: SxProps<Theme> = mergeSx(ToolbarButtonSx, {
    padding: "12px"
})

export const ActiveToggleDoorsButtonSx: SxProps<Theme> = mergeSx(ToggleDoorsButtonSx, {
    backgroundColor: "rgb(243, 242, 241)"
})
