import { ProjectConfigurationLineItem } from "../../../../../../features/sales/sales.api.types"

export function getAllLineItemIdsToExpand(lineItemId: string, items: ProjectConfigurationLineItem[], result = [] as string[]): string[] {
    for (const item of items) {
        if (item.lineItem.lineItemId === lineItemId) {
            return result.concat(lineItemId)
        } else {
            const lineItemIdsToExpand = getAllLineItemIdsToExpand(lineItemId, item.children, result.concat(item.lineItem.lineItemId))
            if (lineItemIdsToExpand.length - 1 > result.length) {
                return lineItemIdsToExpand
            }
        }
    }
    return []
}

export function getAllLineItemIdsToCollapse(item: ProjectConfigurationLineItem): string[] {
    if (item.children.length === 0) {
        return [item.lineItem.lineItemId]
    } else {
        const childLineItemIds = item.children.flatMap(child => getAllLineItemIdsToCollapse(child))
        return [item.lineItem.lineItemId].concat(childLineItemIds)
    }
}
