import { Box } from "@mui/material"
import { TreeItemLabel } from "./TreeItem.Label"
import { TreeItemStyles } from "./TreeItem.styles"
import { ProjectConfigurationLineItemWithParent } from "./TreeItem"
import { ItemSplitConfirmationDialog } from "../itemSplitConfirmationDialog/ItemSplitConfirmationDialog"
import { useLineItemDragDrop } from "../../../../../../features/sales/hooks/useLineItemDragDrop"

interface ProjectConfigurationDragAndDropTreeItemLabelProps {
    item: ProjectConfigurationLineItemWithParent
}

export function TreeItemDragAndDropLabel({ item }: Readonly<ProjectConfigurationDragAndDropTreeItemLabelProps>) {
    const { ref, dragProps, dropProps, isSplitItemDialogOpen, closeSplitItemDialog, unitMoveAction, moveLineItemsToHoverPosition } = useLineItemDragDrop(item)

    const onSplitConfirmation = (splitItems: boolean) => {
        moveLineItemsToHoverPosition(unitMoveAction, splitItems).then(closeSplitItemDialog)
    }

    return (
        <>
            {!dropProps.hoverPosition && dropProps.dragItem && <Box sx={TreeItemStyles.treeItemLabelDropNotAllowOverlay(ref.current)} />}
            <TreeItemLabel ref={ref} item={item} sx={TreeItemStyles.treeItemLabelDragAndDrop(dropProps.hoverPosition, dragProps.isDragging)} />
            {dragProps.isDragging && <TreeItemLabel item={item} sx={TreeItemStyles.treeItemLabelDragged(ref.current, dragProps.difference?.y)} />}
            {isSplitItemDialogOpen && (
                <ItemSplitConfirmationDialog onClose={closeSplitItemDialog} item={item?.parent || item} onSplitDecision={onSplitConfirmation} />
            )}
        </>
    )
}
