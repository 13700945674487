import { memo, useCallback, useEffect } from "react"
import {
    AppSwitch,
    ComponentName as ApplicationComponent,
    IVisualizationServer,
    registerDefaultApplicationComponents
} from "@encoway/cui-application-components"
import { ComponentName as ConfiguratorComponent, EventTypes, registerDefaultConfiguratorComponents } from "@encoway/cui-configurator-components"
import CuiTheme from "./Cui.theme"
import { AbbTab } from "./components/AbbTab/AbbTab"
import AbbInputField from "./components/AbbInputField/AbbInputField"
import AbbDropdown from "./components/AbbDropdown/AbbDropdown"
import Table from "./components/Table/Table"
import AbbParameter from "./components/AbbParameter/AbbParameter"
import { AbbSection } from "./components/AbbSection/AbbSection"
import MatlabButton from "./components/MatlabButton/MatlabButton"
import Graph from "./components/Graph/Graph"
import MatlabWarning from "./components/MatlabWarning/MatlabWarning"
import AbbMultilineDisplay from "./components/AbbMultilineDisplay/AbbMultilineDisplay"
import eventBus from "./eventBus"
import AbbTabs from "./components/AbbTabs/AbbTabs"
import AbbLinkedTree from "./components/AbbLinkedTree/AbbLinkedTree"
import AbbNotReadyCount from "./components/AbbNotReadyCount/AbbNotReadyCount"
import AbbTabsFooter from "./components/AbbTabsFooter/AbbTabsFooter"
import AbbRoot from "./components/AbbRoot/AbbRoot"
import AbbCheckbox from "./components/AbbCheckbox/AbbCheckbox"
import AbbStateIcon from "./components/AbbStateIcon/AbbStateIcon"
import AbbDetailDropdown from "./components/AbbDetailDropdown/AbbDetailDropdown"
import AbbFilterDetailDropdown from "./components/AbbFilterDetailDropdown/AbbFilterDetailDropdown"
import { BusyOverlay } from "../../../../../../features/busy/components/busyOverlay/BusyOverlay"
import { AbbBusyOverlay } from "./components/AbbBusyOverlay/AbbBusyOverlay"
import { CuiStyles } from "./Cui.styles"
import { AbbDimensioningMv } from "./components/AbbDimensioningMv/AbbDimensioningMv"
import AbbDimensioningDc from "./components/AbbDimensioningDc/AbbDimensioningDc"
import { AbbVisualization } from "./components/AbbVisualization/AbbVisualization"
import { CuiQuantityWithCustomValueFormatting } from "./components/AbbQuantity/CuiQuantityWithCustomValueFormatting"
import { CuiAdditionalValueWithCustomValueFormatting } from "./components/AbbAdditionalValue/CuiAdditionalValueWithCustomValueFormatting"
import AbbPrice from "./components/AbbPrice/AbbPrice"
import useCuiAppSettings from "../../hooks/useCuiAppSettings"
import Notification from "./components/Notification/Notification"
import { CustomConfiguratorComponents } from "./constants/CustomConfiguratorComponents"
import { AbbRadioButton } from "./components/AbbRadioButton/AbbRadioButton"
import { AbbImageButton } from "./components/AbbImageButton/AbbImageButton"
import { AbbValueCheckbox } from "./components/AbbValueCheckbox/AbbValueCheckbox"
import { Constants } from "@encoway/react-configurator"
import { CUI_BORDER_STYLES, CUI_LAYOUT } from "./Cui.constants"
import { AbbDefaultLayout } from "./components/AbbDefaultLayout/AbbDefaultLayout"
import { L10n } from "@encoway/l10n"
import { AbbInfoButton } from "./components/AbbInfoButton/AbbInfoButton"
import { AbbDisplay } from "./components/AbbDisplay/AbbDisplay"
import { useAppDispatch, useAppSelector } from "../../../../../../store/store"
import { SalesSlice } from "../../../../../../features/sales/sales.slice"
import { GraphApi } from "../../../../../../features/graph/graph.api"
import { DimensioningApi } from "../../../../../../features/dimensioning/dimensioning.api"
import { SalesApi } from "../../../../../../features/sales/sales.api"
import { useBroadcastChannel } from "../../../../../../hooks/useBroadcastChannel"
import { ConfigurationApi } from "../../../../../../features/configuration/configuration.api"
import { SnackbarDefinition, SnackbarSlice } from "../../../../../../features/snackbar/snackbar.slice"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import AbbCheckResult from "./components/AbbCheckResult/AbbCheckResult"
import AbbFilterDropdown from "./components/AbbFilterDropdown/AbbFilterDropdown"
import LineItemsUtils from "../../../../../../features/sales/utils/LineItemsUtils"
import { UnitOverviewTable } from "./components/UnitOverviewTable/UnitOverviewTable"
import { AbbLineItem } from "../../../../../../features/sales/sales.types"
import { IVisualizationProvider } from "@encoway/cui-application-components/src/services/visualization/visualizationService.types"
import { Visualization } from "@encoway/visual-editor"
import AbbBurgerMenu from "./components/AbbBurgerMenu/AbbBurgerMenu"
import { setVisualizationRootNodeState } from "../../../../../../features/visualization/visualization.utils"

registerDefaultApplicationComponents()
    .override(ApplicationComponent.BusyOverlay, AbbBusyOverlay)
    .override(ApplicationComponent.Header, () => null)
    .override(ApplicationComponent.InitialLoadingSpinner, BusyOverlay)
    // Customizing for ABBM-404 => created FBK-1385
    .override(ApplicationComponent.Visualization, AbbVisualization)

registerDefaultConfiguratorComponents()
    .override(ConfiguratorComponent.AdditionalValue, CuiAdditionalValueWithCustomValueFormatting)
    .override(ConfiguratorComponent.Checkbox, AbbCheckbox)
    .override(ConfiguratorComponent.DetailDropdown, AbbDetailDropdown)
    .override(ConfiguratorComponent.DisplayOnly, AbbDisplay)
    .override(ConfiguratorComponent.Dropdown, AbbDropdown)
    .override(ConfiguratorComponent.FilterDropdown, AbbFilterDropdown)
    .override(ConfiguratorComponent.ImageButton, AbbImageButton)
    .override(ConfiguratorComponent.InfoButton, AbbInfoButton)
    .override(ConfiguratorComponent.InputField, AbbInputField)
    .override(ConfiguratorComponent.LinkedTree, AbbLinkedTree)
    .override(ConfiguratorComponent.Parameter, AbbParameter)
    .override(ConfiguratorComponent.Price, AbbPrice)
    .override(ConfiguratorComponent.NotReadyCount, AbbNotReadyCount)
    .override(ConfiguratorComponent.Quantity, CuiQuantityWithCustomValueFormatting)
    .override(ConfiguratorComponent.RadioButton, AbbRadioButton)
    .override(ConfiguratorComponent.Root, AbbRoot)
    .override(ConfiguratorComponent.Section, AbbSection)
    .override(ConfiguratorComponent.Spinner, BusyOverlay)
    .override(ConfiguratorComponent.StateIcon, AbbStateIcon)
    .override(ConfiguratorComponent.Tab, AbbTab)
    .override(ConfiguratorComponent.TabsFooter, AbbTabsFooter)
    .override(ConfiguratorComponent.Tabs, AbbTabs)
    .override(CustomConfiguratorComponents.FilterDetailDropdown, AbbFilterDetailDropdown)
    .override(CustomConfiguratorComponents.Graph, Graph)
    .override(CustomConfiguratorComponents.MatlabButton, MatlabButton)
    .override(CustomConfiguratorComponents.MatlabWarning, MatlabWarning)
    .override(CustomConfiguratorComponents.MultilineDisplay, AbbMultilineDisplay)
    .override(CustomConfiguratorComponents.Table, Table)
    .override(CustomConfiguratorComponents.Notification, Notification)
    .override(CustomConfiguratorComponents.CheckResult, AbbCheckResult)
    .override(CustomConfiguratorComponents.Dimensioning, AbbDimensioningDc)
    .override(CustomConfiguratorComponents.DimensioningMv, AbbDimensioningMv)
    .override(CustomConfiguratorComponents.ValueCheckbox, AbbValueCheckbox)
    .override(CustomConfiguratorComponents.DefaultLayout, AbbDefaultLayout)
    .override(CustomConfiguratorComponents.UnitOverviewTable, UnitOverviewTable)
    .override(ConfiguratorComponent.BurgerMenu, AbbBurgerMenu)

const dispatchEvent = (e: any, dispatch: ReturnType<typeof useAppDispatch>, broadcastChannel?: BroadcastChannel) => {
    if (e.event === Constants.Events.UpdateState || e.event === Constants.Events.InitialState) {
        dispatch(SalesSlice.actions.setConfigurationContainer(e.rawState.rootContainer))
    } else if (e.event === Constants.Events.ParameterChanged) {
        broadcastChannel?.postMessage(e)
        dispatch(SalesSlice.actions.setConfigurationIsSavedToFalse())
        dispatch(GraphApi.util.invalidateTags(["graph"]))
        // dimensioning widget should not reload when a value is selected from inside of it
        if (e.name !== "DCS880_modules") {
            dispatch(DimensioningApi.util.invalidateTags(["dcDimensioning"]))
        }
    } else if (e.event === Constants.Events.ParameterUndo) {
        dispatch(DimensioningApi.util.invalidateTags(["dcDimensioning"]))
    } else if (e.type === EventTypes.LinkedTreeLoaded && e.tree.rootContainer.children?.length > 0) {
        dispatch(SalesSlice.actions.setConfigurationTreeIsAvailable(true))
    }
}

const visualizationProvider: IVisualizationProvider = {
    load: async (baseUrl: string, version: string, token: string) => {
        const visualization = await Visualization.load(baseUrl, version, token)
        const visualizationCloud: any = visualization.cloud
        visualizationCloud.scriptContextRegister("consoleLog", (node: unknown, parent: unknown, part: unknown) => {
            console.log("Custom visualization script helper function: consoleLog", node, parent, part)
        })
        return visualization as unknown as IVisualizationServer
    }
}

interface CuiProps {
    lineItem: AbbLineItem
}

const Cui = ({ lineItem }: CuiProps) => {
    const configurationId = useAppSelector(state => state.sales.configurationId)
    const visualization = useAppSelector(state => state.visualization.visualization)
    const isLineup = lineItem ? LineItemsUtils.isLineupFolder(lineItem) : false
    const lineupVisualization = SalesApi.useLineupVisualizationQuery(lineItem.lineItemId || "", { skip: !isLineup }).data
    const configurationStatus = ConfigurationApi.useStatusQuery({ configurationId, lineItem }, { skip: !configurationId }).data
    const broadcastChannel = useBroadcastChannel("configuration")
    const settings = useCuiAppSettings()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (lineupVisualization && visualization) {
            setVisualizationRootNodeState(lineupVisualization, visualization)
        }
    }, [visualization, lineupVisualization])

    useEffect(() => {
        const unsubscribe = eventBus.onValue((e: any) => dispatchEvent(e, dispatch, broadcastChannel))
        return () => unsubscribe()
    }, [broadcastChannel, dispatch])

    const onKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "C" && event.altKey && event.ctrlKey && settings?.configuration.options.configurationId) {
                event.preventDefault()
                const url = `${settings?.configuration.baseUrl}/app/conan/${settings?.configuration.options.configurationId}`
                window.open(url)
            }
        },
        [settings]
    )

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown, true)
        return () => {
            window.removeEventListener("keydown", onKeyDown, true)
        }
    }, [onKeyDown])

    useEffect(() => {
        if (configurationStatus && lineItem && configurationStatus.lineItem === lineItem) {
            const currentVersion = configurationStatus.modelVersion
            const previousVersion = lineItem.properties.KNOWLEDGEBASE_VERSION
            console.log("comparing model versions (curr)", currentVersion)
            console.log("comparing model versions (prev)", previousVersion)
            if (currentVersion && previousVersion && currentVersion !== previousVersion) {
                const message = L10n.format(TranslationKeys.configuration.modelChanged, { currentVersion, previousVersion })
                const snackbar: SnackbarDefinition = { message, severity: "warning" }
                dispatch(SnackbarSlice.actions.open(snackbar))
                dispatch(SalesSlice.actions.setConfigurationIsSavedToFalse())
                return () => {
                    dispatch(SnackbarSlice.actions.closeByDefinition(snackbar))
                }
            }
        }
    }, [configurationStatus, lineItem, dispatch])

    return settings ? (
        <AppSwitch
            styles={CuiStyles}
            settings={settings}
            eventBus={eventBus}
            layout={CUI_LAYOUT}
            borderStyles={CUI_BORDER_STYLES}
            theme={CuiTheme}
            /* @ts-ignore */
            visualizationProvider={visualizationProvider}
        />
    ) : null
}

export default memo(Cui, (prevProps, nextProps) => prevProps.lineItem.lineItemId === nextProps.lineItem.lineItemId)
