import { GridOverlay } from "@mui/x-data-grid-pro"
import { BusySpinner } from "../../../../features/busy/components/busySpinner/BusySpinner"

export function LoadingOverlay() {
    return (
        <GridOverlay>
            <BusySpinner />
        </GridOverlay>
    )
}
