import { BurgerMenu, ParameterMenu } from "@encoway/cui-configurator-components"
import { BurgerMenuData, BurgerMenuProps } from "@encoway/cui-configurator-components/src/components/BurgerMenu/BurgerMenu.types"

const UNDO_DECISION_KEY = "undoDecision"

export interface AbbBurgerMenuProps extends BurgerMenuProps {
    parameterMenuData?: ParameterMenu & BurgerMenuData
}

function AbbBurgerMenu({ parameterMenuData, data, ...otherProps }: AbbBurgerMenuProps) {
    //Check if value is set by default
    if (data.setByDefault && parameterMenuData) {
        const undoItem = parameterMenuData.getItems().find((item: any) => item.key === UNDO_DECISION_KEY)
        if (undoItem) {
            parameterMenuData.remove(undoItem)
        }
    }

    return <BurgerMenu parameterMenuData={parameterMenuData} data={data} {...otherProps} />
}

export default AbbBurgerMenu
