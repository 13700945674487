import React, { useEffect, useMemo } from "react"
import { RootProps } from "@encoway/cui-configurator-components"
import { ComponentFactory } from "@encoway/react-configurator"
import { ComponentName } from "@encoway/cui-configurator-components/src/constants"
import AbbRootStyles from "./AbbRoot.styles"
import { Portal } from "@mui/material"
import { ProjectConfigurationContainerIds } from "../../../../ProjectConfiguration.constants"
import { getFilteredData } from "./AbbRoot.utils"
import { getConfigurationLayout } from "../AbbDefaultLayout/AbbDefaultLayout.utils"
import { useAppDispatch } from "../../../../../../../../store/store"
import { CustomConfiguratorComponents } from "../../constants/CustomConfiguratorComponents"
import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client"
import { BLOCKED_LAYOUT_SECTION } from "../../../../../../../../features/catalog/catalog.constants"
import { SalesSlice } from "../../../../../../../../features/sales/sales.slice"

export default function AbbRoot(props: Readonly<RootProps>) {
    const configurationContainer = document.getElementById(ProjectConfigurationContainerIds.CONFIGURATION)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const mutationObserver = new MutationObserver(() => configurationContainer?.scrollTo({ top: 0, left: 0 }))
        mutationObserver.observe(configurationContainer!, { childList: true, subtree: false, attributes: false })
        return () => mutationObserver.disconnect()
    }, [configurationContainer])

    useEffect(() => {
        const parameter = props.data.parameters.find((parameter: ParameterTO) => parameter.viewPort === CustomConfiguratorComponents.DefaultLayout)
        if (parameter) {
            const layout = parameter.selectedValues?.at(0)?.value
            const configurationLayout = getConfigurationLayout(layout)
            dispatch(SalesSlice.actions.changeConfigurationLayout(configurationLayout))
        }
    }, [dispatch, props.data])

    const shouldHideTabs = useMemo(() => {
        const nonDefaultLayouts = props.data?.parameters.filter((param: ParameterTO) => param.viewPort !== CustomConfiguratorComponents.DefaultLayout)
        const contentLayouts = props.data?.children.filter((child: ContainerTO) => BLOCKED_LAYOUT_SECTION.includes(child.name))
        return nonDefaultLayouts?.length > 0 || contentLayouts.length > 0
    }, [props.data.children, props.data?.parameters])

    const filteredData = getFilteredData(props.data)
    const propsWithFilteredData = { ...props, data: filteredData }

    return (
        <Portal container={() => configurationContainer}>
            {shouldHideTabs ? (
                <div style={AbbRootStyles}>{ComponentFactory.instanceOf(ComponentName.Section, propsWithFilteredData)}</div>
            ) : (
                ComponentFactory.instanceOf(ComponentName.Tabs, propsWithFilteredData)
            )}
        </Portal>
    )
}
